import React from 'react';
import Image from "react-bootstrap/Image";
import {useTranslation} from "react-i18next";

/**
 * Renders the logo of the publishing company. Expects logoId prop.
 */
export default function PublishingCompanyLogo({ logoId, emptyAlt, publishingCompanyName }) {
  const { t } = useTranslation();
  const logoUrl = logoId ? `${process.env.REACT_APP_PUBRO_API_URL}/images/publishing-company-logos/${logoId}` : '/publishingCompanyLogoPlaceholder.png';

  let alt = '';
  if (!emptyAlt) {
    if (!publishingCompanyName) {
      throw new Error('publishingCompanyName must be specified if emptyAlt is set to true');
    }
    alt = logoId ? (t('publishing.company.logo.alt') + ' ' + publishingCompanyName) : t('publishing.company.logo.default.alt');
  }

  return (
    <Image className='publishing-company-logo'
           src={logoUrl}
           alt={alt}
           loading='lazy'/>
  );
};
