import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import AccessibleAccordionToggle from '../../common/AccessibleAccordionToggle';
import Container from 'react-bootstrap/Container';

/**
 * Renders index. Expects the index string to be passed as 'index' prop.
 */
export default function IndexDetails({ index }) {
  const { t } = useTranslation();
  if (index) {
    return (
      <Row>
        <Col>
          <Accordion defaultActiveKey="index">
            <h2 className="sr-only">{t('publication.details.index.header')}</h2>
            <Card className='section'>
              <AccessibleAccordionToggle eventKey="index">
                {t('publication.details.index.header')}
              </AccessibleAccordionToggle>
              <Accordion.Collapse eventKey="index">
                <Card.Body>
                  <Container>
                    <p className='preserve-whitespace'>
                      <ProcessMath>{transformAsciiMathDelimiter(index)}</ProcessMath>
                    </p>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    );
  }
}
