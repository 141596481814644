import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import PubroSpinner from '../../common/PubroSpinner';
import * as PropTypes from 'prop-types';
import './Citation.css'
import { Parser } from 'html-to-react';
import ClickToSelect from '@mapbox/react-click-to-select'
import { FaDownload } from 'react-icons/fa';
import i18n from "../../i18n/i18n";
import { getCurrentLanguageShortCode } from '../../common/LanguageUtils';

export const CitationType = {
  ARTICLE: 'articles',
  BOOK: 'books',
  CHAPTER: 'chapters',
}

class Citation extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      citations: null,
      isLoading: true,
      show: false,
      citationType: props.citationType,
      publication: props.publication,
      language: null
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    const handleClose = () => this.setState({ show: false });

    const handleShow = () => {
      const currentLanguage = getCurrentLanguageShortCode(i18n.languages).toUpperCase();

      if (this.state.citations === null || this.state.language !== currentLanguage) {
        this.fetchCitationsData(currentLanguage);
      }

      this.setState({ show: true })

    };

    const citationList = this.state.citations && this.state.citations.map((citation, index) => (
      <Row className="mb-3 info-item" key={citation.type + index}>
        <div className="modal-citation-header">
          {t('dictionary.citation.' + citation.type.toLowerCase())}
        </div>
        <div className="modal-citation-content">
          <ClickToSelect>{new Parser().parse(citation.value)}</ClickToSelect>
        </div>
      </Row>
    ));

    const bibtextButtonAriaLabel = t('citation.footer.button.ariaLabel.prefix') + ' '
                    + t('citation.footer.button.bib.title') + ' ' + t('citation.footer.button.bib.extension')
                    + ' - ' + this.state.publication.mainTitle.text;
    const risButtonAriaLabel = t('citation.footer.button.ariaLabel.prefix') + ' '
                    + t('citation.footer.button.ris.title') + ' ' + t('citation.footer.button.ris.extension')
                    + ' - ' + this.state.publication.mainTitle.text;

    return (
      <>
        <Button onClick={handleShow} className='citation__link' size='sm' variant='light' disabled={this.props.disabled}>
          {t('citation.button.cite')}
          </Button>

        <Modal
          show={this.state.show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="info-title"
          centered
        >
          <Modal.Header closeButton closeLabel={t('modal.close.button.label')}>
            <Modal.Title id="info-title">
              {t('citation.header.title')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              {this.state.isLoading
                ? <PubroSpinner/>
                : citationList}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="d-flex justify-content-center">
              <Button href={`${process.env.REACT_APP_PUBRO_API_URL}/${this.state.citationType}/${this.state.publication.id}/citation/export?format=BIBTEX`}
                      target="_blank" rel="noopener noreferrer" download
                      aria-label={bibtextButtonAriaLabel}>
                <FaDownload />
                <Row><Col>{t('citation.footer.button.bib.title')}</Col></Row>
                <Row><Col><small>{t('citation.footer.button.bib.extension')}</small></Col></Row>
              </Button>
            </Col>
            <Col sm={6} className="d-flex justify-content-center">
              <Button href={`${process.env.REACT_APP_PUBRO_API_URL}/${this.state.citationType}/${this.state.publication.id}/citation/export?format=RIS`}
                      target="_blank" rel="noopener noreferrer" download
                      aria-label={risButtonAriaLabel}>
                <FaDownload />
                <Row><Col>{t('citation.footer.button.ris.title')}</Col></Row>
                <Row><Col><small>{t('citation.footer.button.ris.extension')}</small></Col></Row>
              </Button>
            </Col>

          </Modal.Footer>
        </Modal>
      </>
    );
  }

  fetchCitationsData(language) {
    this.props.api.get(`/${this.state.citationType}/${this.state.publication.id}/citation?language=${language}`)
      .then(response => this.setState({
        citations: response.data,
        isLoading: false,
        language: language
      }));
  }
}

Citation.propTypes = {
  citationType: PropTypes.oneOf(Object.values(CitationType)).isRequired,
  publication: PropTypes.object.isRequired
};

export default withTranslation()(Citation);
