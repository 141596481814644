import { emptyArrayToUndefined, getAbsoluteApiUrl } from '../common/utils';
import { sanitizeFormattedContent } from '../publication/common/FormattedContentUtil';

/**
 * Returns json-ld representation for the given issue
 * using PublicationIssue and PublicationVolume type of schema.org dictionary.
 * Function also takes list of articles attached to the
 * given issue for presenting it as 'hasPart' property of
 * PublicationIssue.
 *
 * @see https://schema.org/PublicationIssue
 * @see https://schema.org/PublicationVolume
 * @see IssueVO java class
 * @see ArticleListItemVO java class
 */
export function buildIssueJsonLd(issue, articles) {

  const reactAppBaseUrl = window.location.origin;
  const restApiBaseUrl = getAbsoluteApiUrl();

  let issueJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'PublicationIssue',
    issueNumber: issue.number,
    publisher: buildOrganizationLdFromPublishingCompanyInfo(issue.journalInfo.publishingCompanyInfo, reactAppBaseUrl)
  };

  if (issue.year) {
    issueJsonLd.datePublished = issue.year;
  }

  if (issue.coverId) {
    issueJsonLd.image = `${restApiBaseUrl}/images/issue-covers/${issue.coverId}`;
  }

  issueJsonLd.hasPart = emptyArrayToUndefined(articles.map(
                            article => buildScholarlyArticleLdFromArticle(article, reactAppBaseUrl)));


  if (issue.volume) {
    issueJsonLd.isPartOf = {
      '@type': 'PublicationVolume',
      volumeNumber: issue.volume,
      publisher: buildOrganizationLdFromPublishingCompanyInfo(issue.journalInfo.publishingCompanyInfo, reactAppBaseUrl),
      isPartOf: `${reactAppBaseUrl}/journals/${issue.journalInfo.id}`
    };
  } else {
    issueJsonLd.isPartOf = `${reactAppBaseUrl}/journals/${issue.journalInfo.id}`;
  }

  return issueJsonLd;
}

// -------------------- PRIVATE --------------------

function buildOrganizationLdFromPublishingCompanyInfo(publishingCompanyInfo, reactAppBaseUrl) {
  return {
    '@type': 'Organization',
    name: publishingCompanyInfo.name,
    url: `${reactAppBaseUrl}/publishing-companies/${publishingCompanyInfo.id}`
  };
}

function buildScholarlyArticleLdFromArticle(article, reactAppBaseUrl) {
  return {
    '@type': 'ScholarlyArticle',
    name: sanitizeFormattedContent(article.title),
    url: `${reactAppBaseUrl}/articles/${article.id}`
  };
}
