import React from 'react';
import {useTranslation} from 'react-i18next';
import SearchResultListItem from './list-item/SearchResultListItem';

/**
 * Renders a list of search results. Takes the whole search result object as
 * `searchResult` property. Displays a total result count and search result
 * list items for the current page.
 */
export default function SearchResultList({searchResult, urlQueryValues, api}) {
  const {t} = useTranslation();

  if (searchResult.totalResults === 0) {
    return (<div><em>{t('search.result.list.empty')}</em></div>)
  }

  return (
    <>
      {searchResult.documents.map(doc => <SearchResultListItem document={doc} urlQueryValues={urlQueryValues} key={doc.publicationId} api={api}/>)}
    </>
  );
}
