import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import {LinkContainer} from 'react-router-bootstrap';
import PubroSpinner from '../common/PubroSpinner';
import {withTranslation} from "react-i18next";
import { Helmet } from 'react-helmet-async';
import { MAIN_CONTENT_ID } from '../main-structure/SkipToContentLinks';
import queryString from 'query-string';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import Col from 'react-bootstrap/Col';
import ResultListPagination from '../common/ResultListPagination';
import LibrarySearch from '../main-structure/LibrarySearch';
import { convertToIetfLanguageTag } from '../common/LanguageUtils';
import { extractNamePartFromUrlQuery, extractPageFromUrlQuery } from '../main-structure/library-utils';
import { generateMetaTitle, generateMetaDescription } from "../common/meta";
import WbnAccessIcon from '../common/WbnAccessIcon';


class JournalList extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      journals: null,
      totalElements: 0,
      pageSize: 100,
      namePart: extractNamePartFromUrlQuery(this.props.location.search),
      page: extractPageFromUrlQuery(this.props.location.search),
      isLoading: true
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.updateJournals();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        namePart: extractNamePartFromUrlQuery(this.props.location.search),
        page: extractPageFromUrlQuery(this.props.location.search),
        isLoading: true
      }, this.updateJournals);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    if (this.state.isLoading) {
      return <PubroSpinner/>;
    }

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: t('journal.list.title') });

    return (
      <>
        <Helmet title={ generateMetaTitle(t, {'label' : 'meta.journals.title'}) }
                meta={[{ name: "description", content: generateMetaDescription(t, {'label' : 'meta.journals.description'}) }]}/>
        <main id={MAIN_CONTENT_ID}>
          <div className="tabbed-details-name-container">
            <div className="tabbed-details-name-container tabbed-details-sidebar-present">
              <h1 className="library-list-header">{t('journal.list.title')}</h1>
            </div>
            <div className="tabbed-details-sidebar">
              <LibrarySearch namePart={this.state.namePart} onSubmit={this.onFormSubmit}
                             placeholderKey='journal.search.input.placeholder'
                             buttonTitleKey='journal.search.button.title'/>
            </div>
          </div>
          <Container className="mt-0 container-without-cols">
            <Row>
              {this.state.journals.length === 0 ? this.emptyJournals() : this.journalList()}
            </Row>
          </Container>
          {this.state.totalElements > this.state.pageSize && this.buildPaginationContainer()}
        </main>
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  emptyJournals() {
    const t = this.props.t;
    const emptyText = (this.state.namePart ? t('journal.list.emptySearch') : t('journal.list.empty'));
    return <em>{emptyText}</em>;
  }

  journalList() {
    const journalElements = this.state.journals.map(journalListItem => (
      <LinkContainer to={`/journals/${journalListItem.id}`} key={journalListItem.id}>
        <ListGroup.Item action className={journalListItem.restricted ? "wbn-access-restricted" : ""}>
          {journalListItem.restricted && <WbnAccessIcon type={'JOURNAL'}/>}
          <h3 lang={convertToIetfLanguageTag(journalListItem.mainTitleLanguage)}>
            {journalListItem.mainTitle}
          </h3>
        </ListGroup.Item>
      </LinkContainer>
    ));

    return (
      <Container className="resources-container">
        <ListGroup className="resources-list">
          {journalElements}
        </ListGroup>
      </Container>
    );
  }

  buildPaginationContainer() {
    const namePart = this.state.namePart;
    const linkFn = page => queryString.stringify({namePart: namePart ? namePart : undefined, page: page});

    return (
      <Container>
        <Row>
          <Col>
            <ResultListPagination currentPage={this.state.page}
                                  linkFn={linkFn}
                                  totalResults={this.state.totalElements}
                                  pageSize={this.state.pageSize}/>
          </Col>
        </Row>
      </Container>
    );
  }

  updateJournals() {
    this.props.api.get(`/journals`, {params: {namePart: this.state.namePart, page: this.state.page, pageSize: this.state.pageSize}})
      .then(response => this.setState({
        journals: response.data.content,
        totalElements: response.data.totalElements,
        isLoading: false
      }));
  }

  onFormSubmit(formValues) {
    this.props.history.push('/journals?namePart=' + encodeURIComponent(formValues.namePart));
  }
}

export default withTranslation()(JournalList);
