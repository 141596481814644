import { convertFormatToEncodingType, convertToIsoDate } from '../../common/JsonLdUtils';
import { buildContributorFullName, emptyArrayToUndefined } from '../../common/utils';
import { convertToIetfLanguageTag } from '../../common/LanguageUtils';
import { sanitizeFormattedContent } from '../common/FormattedContentUtil';

/**
 * Returns part of json-ld representation for the given publication.
 * Note that returned value will not contain "@context" and "@type"
 * fields of json-ld. It is the caller responsibility to add those
 * to form valid json-ld.
 * Returned value will contain fields valid to CreativeWork type
 * of schema.org. Keep that in mind when attaching "@type" to return
 * value - it must extend CreativeWork type of schema.org.
 *
 * @see https://schema.org/CreativeWork
 * @see PublicationVO java class
 */
export function buildCreativeWorkJsonLdPartFromPublication(publication) {

  const publicationJsonLd = {
    name: sanitizeFormattedContent(publication.mainTitle.text),
    inLanguage: convertToIetfLanguageTag(publication.language)
  };

  if (publication.mainAbstract.text) {
    publicationJsonLd.abstract = sanitizeFormattedContent(publication.mainAbstract.text);
  }

  if (publication.license && publication.license.url) {
    publicationJsonLd.license = publication.license.url;
  }

  const datePublished = extractDateWithType(publication, 'PUBLISHED');
  if (datePublished) {
    publicationJsonLd.datePublished = convertToIsoDate(datePublished);
  }

  publicationJsonLd.alternateName = emptyArrayToUndefined(publication.mainTitleTranslations
                                    .map(titleTranslation => sanitizeFormattedContent(titleTranslation.text)));

  publicationJsonLd.keywords = emptyArrayToUndefined(publication.keywords
                                    .map(keyword => sanitizeFormattedContent(keyword.text)));

  publicationJsonLd.citation = emptyArrayToUndefined(publication.bibEntries
                                    .map(bibEntry => sanitizeFormattedContent(bibEntry)));

  publicationJsonLd.author = emptyArrayToUndefined(
                            extractContributorsWithRole(publication, "AUTHOR")
                                .map(author => buildPersonLdFromContributor(author)));

  publicationJsonLd.editor = emptyArrayToUndefined(
                            extractContributorsWithRole(publication, "EDITOR")
                                .map(editor => buildPersonLdFromContributor(editor)));

  publicationJsonLd.translator = emptyArrayToUndefined(
                                extractContributorsWithRole(publication, "TRANSLATOR")
                                    .map(translator => buildPersonLdFromContributor(translator)));

  publicationJsonLd.associatedMedia = emptyArrayToUndefined(publication.fullTextFormats
                                    .map(format => buildMediaObjectLdFromFulltext(format)));

  return publicationJsonLd;
}

// -------------------- PRIVATE --------------------

function buildMediaObjectLdFromFulltext(fullTextFormat) {
  let mediaObjectLd = {
    "@type": "MediaObject",
    contentUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname}.${fullTextFormat.toLowerCase()}`,
    encodingFormat: convertFormatToEncodingType(fullTextFormat)
  }
  return mediaObjectLd;
}

function buildPersonLdFromContributor(contributor) {
  let personJsonLd = {
    "@type": "Person",
    name: buildContributorFullName(contributor.firstName, contributor.lastName),
  };

  if (contributor.firstName) {
    personJsonLd.givenName = contributor.firstName;
  }

  personJsonLd.familyName = contributor.lastName

  if (contributor.orcid) {
    personJsonLd.identifier = [{
      "@type": "PropertyValue",
      "name": "orcid",
      "value": contributor.orcid
    }];
  }

  personJsonLd.affiliation = emptyArrayToUndefined(contributor.publicationInstitutions
                                .filter(publicationInstitution => publicationInstitution.institutionName !== null)
                                .map(publicationInstitution => buildOrganizationLdFromPublicationInstitution(publicationInstitution)));

  return personJsonLd;
}

function buildOrganizationLdFromPublicationInstitution(publicationInstitution) {
  return {
    "@type": "Organization",
    name: publicationInstitution.institutionName
  }
}

function extractDateWithType(publication, dateType) {
  const dateWithType = publication.dates.find(date => date.type === dateType);
  return dateWithType ? dateWithType.date : undefined;
}
function extractContributorsWithRole(publication, role) {
  return publication.contributors.filter(contributor => contributor.role === role);
}


