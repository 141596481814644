import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import {LinkContainer} from "react-router-bootstrap";
import {withTranslation} from "react-i18next";
import { convertToIetfLanguageTag } from '../../common/LanguageUtils';
import { buildContributorFullName, transformAsciiMathDelimiter } from '../../common/utils';
import ProcessMath from '../../common/ProcessMath';
import FormattedContent from '../common/FormattedContent';
import WbnAccessIcon from "../../common/WbnAccessIcon";

/**
 * Renders a list of given articles.
 */
class ArticleList extends React.Component {

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    if (this.props.articles.length === 0) {
      return <div className="article-list-container"><em>{t('article.list.empty')}</em></div>;
    }

    const articleListElements = this.props.articles.map(articleListItem => (
      <LinkContainer to={`/articles/${articleListItem.id}`} key={articleListItem.id}>
        <ListGroup.Item action className={articleListItem.restricted ? "wbn-access-restricted" : ""}>
          {articleListItem.restricted && <WbnAccessIcon type={'ARTICLE'}/>}
          {articleListItem.pageRange && !articleListItem.number && <div className="page-range">{articleListItem.pageRange}</div>}
          {articleListItem.number && <div className="article-number">{articleListItem.number}</div>}
          <h3 lang={convertToIetfLanguageTag(articleListItem.titleLanguage)}>
            <ProcessMath>
              <FormattedContent content={transformAsciiMathDelimiter(articleListItem.title)} />
            </ProcessMath>
          </h3>
          {articleListItem.contributors && articleListItem.contributors.length > 0 &&
            <ul className="publications-list-authors">
              {articleListItem.contributors.map((author, index) => <li key={index}>{buildContributorFullName(author.firstName, author.lastName)}</li>)}
            </ul>
          }
        </ListGroup.Item>
      </LinkContainer>
    ));

    return (
      <div className="article-list-container">
        <ListGroup>
          {articleListElements}
        </ListGroup>
      </div>
    );
  }
}

export default withTranslation()(ArticleList);
