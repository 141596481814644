import React, { useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguageShortCode } from '../../../../common/LanguageUtils';
import './SearchResultLicense.css';
import './SearchResultScientificDisciplines.css'
import { convertUrlQueryValuesToUrlQuery } from '../../../form/AdvancedSearchUtils';
import { Link } from 'react-router-dom';
import AccessibleOverlayTrigger from '../../../../common/AccessibleOverlayTrigger';
import { v4 as uuidv4 } from 'uuid';

/**
 * Renders scientific disciplines in form of a list. Each discipline is wrapped
 * in a link to new search query.
 *
 * @author sniewcza
 */
export default function SearchResultScientificDisciplines({scientificDisciplines, urlQueryValues}) {
  const {i18n} = useTranslation();
  const [idPostfix] = useState(uuidv4());

  return (
    <ul>
      {scientificDisciplines.map(discipline =>
        <li key={discipline.id}>
          {scientificDisciplineLink(discipline, urlQueryValues, idPostfix, i18n)}
        </li>
      )}
    </ul>
  );
}

function scientificDisciplineLink(scientificDiscipline, urlQueryValues, idPostfix, i18n) {
  const tooltip = (<Tooltip id={'discipline-tooltip-' + scientificDiscipline.id + '-' + idPostfix}>
    {i18n.t('search.result.list.item.scientificdisciplines.link.tooltip')}
  </Tooltip>);

  return (
    <AccessibleOverlayTrigger overlay={tooltip}>
      <Link to={'/search?' + buildDisciplineSearchUrl(scientificDiscipline.id, urlQueryValues)} className="scientific-field-link">
        {getCurrentLanguageShortCode(i18n.languages) === 'pl' ? scientificDiscipline.namePl : scientificDiscipline.nameEn}
      </Link>
    </AccessibleOverlayTrigger>
  );
}

function buildDisciplineSearchUrl(disciplineId, currentUrlQueryValues) {
  const newUrlQueryValues = {
    pageSize: currentUrlQueryValues.pageSize,
    scientificDisciplines: [disciplineId]
  };
  return convertUrlQueryValuesToUrlQuery(newUrlQueryValues)
}
