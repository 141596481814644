import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertToIetfLanguageTag } from '../../common/LanguageUtils';
import AccessibleOverlayTrigger from '../../common/AccessibleOverlayTrigger';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';
import FormattedContent from '../common/FormattedContent';

/**
 * Renders a list of keywords with keywords in language of the publication at
 * the top of the list. Expects the following props:
 *  - keywords
 *  - publicationLanguage
 */
export default function KeywordTabList({ keywords, publicationLanguage }) {
  const { t } = useTranslation();
  if (keywords && keywords.length > 0) {
    const languages = getKeywordLanguagesWithPublicationLanguageOnFirstPosition(keywords, publicationLanguage);
    return (
      <Row>
        <Col>
          <Tab.Container defaultActiveKey={0}>
            <div className="header-with-tabs">
            <h2>{t('publication.details.keywords.header')}</h2>
            <Nav variant="tabs">
              {languages.map((lang, index) =>
                <AccessibleOverlayTrigger key={"keyword-tab-"+lang} overlay={<Tooltip>{t('dictionary.language.' + lang)}</Tooltip>}>
                  <Nav.Item>
                    <Nav.Link eventKey={index}>{lang}</Nav.Link>
                  </Nav.Item>
                </AccessibleOverlayTrigger>
              )}
            </Nav>
            </div>
            <Tab.Content>
              {languages.map((lang, index) =>
                <Tab.Pane key={"keyword-tab-content-"+ lang} eventKey={index}>
                  <div lang={convertToIetfLanguageTag(lang)}>
                    <ProcessMath>
                      <FormattedContent content={transformAsciiMathDelimiter(getKeywordValuesForLanguage(keywords, lang).join(', '))}></FormattedContent>
                    </ProcessMath>
                  </div>
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>

        </Col>
      </Row>
    );
  }
}

// -------------------- PRIVATE --------------------

function getKeywordValuesForLanguage(keywords, languageKey) {
  return keywords.filter(keyword => keyword.language === languageKey)
    .map(keyword => keyword.text);
}

function getKeywordLanguagesWithPublicationLanguageOnFirstPosition(keywords, publicationLanguage) {
  const languages = keywords.map(keyword => keyword.language);
  const distinctLanguages = [];

  if (publicationLanguage && languages.includes(publicationLanguage)) {
    distinctLanguages.push(publicationLanguage);
  }

  languages.forEach(language => {
    if (!distinctLanguages.includes(language)) {
      distinctLanguages.push(language);
    }
  });

  return distinctLanguages;
}
