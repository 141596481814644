import { firstBy } from 'thenby';
import { extractPageRange } from '../../common/utils';

/**
 * Returns sorted chapter array.
 * Sorting is performed on according to rules below:
 *  - chapters with 'pageRange' are sorted based on:
 *     - number of value before hyphen of 'pageRange' (for example '10' in string '10-11')
 *     - number of value after hyphen of 'pageRange' (for example '11' in string '10-11')
 *     - if number extraction of above fails then sort by 'pageRange' lexicographically
 *  - chapters without 'pageRange' are positioned last and sorted lexicographically (with ignore case)
 */
export function sortChapters(chapters) {
  return chapters.sort(
    firstBy(chapter => chapter.pageRange ? false : true)
    .thenBy(chapter => chapter.pageRange ? parseInt(extractPageRange(chapter.pageRange).pageStart) : undefined, { ignoreCase: true })
    .thenBy(chapter => chapter.pageRange ? parseInt(extractPageRange(chapter.pageRange).pageEnd) : undefined, { ignoreCase: true })
    .thenBy('pageRange', { ignoreCase: true })
    .thenBy('title', { ignoreCase: true })
    .thenBy('id')
  );
}
