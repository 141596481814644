import { buildContributorFullName } from '../../common/utils';
import { cleanFormattedContent } from './FormattedContentUtil';

export function getCommonPublicationMetaTagValues(publication) {
  const meta = [{name: 'citation_title', content: cleanFormattedContent(publication.mainTitle.text)}];

  const authors = publication.contributors.filter(c => c.role === 'AUTHOR')
    .map(c => buildContributorFullName(c.firstName, c.lastName))
    .map(c => ({name: 'citation_author', content: c}))
  meta.push(...authors);

  const pubTypedDate = publication.dates.find(d => d.type === 'PUBLISHED');
  if (pubTypedDate) {
    meta.push({name: 'citation_publication_date', content: formatDateForMetaTag(pubTypedDate.date)});
  }

  const pdf = publication.fullTextFormats.find(format => format === 'PDF');
  if (pdf) {
    meta.push({name: 'citation_pdf_url', content: `${window.location.protocol}//${window.location.host}${window.location.pathname}.pdf`})
  }

  if (publication.conferenceName) {
    meta.push({name: 'citation_conference_title', content: publication.conferenceName})
  }

  if (publication.mainAbstract && publication.mainAbstract.text) {
    meta.push({name: 'citation_abstract', content: cleanFormattedContent(publication.mainAbstract.text)})
  }

  return meta;
}

// -------------------- PRIVATE --------------------

function formatDateForMetaTag(date) {
  return date.day > 0
    ? [date.year, date.month, date.day].join('/')
    : date.year;
}
