
/**
 * Returns true if external id can be presented as url
 */
export function canCreatUrlExternalId(extId) {
  return extId.type === 'DOI' || extId.type === 'PUBMED_ID';
}

/**
 * Returns url to the page that is pointed by external id
 */
export function createUrlExternalId(extId) {

  if (extId.type === 'DOI') {
    return 'https://doi.org/' + extId.text;
  }
  if (extId.type === 'PUBMED_ID') {
    return 'https://www.ncbi.nlm.nih.gov/pubmed/' + extId.text;
  }

  throw new Error(`Creating ${extId.type} external id link not implemented.`);
}