import Card from 'react-bootstrap/Card';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Displays a draft preview warning text label.
 */
export default function DraftPreviewModeWarning() {
  const { t } = useTranslation();

  return (
    <Card className="section preview-mode-warning">
      <Card.Body>
        <p>
          {t('publication.details.previewmode')}
        </p>
      </Card.Body>
    </Card>
  );
}
