import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { withTranslation } from 'react-i18next';
import { MAIN_CONTENT_ID } from './SkipToContentLinks';
import { extractLocationWithoutTabKey, extractTabKey } from '../common/utils';
import PublishingCompanyList from '../publishing-company/PublishingCompanyList';
import { withRouter } from 'react-router-dom';
import JournalList from '../journal/JournalList';
import BookList from '../publication/book/BookList';

const PUBLISHING_COMPANIES_TAB_KEY = '/publishing-companies';
const JOURNALS_TAB_KEY = '/journals';
const BOOKS_TAB_KEY = '/books';
export const AVAILABLE_TABS = [PUBLISHING_COMPANIES_TAB_KEY, JOURNALS_TAB_KEY, BOOKS_TAB_KEY];

const DEFAULT_TAB_KEY = PUBLISHING_COMPANIES_TAB_KEY;

class Library extends React.Component {

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.state = {
      activeTab: DEFAULT_TAB_KEY
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.setState({activeTab: extractTabKey(this.props.location.pathname, AVAILABLE_TABS, DEFAULT_TAB_KEY)});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.setState({activeTab: extractTabKey(this.props.location.pathname, AVAILABLE_TABS, DEFAULT_TAB_KEY)});
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    return (
      <>
        <main id={MAIN_CONTENT_ID}>
          <div className="tabbed-details-tabs-container">
            <Tab.Container activeKey={this.state.activeTab} onSelect={this.handleTabSelect}>
              <div className="tabbed-details-tab-nav-container">
                <Nav variant="tabs">
                  <Nav.Link eventKey={PUBLISHING_COMPANIES_TAB_KEY}
                            href={extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS) + PUBLISHING_COMPANIES_TAB_KEY}>
                    {t('library.tab.publishing-companies')}
                  </Nav.Link>
                  <Nav.Link eventKey={JOURNALS_TAB_KEY}
                            href={extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS) + JOURNALS_TAB_KEY}>
                    {t('library.tab.journals')}
                  </Nav.Link>
                  <Nav.Link eventKey={BOOKS_TAB_KEY}
                            href={extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS) + BOOKS_TAB_KEY}>
                    {t('library.tab.books')}
                  </Nav.Link>
                </Nav>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey={PUBLISHING_COMPANIES_TAB_KEY}>
                  <h2 className="sr-only">{t('library.tab.publishing-companies')}</h2>
                  {this.state.activeTab === PUBLISHING_COMPANIES_TAB_KEY &&
                    <PublishingCompanyList location={this.props.location} history={this.props.history} api={this.props.api}/>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey={JOURNALS_TAB_KEY}>
                  <h2 className="sr-only">{t('library.tab.journals')}</h2>
                  {this.state.activeTab === JOURNALS_TAB_KEY &&
                    <JournalList location={this.props.location} history={this.props.history} api={this.props.api}/>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey={BOOKS_TAB_KEY}>
                  <h2 className="sr-only">{t('library.tab.books')}</h2>
                  {this.state.activeTab === BOOKS_TAB_KEY &&
                    <BookList location={this.props.location} history={this.props.history} api={this.props.api}/>
                  }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </main>
      </>
    );
  }

  handleTabSelect = (tabKey, event) => {
    const locationWithoutTabKey = extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS);
    event.preventDefault();
    this.setState({activeTab: tabKey});
    this.props.history.push(locationWithoutTabKey + tabKey);
  }
}

export default withTranslation()(withRouter(Library));
export { Library as TestLibrary };
