import React, { useState, useRef, useEffect, useCallback, cloneElement } from "react";
import Overlay from 'react-bootstrap/Overlay';
import useTimeout from '@restart/hooks/useTimeout';
import safeFindDOMNode from 'react-overlays/safeFindDOMNode';

/**
 * Time in miliseconds after witch overlay will be hidden
 * when user moves mouse cursor away from overlay or overlay trigger.
 * This time should be high enough for user to move mouse cursor
 * beetween overlay and overlay trigger.
 */
const HIDE_OVERLAY_TIMEOUT = 100;

/**
 * Helper state value which tells that at this moment
 * overlay trigger is focused or mouse cursor
 * is over overlay or overlay trigger.
 */
const HOVER_OR_FOCUS_STATE_TRUE = 'true';
/**
 * Helper state value which is exact opposite
 * to HOVER_OR_FOCUS_STATE_TRUE
 */
const HOVER_OR_FOCUS_STATE_FALSE = 'false';

const ESC_KEY_CODE = 27;

/**
 * Helper component that allows to assign ref to
 * children
 */
class RefHolder extends React.Component {
  render() {
    return this.props.children;
  }
}

/**
 * Alternative version of OverlayTrigger component that fullfils WCAG 1.4.13 rule
 * (Content on Hover or Focus).
 * Implementation is heavily based on original OverlayTrigger component.
 * It supports placement and overlay props. Their allowed values
 * and meaning are exactly the same as in original OverlayTrigger.
 *
 * Overlay rendered by this component behaves as stated below:
 *  - overlay is shown to the user when user move mouse to overlay target
      (which is specified by children of this component) or overlay
      target is focused
 *  - overlay can be dissmised with ESC key
 *  - mouse pointer can be moved from overlay trigger to the overlay without
 *    dissapearing
 * 
 * @see https://www.w3.org/WAI/WCAG21/Understanding/content-on-hover-or-focus.html
 * @see https://react-bootstrap.github.io/components/overlays/#overlay-trigger
 * @see https://react-bootstrap.github.io/components/overlays/#overlay-trigger-props
 */
export default function AccessibleOverlayTrigger({placement, overlay, children}) {
  const [show, setShow] = useState(false);
  const hoverOrFocusStateRef = useRef('');
  const target = useRef(null);
  const timeout = useTimeout();

  const showHandler = () => {
    hoverOrFocusStateRef.current = HOVER_OR_FOCUS_STATE_TRUE;
    setShow(true);
  };

  const hideHandler = () => {
    hoverOrFocusStateRef.current = HOVER_OR_FOCUS_STATE_FALSE;
    setShow(false);
  };

  const hideAfterTimeoutHandler = () => {
    timeout.clear();
    hoverOrFocusStateRef.current = HOVER_OR_FOCUS_STATE_FALSE;

    timeout.set(() => {
      if(hoverOrFocusStateRef.current === HOVER_OR_FOCUS_STATE_FALSE) {
        setShow(false);
      }
    }, HIDE_OVERLAY_TIMEOUT);
  };

  const hideOverlayOnEscKey = (event) => {
    if (event.keyCode === ESC_KEY_CODE) {
      setShow(false);
    }
  }

  const triggerProps = {
    onFocus: showHandler,
    onBlur: hideHandler,
    onMouseOver: showHandler,
    onMouseOut: hideAfterTimeoutHandler
  };
  const overlayProps = {
    onMouseOver: showHandler,
    onMouseOut: hideAfterTimeoutHandler
  };

  const getTarget = useCallback(
    () => safeFindDOMNode(target.current),
    [],
  );

  useEffect(() => {
    document.addEventListener('keydown', hideOverlayOnEscKey);

    return () => document.removeEventListener('keydown', hideOverlayOnEscKey);
  });


  return (
    <>
      <RefHolder ref={target}>
        {cloneElement(children, triggerProps)}
      </RefHolder>
      <Overlay target={getTarget} show={show} placement={placement}>
        {cloneElement(overlay, overlayProps)}
      </Overlay>
    </>
  );
}