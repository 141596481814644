import { buildCreativeWorkJsonLdPartFromPublication } from '../common/PublicationJsonLd';
import { canCreatUrlExternalId, createUrlExternalId } from '../common/ExternalIdentifierUtil';
import { extractPageRangesOrPagination, emptyArrayToUndefined } from '../../common/utils';

/**
 * Returns json-ld representation for the given chapter
 * using Chapter type of schema.org dictionary. 
 * 
 * @see https://schema.org/Chapter
 * @see ChapterVO java class
 */
export function buildChapterJsonLd(chapter) {

  const reactAppBaseUrl = window.location.origin;

  let chapterJsonLd = {
    "@context": "https://schema.org",
    "@type": "Chapter",
    ...buildCreativeWorkJsonLdPartFromPublication(chapter),
    isPartOf: `${reactAppBaseUrl}/books/${chapter.bookInfo.id}`,
    publisher: { "@type": "Organization", 
        name: chapter.bookInfo.publishingCompanyInfo.name, 
        url: `${reactAppBaseUrl}/publishing-companies/${chapter.bookInfo.publishingCompanyInfo.id}`}
  }
  
  if (chapter.pageRange) {
    const pageRangesOrPagination = extractPageRangesOrPagination(chapter.pageRange);
    chapterJsonLd.pageStart = pageRangesOrPagination.pageStart;
    chapterJsonLd.pageEnd = pageRangesOrPagination.pageEnd;
    chapterJsonLd.pagination = pageRangesOrPagination.pagination;
  }
  
  chapterJsonLd.sameAs = emptyArrayToUndefined(chapter.externalIdentifiers
                                    .filter(externalId => canCreatUrlExternalId(externalId))
                                    .map(externalId => createUrlExternalId(externalId)))
  
  return chapterJsonLd;
}
