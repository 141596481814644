import React from "react";
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import PublishingCompanyBasicInfo from "./PublishingCompanyBasicInfo";
import PublishingCompanyLogo from "./PublishingCompanyLogo";
import PublishingCompanyJournals from "../journal/PublishingCompanyJournals";
import PublishingCompanyBooks from "../publication/book/PublishingCompanyBooks";
import PubroSpinner from "../common/PubroSpinner";
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../common/Breadcrumbs';
import { Helmet } from 'react-helmet-async'
import SearchInPublishingCompanyForm from '../search/form/SearchInPublishingCompanyForm';
import { buildPublishingCompanyJsonLd } from './PublishingCompanyJsonLd';
import { MAIN_CONTENT_ID } from '../main-structure/SkipToContentLinks';
import { extractTabKey, extractLocationWithoutTabKey} from '../common/utils';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import { generateMetaDescription, generateMetaTitle, generateSeparatedList } from "../common/meta";
import WbnAccessIcon from "../common/WbnAccessIcon";

const DEFAULT_TAB_KEY = '';
const BOOKS_TAB_KEY = '/books';
const JOURNALS_TAB_KEY = '/journals';
export const AVAILABLE_TABS = [DEFAULT_TAB_KEY, BOOKS_TAB_KEY, JOURNALS_TAB_KEY];


/**
 * Renders publishing company details and a lists of journals and books assigned
 * to this publishing company. Id of the company to be displayed is determined
 * from 'publishingCompanyIdd' URL parameter. The component contacts the backend
 * to fetch the PublishingCompanyVO when mounted.
 */
class PublishingCompanyDetails extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      publishingCompany: null,
      isLoading: true,
      activeTab: DEFAULT_TAB_KEY
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    const { publishingCompanyId } = this.props.match.params;
    this.setState({activeTab: extractTabKey(this.props.location.pathname, AVAILABLE_TABS, DEFAULT_TAB_KEY)});

    this.props.api.get(`/publishing-companies/${publishingCompanyId}`)
      .then(reponse => {
        this.setState({
          publishingCompany: reponse.data,
          isLoading: false
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({activeTab: extractTabKey(this.props.location.pathname, AVAILABLE_TABS, DEFAULT_TAB_KEY)});
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const { publishingCompanyId } = this.props.match.params;
    const t = this.props.t;

    return (
      <>
        {this.state.publishingCompany &&
          <>
            <Helmet title={this.getMetaTitle(t)}
                    script={[{type: "application/ld+json", innerHTML: JSON.stringify(buildPublishingCompanyJsonLd(this.state.publishingCompany))}]}/>
            <Breadcrumbs items={this.getBreadcrumbs(this.state.publishingCompany)} maxItemLength={75}/>
          </>
        }
        <main id={MAIN_CONTENT_ID}>
          <div className="tabbed-details-name-container">
            {this.state.publishingCompany &&
            <div className="tabbed-details-name-container tabbed-details-sidebar-present">
              <div className="tabbed-details-name-logo-container">
                <PublishingCompanyLogo logoId={this.state.publishingCompany.logoId} publishingCompanyName={this.state.publishingCompany.name}/>
              </div>
              <div className="tabbed-details-name-header-container">
                <div className="tabbed-details-name-header">{t('publishing.company.details.header.publisher')}</div>
                  {this.state.publishingCompany && <>
                      {this.state.publishingCompany.restricted && <WbnAccessIcon type={'PUBLISHER'}/>}
                      <h1 className="tabbed-details-name">
                        {this.state.publishingCompany.name}
                      </h1>
                  </>}
              </div>
            </div>
            }
            <div className="tabbed-details-sidebar">
              <SearchInPublishingCompanyForm publishingCompanyId={publishingCompanyId}/>
            </div>
          </div>
          <div className="tabbed-details-tabs-container">
            <Tab.Container activeKey={this.state.activeTab} onSelect={this.handleTabSelect}>
              <div className="tabbed-details-tab-nav-container">
                <Nav variant="tabs">
                  <Nav.Link eventKey={DEFAULT_TAB_KEY}
                            href={extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS) + DEFAULT_TAB_KEY}>
                    {t('publishing.company.details.header.basicinfo')}
                  </Nav.Link>
                  <Nav.Link eventKey={JOURNALS_TAB_KEY}
                            href={extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS) + JOURNALS_TAB_KEY}>
                    {t('publishing.company.details.header.journals')}
                  </Nav.Link>
                  <Nav.Link eventKey={BOOKS_TAB_KEY}
                            href={extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS) + BOOKS_TAB_KEY}>
                    {t('publishing.company.details.header.books')}
                  </Nav.Link>
                </Nav>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey={DEFAULT_TAB_KEY}>
                  {this.basicDetails(t)}
                </Tab.Pane>
                <Tab.Pane eventKey={JOURNALS_TAB_KEY}>
                  <h2 className="sr-only">{t('publishing.company.details.header.journals')}</h2>
                  <PublishingCompanyJournals active={this.state.activeTab === JOURNALS_TAB_KEY}
                                             publishingCompanyId={publishingCompanyId}
                                             api={this.props.api}/>
                </Tab.Pane>
                <Tab.Pane eventKey={BOOKS_TAB_KEY}>
                  <h2 className="sr-only">{t('publishing.company.details.header.books')}</h2>
                  <PublishingCompanyBooks active={this.state.activeTab === BOOKS_TAB_KEY}
                                          publishingCompanyId={publishingCompanyId}
                                          api={this.props.api}
                                          location={this.props.location}/>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </main>
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  handleTabSelect = (tabKey, event) => {
    const locationWithoutTabKey = extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS);
    event.preventDefault();
    this.setState({activeTab: tabKey});
    this.props.history.push(locationWithoutTabKey + tabKey);
  }


  basicDetails(t) {
    if (this.state.publishingCompany) {
      if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: this.state.publishingCompany.name });
      return (
        <>
          {this.state.activeTab === DEFAULT_TAB_KEY &&
            <Helmet meta={[{name: "description", content: this.getMetaDescription(t)}]}/>
          }
          <Row className="tabbed-details-tab-basic-info">
            <h2 className="sr-only">{t('publishing.company.details.header.basicinfo')}</h2>
            <PublishingCompanyBasicInfo publishingCompany={this.state.publishingCompany}/>
          </Row>
        </>
      )
    } else {
      return <PubroSpinner/>
    }
  }

  getBreadcrumbs(publishingCompany) {
    return [{ label: publishingCompany.name, link: null}];
  }

  getMetaTitle(t) {
    const defaultTitle = generateMetaTitle(t, {});
    const publishingCompany = this.state.publishingCompany;

    if (publishingCompany && publishingCompany.name) {
      if (this.state.activeTab === DEFAULT_TAB_KEY) {
        return generateMetaTitle(t, {"text": publishingCompany.name});
      }
      else {
        return generateMetaTitle(t, {"text": publishingCompany.name, "subLabel": "publishing.company.details.header." + this.state.activeTab.substring(1)});
      }
    }
    else {
      return defaultTitle;
    }
  }

  getMetaDescription(t) {
    const defaultDescription = generateMetaDescription(t, {});
    const publishingCompany = this.state.publishingCompany;

    if (publishingCompany) {
      if (this.state.activeTab === DEFAULT_TAB_KEY) {
        let description = generateSeparatedList({"type": "publishingCompanyAttributes", "data": publishingCompany})

        if (description.length === 0) {
          return defaultDescription;
        }
        else {
          return generateMetaDescription(t, {"text": description})
        }
      }
    }
    else {
      return defaultDescription;
    }
  }
}

export default withTranslation()(PublishingCompanyDetails);
