import React from 'react';
import SearchResultFrame from '../common/SearchResultFrame';
import ChapterSearchResultSidebar from './ChapterSearchResultSidebar';
import SearchResultCommonBody from '../common/SearchResultCommonBody';

/**
 * Search result list item for chapters. Renders a common search result frame
 * with chapter-specific body and sidebar.
 *
 * @author sniewcza
 */
export default function ChapterSearchResultListItem({document, urlQueryValues, api}) {
  const mainBody = <SearchResultCommonBody headerKey={'search.result.list.item.frame.chapter.header'}
                                           abstractHeaderKey={'search.result.list.item.summary.header'}
                                           document={document} urlQueryValues={urlQueryValues} api={api}
                                           accessRestricted={document.accessRestricted}/>;
  const sidebar = <ChapterSearchResultSidebar document={document} urlQueryValues={urlQueryValues}/>;
  return (
    <SearchResultFrame mainBody={mainBody}
                       sidebar={sidebar}/>
  );
}
