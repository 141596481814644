import React from "react";
import PaginationLink from './PaginationLink';
import Pagination from 'react-bootstrap/Pagination';
import { useTranslation } from "react-i18next";
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleDoubleRight, FaAngleRight } from 'react-icons/fa';

/**
 * Renders pagination links for search result list. Expects the following to be
 * passed as props:
 *
 *  - currentPage - number of the currently displayed page
 *  - totalResults - number of all results found for current search criteria
 *  - pageSize - number of search results per page
 *  - linkFn - function (a closure) to generate links with
 */
export default function ResultListPagination({currentPage, totalResults, pageSize, linkFn}) {
  const { t } = useTranslation();
  const totalPages = Math.ceil(totalResults / pageSize);
  const paginationItems = [];
  const pagesBeforeAndAfterCurrent = 4;

  if (currentPage > 1) {
    pushFirstAndPrevious(linkFn, paginationItems, currentPage, t);
  }

  pushPageLinksBeforeCurrent(linkFn, paginationItems, currentPage, pagesBeforeAndAfterCurrent, t);
  paginationItems.push(<Pagination.Item key={currentPage} active activeLabel={t('search.pagination.current.label')}>{currentPage}</Pagination.Item>);
  pushPageLinksAfterCurrent(linkFn, paginationItems, currentPage, totalPages, pagesBeforeAndAfterCurrent, t);

  if (currentPage < totalPages) {
    pushNextAndLast(linkFn, paginationItems, currentPage, totalPages, t);
  }

  return (
    <Pagination className='pagination-container'>
      {paginationItems}
    </Pagination>
  );
}

// -------------------- PRIVATE --------------------

function pushFirstAndPrevious(linkFn, paginationItems, currentPage, t) {
  paginationItems.push(
    <PaginationLink key={'first'} linkFn={linkFn} page={1}>
      <Pagination.Item aria-label={t('search.pagination.first.ariaLabel')} data-page-first className='pagination-icon'>
        <FaAngleDoubleLeft aria-hidden="true" />
      </Pagination.Item>
    </PaginationLink>
  );
  paginationItems.push(
    <PaginationLink key={'previous'} linkFn={linkFn} page={currentPage-1}>
      <Pagination.Item aria-label={t('search.pagination.previous.ariaLabel')} className="mr-2" data-page-prev className='pagination-icon'>
        <FaAngleLeft aria-hidden="true" />
      </Pagination.Item>
    </PaginationLink>
  );
}

function pushPageLinksBeforeCurrent(linkFn, paginationItems, currentPage, linkCount, t) {
  for (let i = linkCount; i > 0; i--) {
    if ((currentPage - i) > 0) {
      paginationItems.push(
        <PaginationLink key={currentPage - i} linkFn={linkFn} page={currentPage - i}>
          <Pagination.Item aria-label={(currentPage-i) + ' ' + t('search.pagination.page.ariaLabelPostfix')}>
            {currentPage - i}
          </Pagination.Item>
        </PaginationLink>
      );
    }
  }
}

function pushPageLinksAfterCurrent(linkFn, paginationItems, currentPage, totalPages, linkCount, t) {
  for (let i = 1; i <= linkCount; i++) {
    if ((currentPage + i) <= totalPages) {
      paginationItems.push(
        <PaginationLink key={currentPage + i} linkFn={linkFn} page={currentPage + i}>
          <Pagination.Item aria-label={(currentPage+i) + ' ' + t('search.pagination.page.ariaLabelPostfix')}>
            {currentPage + i}
          </Pagination.Item>
        </PaginationLink>
      );
    }
  }
}

function pushNextAndLast(linkFn, paginationItems, currentPage, totalPages, t) {
  paginationItems.push(
    <PaginationLink key={'next'} linkFn={linkFn} page={currentPage+1}>
      <Pagination.Item aria-label={t('search.pagination.next.ariaLabel')}  className="ml-2" data-page-next className='pagination-icon'>
        <FaAngleRight aria-hidden="true" />
      </Pagination.Item>
    </PaginationLink>
  );
  paginationItems.push(
    <PaginationLink key={'last'} linkFn={linkFn} page={totalPages}>
      <Pagination.Item aria-label={t('search.pagination.last.ariaLabel')} data-page-last className='pagination-icon'>
        <FaAngleDoubleRight aria-hidden="true" />
      </Pagination.Item>
    </PaginationLink>
  );
}
