import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Renders a publication type. Expects the following props:
 * - publicationType'
 * - publicationTypePrefix.
 */
export default function PublicationType({ publicationType, publicationTypePrefix }) {
  const { t } = useTranslation();

  if (publicationType) {
    return (
      <>{t(`${publicationTypePrefix}${publicationType.toLowerCase()}`)}</>
    );
  } else {
    return null;
  }
}
