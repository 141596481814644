import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertToIetfLanguageTag } from '../../common/LanguageUtils';
import AccessibleOverlayTrigger from '../../common/AccessibleOverlayTrigger';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';
import FormattedContent from '../common/FormattedContent';

/**
 * Renders abstracts in form of tabs. Expects the following props:
 * - mainAbstracts
 * - abstractTranslations
 *
 * The header can be customised by passing the translation label as 'headerKey'
 * prop.
 */
export default function AbstractTabList({ mainAbstract, abstractTranslations, headerKey, preserveWhitespace }) {
  const { t } = useTranslation();
  abstractTranslations = abstractTranslations || [];

  if (!mainAbstract && abstractTranslations.length === 0) {
    return;
  }

  if (abstractTranslations.length === 0) {
    return (
      <Row>
        <Col>
          <h2>{t(headerKey ? headerKey : 'publication.details.non.book.abstracts.header')}</h2>
          <div className={preserveWhitespace ? 'abstract-text preserve-whitespace' : 'abstract-text'}
                lang={convertToIetfLanguageTag(mainAbstract.language)}>
            <ProcessMath>
              <FormattedContent content={transformAsciiMathDelimiter(mainAbstract.text)} />
            </ProcessMath>
          </div>
        </Col>
      </Row>
    )
  }

  const abstracts = [mainAbstract].concat(abstractTranslations);

  return (
    <Row>
      <Col>
        <Tab.Container defaultActiveKey={abstracts[0].language}>
          <div className="header-with-tabs">
          <h2>{t(headerKey ? headerKey : 'publication.details.non.book.abstracts.header')}</h2>
          <Nav variant="tabs">
            {abstracts.map((abstr, index) =>
              <AccessibleOverlayTrigger key={index} overlay={<Tooltip>{t('dictionary.language.' + abstr.language)}</Tooltip>}>
                <Nav.Item>
                  <Nav.Link eventKey={abstr.language}>{abstr.language}</Nav.Link>
                </Nav.Item>
              </AccessibleOverlayTrigger>
            )}
          </Nav>
          </div>
          <Tab.Content>
            {abstracts.map((abstr, index) =>
              <Tab.Pane key={index} eventKey={abstr.language} title={abstr.language}>
                <div className={preserveWhitespace ? 'abstract-text preserve-whitespace' : 'abstract-text'}
                      lang={convertToIetfLanguageTag(abstr.language)}>
                <ProcessMath>
                  <FormattedContent content={transformAsciiMathDelimiter(abstr.text)} />
                </ProcessMath>
                </div>
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  );
}
