import React from 'react';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { Parser } from 'html-to-react';

/**
 * Renders html from [Quill Delta]{@link https://quilljs.com/docs/delta/} object
 *
 * @param delta Quill Delta object
 * @returns Rendered html
 */
export default function DeltaRenderer({delta}) {
  const converter = new QuillDeltaToHtmlConverter(delta["ops"]);

  const html = converter.convert();

  return (
    <div id="deltaRenderer" className="ql-editor">
      {new Parser().parse(html)}
    </div>
  )
};
