import React from "react";
import Select from 'react-select';
import { getSelectAriaLiveMessages } from '../../common/utils';
import { withTranslation } from 'react-i18next';

/**
 * Renders a select field with available page sizes. Fires the handler passed
 * in as `onChange` prop to report back to Search.
 *
 * Expects the following props:
 *  - `pageSizes` - all valid page sizes
 *  - `currentPageSize` - current page size; `Search` will use the default if
 *    not included in URL params
 *  - `onChange` - change event handler
 *
 * @author sniewcza
 */
class PageSizeForm extends React.Component {

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.state = {
      pageSizeOptions: this.props.pageSizes.map(s => ({label: s, value: s})),
    };
  }

  // -------------------- LOGIC --------------------

  render() {
    return <Select inputId={this.props.inputId}
                   options={this.state.pageSizeOptions}
                   defaultValue={this.state.pageSizeOptions.filter(opt => opt.value === this.props.currentPageSize)}
                   onChange={selectedOption => this.props.onChange(selectedOption.value)}
                   ariaLiveMessages={getSelectAriaLiveMessages(this.props.t)}
                   isSearchable={false} isClearable={false}/>;
  }
}

export default withTranslation()(PageSizeForm);
