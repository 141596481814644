import {convertDeltaStringToHtml, emptyArrayToUndefined, groupBy} from '../common/utils';
import { convertToIetfLanguageTag, getCurrentLanguageShortCode } from '../common/LanguageUtils';
import {createUrlExternalId} from '../publication/common/ExternalIdentifierUtil';

export function buildJournalJsonLd(journal, issues, i18n) {

  const reactAppBaseUrl = window.location.origin;

  let journalJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Periodical',
    name: journal.mainTitle.text,
    publisher: buildOrganizationLdFromPublishingCompanyInfo(journal.publishingCompanyInfo, reactAppBaseUrl)
  };

  journalJsonLd.issn = emptyArrayToUndefined(journal.externalIdentifiers
                                               .filter(externalId => ['ISSN', 'EISSN'].includes(externalId.type))
                                               .map(externalId => externalId.text));

  let sameAsJsonLd = journal.externalIdentifiers
                            .filter(externalId => externalId.type === 'DOI')
                            .map(externalId => createUrlExternalId(externalId));

  sameAsJsonLd = sameAsJsonLd.concat(journal.links);

  journalJsonLd.sameAs = emptyArrayToUndefined(sameAsJsonLd);


  let alternateNameJsonLd = journal.mainTitleTranslations.map(titleTranslation => titleTranslation.text);

  alternateNameJsonLd = alternateNameJsonLd.concat(journal.additionalTitles
        .filter(title => ['ABBREVIATION', 'ROMANIZATION', 'ALTERNATIVE'].includes(title.type))
        .map(title => title.text));

  journalJsonLd.alternateName = emptyArrayToUndefined(alternateNameJsonLd);


  journalJsonLd.disambiguatingDescription = extractTitleValueWithType(journal.additionalTitles, 'SUBTITLE');

  journalJsonLd.isPartOf = buildSeriesLdFromSeriesTitle(journal);


  if (journal.mainDescription && journal.mainDescription.text) {
    journalJsonLd.description = convertDeltaStringToHtml(journal.mainDescription.text);
  }

  if (journal.license) {
    const licenseUrl = getCurrentLanguageShortCode(i18n.languages) === 'pl' ? journal.license.urlPl : journal.license.urlEn;
    if (licenseUrl) {
      journalJsonLd.license = licenseUrl;
    }
  }

  if (journal.language) {
    journalJsonLd.inLanguage = convertToIetfLanguageTag(journal.language);
  }

  journalJsonLd.hasPart = emptyArrayToUndefined(buildPublicationVolumesAndIssuesLd(issues, reactAppBaseUrl));

  return journalJsonLd;
}

// -------------------- PRIVATE --------------------

function buildOrganizationLdFromPublishingCompanyInfo(publishingCompanyInfo, reactAppBaseUrl) {
  return {
    '@type': 'Organization',
    name: publishingCompanyInfo.name,
    url: `${reactAppBaseUrl}/publishing-companies/${publishingCompanyInfo.id}`
  };
}

function buildPublicationVolumesAndIssuesLd(issues, reactAppBaseUrl) {
  let volumesAndIssuesLd = [];

  const issuesByYearAndVolume = groupBy(issues, issue => issue.year + '_' + (issue.volume ? issue.volume : ''));

  for (const yearAndVolumeNumber of Object.keys(issuesByYearAndVolume)) {
    const issuesLd = issuesByYearAndVolume[yearAndVolumeNumber]
      .map(issue => buildPublicationIssueLdFromIssue(issue, reactAppBaseUrl));

    const parts = yearAndVolumeNumber.split('_');
    const volumeNumber = parts[1];

    if (volumeNumber) {
      let volumeLd = buildPublicationVolumeLdFromNumber(volumeNumber);
      volumeLd.hasPart = issuesLd;
      volumesAndIssuesLd.push(volumeLd);
    } else {
      volumesAndIssuesLd = volumesAndIssuesLd.concat(issuesLd);
    }

  }

  return volumesAndIssuesLd;
}

function buildPublicationIssueLdFromIssue(issue, reactAppBaseUrl) {
  const issueJsonLd = {
    '@type': 'PublicationIssue',
    issueNumber: issue.number,
    url: `${reactAppBaseUrl}/issues/${issue.id}`
  }
  if (issue.year) {
    issueJsonLd.datePublished = issue.year;
  }
  return issueJsonLd;
}
function buildPublicationVolumeLdFromNumber(volumeNumber) {
  return {
    '@type': 'PublicationVolume',
    volumeNumber: volumeNumber,
  }
}

function buildSeriesLdFromSeriesTitle(journal) {
  return journal.seriesTitle ? ({'@type': 'Series', name: journal.seriesTitle}) : undefined;
}

function extractTitleValueWithType(additionalTitles, type) {
  const title = additionalTitles.find(title => title.type === type);
  return title ? title.text : undefined;
}
