import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function PbnExportButton({pbnExportUrl, disabled}) {
  const { t } = useTranslation();
  
  const button = disabled 
    ? <Button disabled size="sm" className="export__link" variant='light'>
        {t('pbn.export.button.label')}
      </Button>
    : <Button href={`${pbnExportUrl}`} target='_blank' rel='noopener noreferrer'
        size="sm" className="export__link" variant='light'>
        {t('pbn.export.button.label')}
        <span className="sr-only">{t('link.externalLink.label')}</span>
      </Button>

  return (
    <>
      {pbnExportUrl && button }
    </>
  );
}