import React, { Fragment } from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import {abbreviate, transformAsciiMathDelimiter} from './utils';
import Tooltip from 'react-bootstrap/Tooltip';
import AccessibleOverlayTrigger from './AccessibleOverlayTrigger';
import { useTranslation } from "react-i18next";
import ProcessMath from './ProcessMath';
import {cleanFormattedContent} from '../publication/common/FormattedContentUtil';
import FormattedContent from "../publication/common/FormattedContent";

/**
 * Renders a list of links to entities higher in the hierarchy, above the one,
 * which is currently being displayed. Eg, when rendering an article,
 * breadcrumbs will contain the following elements, from first to last:
 *  - linked name of the publishing company
 *  - linked name of the journal
 *  - linked name of the issue
 *  - main title of the article (no link, last element is the active one)
 */
export default function Breadcrumbs({ items, maxItemLength }) {
  const { t } = useTranslation();
  return (
    <div  className="breadcrumb-container">
      <Container>
        <Breadcrumb label={t('breadrumb.aria-label')}>
          {
            items.map((item, index) => {
              return (<Fragment key={index}>
                  {item.link
                    ? linkItem(item, index, maxItemLength)
                    : regularItem(item)}</Fragment>
              );
            })
          }
        </Breadcrumb>
      </Container>
    </div>
  )
}

// -------------------- PRIVATE --------------------

function linkItem(item, index, maxLinkItemLength) {

  const tooltip = <Tooltip id={'breadcrumb-tooltip-' + index}>{itemFullLengthContent(item)}</Tooltip>;

  return (
    <AccessibleOverlayTrigger placement='bottom' overlay={tooltip}>
      <LinkContainer to={item.link}>
        <Breadcrumb.Item>{maxLinkItemLength > 0 ? itemAbbreviatedContent(item, maxLinkItemLength) : itemFullLengthContent(item)}</Breadcrumb.Item>
      </LinkContainer>
    </AccessibleOverlayTrigger>
  );
}

function regularItem(item) {

  return (
    <Breadcrumb.Item active>
      {itemFullLengthContent(item)}
    </Breadcrumb.Item>
  );
}

function itemAbbreviatedContent(item, maxLength) {
  const cleanedLabel = item.html ? cleanFormattedContent(item.label) : item.label;
  const abbreviatedLabel = abbreviate(cleanedLabel, maxLength);
  return (<>
    {item.math ? <ProcessMath>{transformAsciiMathDelimiter(abbreviatedLabel)}</ProcessMath> : abbreviatedLabel}
    </>
  );
}

function itemFullLengthContent(item) {
  return (<>
      {item.math && item.html && <ProcessMath><FormattedContent as='span' content={transformAsciiMathDelimiter(item.label)} /></ProcessMath>}
      {item.math && !item.html && <ProcessMath>{transformAsciiMathDelimiter(item.label)}</ProcessMath>}
      {!item.math && item.html && <FormattedContent as='span' content={item.label} />}
      {!item.math && !item.html && item.label}
      </>
  );
}