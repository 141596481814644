import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {sortLinkedJournals} from "./LinkedJournalSortUtil";

/**
 * Renders list of links to linked journals and connection descriptions.
 */
export default function JournalLinkedJournalsSidePanel({ linkedJournals }) {

  const { t } = useTranslation();
  if (linkedJournals.length === 0) {
    return null;
  }

  return (
    <>
      <h3>{t('journal.details.linked-journals')}</h3>
      <div>
        {sortLinkedJournals(linkedJournals).map((linkedJournal, index) => (
          <div key={'linked-journal-' + index} className="linked-journal-link-container" data-linked-journals='linked-journals'>
            <Link to={`/journals/${linkedJournal.targetJournal.id}`} key={'linked-journal-link-' + index}>
              <span className="linked-journal-link-text">{linkedJournal.targetJournal.mainTitle}</span>
            </Link>
            {linkedJournal.description ? <span> ({linkedJournal.description})</span> : ''}
          </div>
        ))}
      </div>
    </>
  );
}
