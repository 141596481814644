import React from 'react';
import BookCoverThumbnail from '../../../../publication/book/BookCoverThumbnail';
import { joinWithYear } from '../common/SearchResultUtils';
import SearchResultScientificDisciplines from '../common/SearchResultScientificDisciplines';
import ProcessMath from '../../../../common/ProcessMath';
import FormattedContent from '../../../../publication/common/FormattedContent';
import { transformAsciiMathDelimiter } from '../../../../common/utils';


/**
 * Renders sidebar part of chapter search result. Includes book cover, book
 * title and release year, publishing company name and full text download
 * buttons.
 *
 * @author sniewcza
 */
export default function ChapterSearchResultSidebar({document, urlQueryValues}) {
  return (
    <>
      <div>
        <BookCoverThumbnail coverId={document.coverId} bookTitle={document.bookTitle}/>
      </div>
      <div className='search-result-book'>
        <ProcessMath>
          <FormattedContent as='span' content={transformAsciiMathDelimiter(joinWithYear(document.bookTitle, document.publishedDate))} />
        </ProcessMath>
      </div>
      <div>
        <div className='search-result-publishing-company'>{document.publishingCompanyName}</div>
      </div>
      {document.scientificDisciplines.length > 0 && <SearchResultScientificDisciplines scientificDisciplines={document.scientificDisciplines}
                                                                                       urlQueryValues={urlQueryValues}/> }
    </>
  );
}
