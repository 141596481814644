import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertToIetfLanguageTag } from '../../common/LanguageUtils';
import AccessibleOverlayTrigger from '../../common/AccessibleOverlayTrigger';
import './TitleList.css';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';
import FormattedContent from '../common/FormattedContent';

/**
 * Renders publication titles (main title at the top, and a list of title
 * translations). Expects the following props:
 *  - mainTitle
 *  - mainTitleTranslations with translation language
 */
export default function TitleList({ mainTitle, mainTitleTranslations }) {
  const { t } = useTranslation();

  if (mainTitleTranslations.length === 0) {
    return (
      <Row>
        <Col>
          <h2>{t('publication.details.title.header')}</h2>
            <h1 lang={convertToIetfLanguageTag(mainTitle.language)}>
              <ProcessMath>
                <FormattedContent as='span' content={transformAsciiMathDelimiter(mainTitle.text)} />
              </ProcessMath>
            </h1>
        </Col>
      </Row>
    )
  }

  const titles = [mainTitle].concat(mainTitleTranslations);

  return (
    <>
      <Tab.Container defaultActiveKey={titles[0].language}>
        <div className="header-with-tabs">
          <h2>{t('publication.details.title.header')}</h2>
          <Nav variant="tabs">
            {titles.map((translation, index) =>
              <AccessibleOverlayTrigger key={index} overlay={<Tooltip>{t('dictionary.language.' + translation.language)}</Tooltip>}>
                <Nav.Item>
                  <Nav.Link eventKey={translation.language}>{translation.language}</Nav.Link>
                </Nav.Item>
              </AccessibleOverlayTrigger>
            )}
          </Nav>
        </div>
        <Tab.Content>
          {titles.map((translation, index) =>
            <Tab.Pane key={index} eventKey={translation.language} title={translation.language}>
              <h1 lang={convertToIetfLanguageTag(translation.language)}>
                <ProcessMath>
                  <FormattedContent as='span' content={transformAsciiMathDelimiter(translation.text)} />
                </ProcessMath>
              </h1>
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
