import React from "react";
import {Link} from 'react-router-dom';
import {assemblePublicationUrl} from './SearchResultUtils';
import HighlightedContent from './HighlightedContent';
import ProcessMath from '../../../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../../../common/utils';

/**
 * Renders publication title on the search result list item. The title is linked
 * to publication details view. Title text is either a highlighted title
 * snippet, or a title itself if snippet is not present.
 *
 * Takes the following props:
 *  - `title` - publication title
 *  - `titleSnippets` - list of highlighting snippets
 *  - `publicationId` - identifier of the publication
 *  - `publicationType` - type of the publication, to assemble the details URL
 *
 * @author sniewcza
 */
export default function SearchResultTitle({title, titleSnippets, publicationId, publicationType}) {
  const publicationUrl = assemblePublicationUrl(publicationId, publicationType);
  return (
    <Link to={publicationUrl} className="search-result-title-link">
      <h2>
        {titleSnippets.length > 0
          ? <ProcessMath><HighlightedContent content={transformAsciiMathDelimiter(titleSnippets[0])} as={'span'}/></ProcessMath>
          : <ProcessMath>{transformAsciiMathDelimiter(title)}</ProcessMath>
        }
      </h2>
    </Link>
  );
}
