import React from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {useTranslation} from 'react-i18next';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

/**
 * Simple search input field with search scope dropdown and submit button
 * attached. Requires `formik` prop.
 *
 * @author sniewcza
 */
export default function SimpleSearchForm({formik}) {
  const {t} = useTranslation();
  return (
    <InputGroup className='mb-3 search-form'>
      <FormControl type='text' {...formik.getFieldProps('q')}
                   aria-label={t('search.form.simple.placeholder')}
                   placeholder={t('search.form.simple.placeholder')} />
      <DropdownButton as={InputGroup.Append} variant='outline-secondary' title={t(getCurrentScopeLabel(formik.values.scope))} id='scope-dropdown'>
        <Dropdown.Item onSelect={() => formik.setFieldValue('scope', null)}>{t('search.form.simple.scope.all')}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onSelect={() => formik.setFieldValue('scope', 'journals')}>{t('search.form.simple.scope.journals')}</Dropdown.Item>
        <Dropdown.Item onSelect={() => formik.setFieldValue('scope', 'books')}>{t('search.form.simple.scope.books')}</Dropdown.Item>
      </DropdownButton>
      <InputGroup.Append>
        <Button type='submit' variant='primary'>{t('search.form.simple.button.submit.label')}</Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

// -------------------- PRIVATE --------------------

function getCurrentScopeLabel(scope) {
  switch (scope) {
    case 'journals': return 'search.form.simple.scope.journals';
    case 'books': return 'search.form.simple.scope.books';
    default: return 'search.form.simple.scope.all';
  }
}
