import React from "react";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import DeltaRenderer from "../common/DeltaRenderer";

/**
 * Renders journal for authors information. JournalVO is expected to be passed
 * as 'journal' prop.
 */
export default function JournalForAuthors({ journal }) {
  const { t } = useTranslation();

  const forAuthorsDescription = journal && journal.additionalDescriptions.find(x => x.type === "FOR_AUTHORS_AND_REVIEWERS");

  return (
    <>
      <Container>
        <Row>
          <Col>
            {forAuthorsDescription ? forAuthors(forAuthorsDescription) : <em>{t('journal.details.for-authors.empty')}</em>}
          </Col>
        </Row>
      </Container>
    </>
  );
}

// -------------------- PRIVATE --------------------

function forAuthors(forAuthors) {
  return (
    <div data-issue="for-authors">
      <DeltaRenderer delta={JSON.parse(forAuthors.text)}/>
    </div>
  );
}