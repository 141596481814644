import React from "react";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from 'react-icons/fa';

/**
 * Renders journal contact information. JournalVO is expected to be passed
 * as 'journal' prop.
 */
export default function JournalContact({ journal }) {
  const { t } = useTranslation();
  const areContactAndLinksEmpty = !journal.contact && journal.links.length === 0;

  return (
    <>
      <Container>
        <Row>
          <Col className="break-word space-between-list-elements">
            {areContactAndLinksEmpty &&
              <em>{t('journal.details.contact.empty')}</em>
            }
            {journal.contact &&
              <>
                <h3>{t('journal.details.header.contact')}</h3>
                {contact(journal.contact)}
              </>
            }
            {journal.links.length > 0 &&
              <>
                <h3>{t('journal.details.header.links')}</h3>
                {links(journal.links, t)}
              </>
            }
          </Col>
        </Row>
      </Container>

    </>
  );
}

// -------------------- PRIVATE --------------------

function contact(contact) {
  return (
    <div className='preserve-whitespace journal-contact'>{contact}</div>
  );
}

function links(links, t) {
  return (
    <>
      {links.map((link, index) => (
        <div key={'link' + index}>
          <a href={link} target="_blank" rel="noopener noreferrer" data-journal-link>
            {link}
            <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
          </a>
        </div>
      ))}
    </>
  );
}
