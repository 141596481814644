import sanitizeHtml from 'sanitize-html';

const pubDetailUrlPrefixes = {
  'ARTICLE': '/articles/',
  'CHAPTER': '/chapters/',
  'SIMPLE_BOOK': '/books/',
  'COLLECTIVE_WORK': '/books/'
};

// -------------------- LOGIC --------------------

/**
 * Assembles a relative URL to publication details, based on its id and type.
 * Throws an error on invalid publication type.
 */
export function assemblePublicationUrl(pubId, pubType) {
  if (pubType in pubDetailUrlPrefixes) {
    return pubDetailUrlPrefixes[pubType] + pubId;
  }
  throw new Error("Invalid publication type: " + pubType);
}

/**
 * Escapes all html tags except `<em>`. Strips all attributes from `<em>` tags.
 */
export function sanitizeHighlightedContent(html) {
  return sanitizeHtml(html, {
    allowedTags: [ 'em' ],
    allowedAttributes: {
      'em': []
    },
    disallowedTagsMode: 'recursiveEscape',
  });
}

/**
 * Joins given string value with year extracted from date string, eg:
 *  - `join('Warszawa', '2000-03-30') -> 'Warszawa (2000)'`
 *  - `join('Book Title', '2011-02') -> 'Book Title (2011)'`
 */
export function joinWithYear(value, publishedDate) {
  const yearSuffix = publishedDate ? (' (' + extractYear(publishedDate) + ')') : '';
  return value + yearSuffix;
}

/**
 * Extracts the year part of date string, eg. will return `2000` for date
 * `2000-03-21`.
 */
export function extractYear(date) {
  return date.match(/^(\d{4}).*/)[1];
}
