import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';

/**
 * Renders remarks. Expects the remarks string to be passed as 'remarks' prop.
 */
export default function RemarksDetails({ remarks }) {
  const { t } = useTranslation();
  if (remarks) {
    return (
      <Row>
        <Col>
          <h2>{t('publication.details.remarks.header')}</h2>
          <p className='preserve-whitespace'>
            <ProcessMath>{transformAsciiMathDelimiter(remarks)}</ProcessMath>
          </p>
        </Col>
      </Row>
    );
  }
}
