import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import WbnAccessInfoIcon from '../common/WbnAccessInfoIcon';

class RestrictedStatCounters extends React.Component {

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    const stats = this.props.stats;
    const classes = "front-page-stat fade-in";
    
    return (
      <>
        <Row>

          <h2 className="stats-header">
            {t('welcome.stats.restrictedAccess.header')}
            <WbnAccessInfoIcon publisherNames={this.parsePublisherNames()} />
          </h2>

          <Col xs={6} sm={6} md={3} lg={3} xl={12} className={classes}>
            <div className="stats-number">{stats ? stats.articleCount : 0}</div>
            <div className="stats-desc">{t('search.form.simple.stats.articles')}</div>
          </Col>
          
          <Col xs={6} sm={6} md={3} lg={3} xl={12} className={classes}>
            <div className="stats-number">{stats ? stats.journalCount : 0}</div>
            <div className="stats-desc">{t('search.form.simple.stats.journals')}</div>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={12} className={classes}>
            <div className="stats-number">{stats ? stats.bookCount : 0}</div>
            <div className="stats-desc">{t('search.form.simple.stats.books')}</div>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={12} className="front-page-stat hidden" aria-hidden="true">
            <div className="stats-number">-</div>
            <div className="stats-desc">{t('search.form.simple.stats.oncclicence')}</div>
          </Col>
        
        </Row>
      </>
    );
  }

  parsePublisherNames() {
    const stats = this.props.stats;

    if (!!stats && stats.accessiblePublisherNames) {
      return stats.accessiblePublisherNames.join(", ");
    }

    return "-";
  }
}

export default withTranslation()(RestrictedStatCounters);
