import React from 'react';
import SearchResultContributorList from './SearchResultContributorList';
import SearchResultAbstract from './SearchResultAbstract';
import SearchResultFullText from './SearchResultFullText';
import SearchResultTitle from './SearchResultTitle';
import SearchResultFullTextButtons from './SearchResultFullTextButtons';
import SearchResultLicense from './SearchResultLicense';
import {useTranslation} from 'react-i18next';
import WbnAccessIcon from '../../../../common/WbnAccessIcon';

SearchResultCommonBody.defaultProps = {
  abstractHeaderKey: 'search.result.list.item.abstract.header'
}

/**
 * Renders main parts of a search result common for articles, simple books and
 * chapters. Collective works have their own content, with main difference being
 * the presence of chapter titles and lack of full text snippet.
 *
 * Displays the following parts:
 *  - title, with highlighting if present
 *  - horizontal list of contributors, with highlighting if present
 *  - abstract/summary, with highlighting if present
 *  - highlighted full text snippet, if present
 *
 *  Expects the document to be passed in as `document` prop.
 *  Can also take the `abstractHeaderKey` to customize the header above the
 *  abstract. Will use the default value if not present.
 *
 *  @author sniewcza
 */
export default function SearchResultCommonBody({document, headerKey, abstractHeaderKey, urlQueryValues, api, accessRestricted}) {
  const {t} = useTranslation();

  const hasFulltextOrLicense = document.fullTextFormats.length > 0 || document.license;

  return (
    <>
      {(hasFulltextOrLicense) &&
        <div className="search-result-download-licence-container">
          {document.fullTextFormats.length > 0 &&
            <span>
              <span className="search-result-download-label">{t('search.result.list.item.download.label')}</span>
              <SearchResultFullTextButtons document={document} api={api} />
            </span>
          }
          {accessRestricted && <WbnAccessIcon type={document.publicationType} />}
          {document.license && 
            <div className="float-right">
              <SearchResultLicense license={document.license}/>
            </div>
          }
        </div>
      }
      <div className="publication-kind">{t(headerKey)}</div>
      <SearchResultTitle title={document.mainTitle}
                         titleSnippets={document.mainTitleSnippets}
                         publicationId={document.publicationId}
                         publicationType={document.publicationType}/>
      {document.contributors.length > 0 && <SearchResultContributorList contributorNames={document.contributors}
                                                                        contributorSnippets={document.contributorSnippets}
                                                                        urlQueryValues={urlQueryValues}/>}
      <h3>{t(abstractHeaderKey)}</h3>
      <SearchResultAbstract abstract={document.mainAbstract} abstractSnippets={document.mainAbstractSnippets} />
      {document.fullTextSnippets.length > 0 && <>
        <h3>{t('search.result.list.item.fulltext.header')}</h3>
        <SearchResultFullText fullTextSnippet={document.fullTextSnippets[0]}/>
      </>}
    </>
  );
}
