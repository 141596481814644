import { emptyArrayToUndefined, getAbsoluteApiUrl } from '../common/utils';

export function buildPublishingCompanyJsonLd(publishingCompany) {

  const reactAppBaseUrl = window.location.origin;
  const restApiBaseUrl = getAbsoluteApiUrl();

  let publishingCompanyJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: `${reactAppBaseUrl}/publishing-companies/${publishingCompany.id}`,
    name: publishingCompany.name,
  };

  publishingCompanyJsonLd.alternateName = emptyArrayToUndefined(publishingCompany.additionalNames);

  if (publishingCompany.logoId) {
    publishingCompanyJsonLd.logo = `${restApiBaseUrl}/images/publishing-company-logos/${publishingCompany.logoId}`;
  }

  return publishingCompanyJsonLd;
}
