import ListGroup from 'react-bootstrap/ListGroup';
import React from 'react';
import { Link } from 'react-router-dom';
import { buildContributorFullName } from '../../common/utils';
import ProcessMath from '../../common/ProcessMath';
import FormattedContent from '../common/FormattedContent';
import { transformAsciiMathDelimiter } from '../../common/utils';
import FullTextButtonList from '../common/FullTextButtonList';
import WbnAccessIcon from '../../common/WbnAccessIcon';

/**
 * Displays a linked panel with chapter details.
 * To be used in chapter / book part lists.
 */
export default function ChapterListItem({ chapter, book, isBookPart, api }) {
  return (
    <ListGroup.Item className={`publication-chapters-list-item ${chapter.restricted ? "wbn-access-restricted" : ""}`}>
        <p className="publication-download-licence-container mt-0">
          {chapter.fullTextFormats && chapter.fullTextFormats.length > 0 && <FullTextButtonList publication={publicationDetails()}
                          publicationKind='chapter.details.kind'
                          api={api}/>}
          {chapter.restricted && <WbnAccessIcon type={'CHAPTER'}/>}
        </p>
        <Link to={`/chapters/${chapter.id}`} className="chapter-link">
        {chapter.pageRange && <div className="page-range">{chapter.pageRange}</div>}
        {!isBookPart && <h3><ProcessMath><FormattedContent as='span' content={transformAsciiMathDelimiter(chapter.title)} /></ProcessMath></h3>}
        {isBookPart && <h4><ProcessMath><FormattedContent as='span' content={transformAsciiMathDelimiter(chapter.title)} /></ProcessMath></h4>}
        {chapter.contributors.length > 0 &&
            <ul className="publications-list-authors">
                {chapter.contributors.map((author, index) => <li key={index}>{buildContributorFullName(author.firstName, author.lastName)}</li>)}
            </ul>
        }
        </Link>
    </ListGroup.Item>
  );

  function publicationDetails() {
    return Object.assign({
      mainTitle: {
        text: chapter.title
      },
      scientificFields: book.scientificFields,
      bookInfo: {
        id: book.id,
        title: book.mainTitle.text,
        publishingCompanyInfo: book.publishingCompanyInfo
      },
      externalIdentifiers: book.externalIdentifiers
    }, chapter)
  }
}
