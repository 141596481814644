import React from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import AccessibleOverlayTrigger from '../../../../common/AccessibleOverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './SearchResultLicense.css';
import { getCurrentLanguageShortCode } from '../../../../common/LanguageUtils';

/**
 * Renders the license icon linked to license details page.
 *
 * @author sniewcza
 */
export default function SearchResultLicense({license}) {
  const { t, i18n } = useTranslation();

  const licenseName = getCurrentLanguageShortCode(i18n.languages) === 'pl' ? license.namePl : license.nameEn;
  const licenseImage = (
    <Image src={`${process.env.REACT_APP_PUBRO_API_URL}/images/license-icons/${license.iconId}`}
      alt={licenseName}
      className='license-image'
      loading='lazy'/>
  )

  const licenseUrl = getCurrentLanguageShortCode(i18n.languages) === 'pl' ? license.urlPl : license.urlEn;
  return (
    licenseUrl && licenseUrl.length > 0 ?
      <AccessibleOverlayTrigger placement='top' overlay={<Tooltip id='license-icon-tooltip'>{licenseName}</Tooltip>}>
        <a href={licenseUrl} target="_blank" rel="noopener noreferrer">
          {licenseImage}
          <span className="sr-only">{t('link.externalLink.label')}</span>
        </a>
      </AccessibleOverlayTrigger>
      :
      <AccessibleOverlayTrigger placement='top' overlay={<Tooltip id='license-icon-tooltip'>{licenseName}</Tooltip>}>
        {licenseImage}
      </AccessibleOverlayTrigger>
  );
}
