import React, { useLayoutEffect, useRef, useState } from 'react';

/**
 * Uses globally configured MathJax to present tex and asciimath formulas.
 *
 * See index.html for MathJax instance configuration.
 * Based on: https://gist.github.com/GiacoCorsiglia/1619828473f4b34d3d914a16fcbf10f3
 */
export default function ProcessMath({ children }) {
  const rootRef = useRef(null);
  const [isReady, setIsReady] = useState(window.__MathJax_State__.isReady);

  useLayoutEffect(() => {
    if (isReady) {
      window.MathJax.typeset([rootRef.current]);
    } else {
      window.__MathJax_State__.promise.then(() => setIsReady(true));
    }
  });

  return <span ref={rootRef}>{children}</span>;
}
