import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { convertFormValuesToUrlQuery } from './AdvancedSearchUtils';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

/**
 * Simple search field located on the journal details page. Allows
 * for searching within the currently viewed journal.
 *
 * Renders the search button with SVG magnifying glass icon (no need for
 * Font Awesome or other dependencies/resources). Icon taken from Bootstrap
 * icons: https://icons.getbootstrap.com/icons/search/
 *
 * Expects the following props:
 *  - `journalId` - identifier of the journal to search in
 *  - `history` - provided by router, allows for navigating to search results
 *
 * @author sniewcza
 */
function SearchInJournalForm({ journalId, history }) {
  const {t} = useTranslation();

  const onSubmit = (formValues, formik) => history.push('/search?' + convertFormValuesToUrlQuery(formValues, 1));
  const initialFormValues = { journals: [journalId], q: '' };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit} enableReinitialize={true}>
      {( formik ) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <InputGroup className='mb-3 search-form'>
            <FormControl type='text' {...formik.getFieldProps('q')}
                aria-label={t('search.form.in.journal.input.placeholder')}
                placeholder={t('search.form.in.journal.input.placeholder')}/>
            <InputGroup.Append>
              <Button type='submit' variant='primary' title={t('search.form.in.journal.button.title')}><FaSearch /></Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
}

export default withRouter(SearchInJournalForm);
