import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

/**
 * Creates a component for displaying e-mail only after
 * hovering over or focusing on the displayed placeholder.
 *
 * Component has following props:
 * - encoded – stores encoded e-mail address,
 * - renderAsLink – when set to true renders the mail as a link
 *   otherwise as a static text.
 * - textLabel – when set, a text label is displayed instead of
 *   placeholder and e-mail.
 * - className – CSS classes to be set on the rendered tag.
 */
export default function SafeMail(props) {
  const { t } = useTranslation();
  const label = props.textLabel;
  const classAttribute = props.className
    ? 'safe-mail ' + props.className
    : 'safe-mail';

  const placeholder = label
    ? label
    : t('safemail.placeholder.message');

  const [email, setEmail] = useState(placeholder);

  const emailLabel = label
    ? label
    : email;


  return (
    props.renderAsLink ?
      <a className={classAttribute}
         href={"mailto:" + email}
         onMouseEnter={() => setEmail(decode(props.encoded))}
         onFocus={() => setEmail(decode(props.encoded))}>
        {!props.children && emailLabel}
        {props.children}
      </a>
      :
      <div tabIndex="0" className={classAttribute}
        onMouseEnter={() => setEmail(decode(props.encoded))}
        onFocus={() => setEmail(decode(props.encoded))}>
            {email}
      </div>
    );
}

// -------------------- PRIVATE --------------------

/**
 * Decodes e-mail encoded by backend SimpleEmailEncoder#encode method.
 */
function decode(encoded) {
  if (encoded == null) {
    return '';
  }
  const length = encoded.length;
  const half = Math.floor(length / 2) + length % 2;
  let decoded = Array.apply(null, {length: length});
  for (let i = 0; i < length; i++) {
    let index = i < half ? i * 2 : (i - half) * 2 + 1;
    decoded[index] = encoded.charAt(i);
  }
  return decoded.join('');
}
