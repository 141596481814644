import React from "react";
import PubroSpinner from "../common/PubroSpinner";
import { withTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async'
import JournalListItems from './JournalListItems';
import Container from 'react-bootstrap/Container';
import { generateMetaDescription, generateSeparatedList } from "../common/meta";

/**
 * Renders a list of all journals assigned to given publishing company.
 * Id of the publishing company is determined from the 'publishingCompanyId'
 * path variable. The component contacts the backend to fetch journal list items
 * when mounted.
 */
class PublishingCompanyJournals extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      journals: null,
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.props.api.get(`/publishing-companies/${this.props.publishingCompanyId}/journals`)
      .then(response => this.setState({
        journals: [].concat(response.data),
        isLoading: false
      }));
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    return this.state.isLoading
      ? <>
        {this.props.active && <Helmet meta={[{name: "description", content: ""}]}/>}
        <PubroSpinner/>
      </>
      : <>
        {this.props.active && <Helmet meta={[{name: "description", content: this.getMetaDescription(t)}]}/>}
        <Container><JournalListItems journalListItems={this.state.journals}/></Container>
      </>;
  }

  // -------------------- PRIVATE --------------------

  getMetaDescription(t) {
    const defaultDescription = generateMetaDescription(t, {});

    if (!this.state.loading && this.state.journals) {
      let description = generateSeparatedList({"type": "journals", "data": this.state.journals})

      if (description.length === 0) {
        return defaultDescription;
      }
      else {
        return generateMetaDescription(t, {"text": description})
      }
    }
    else {
      return defaultDescription;
    }
  }
}

export default withTranslation()(PublishingCompanyJournals);
