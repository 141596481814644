import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import { withTranslation } from "react-i18next";
import ChapterListItem from "./ChapterListItem";

/**
 * Renders a list of chapters passed in component property.
 */
class ChapterList extends React.Component {

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    if (this.props.chapters.length === 0) {
      return <em>{t('chapter.list.empty')}</em>;
    }

    const chapterListElements = this.props.chapters.map(chapterListItem => (
      <ChapterListItem key={chapterListItem.id} chapter={chapterListItem} book={this.props.book} isBookPart={false} api={this.props.api} />
    ));

    return (
        <>
          <h2>{t('book.details.header.tableofcontents')}</h2>
          <Col>
            <ListGroup className="book-details-chapters">
              {chapterListElements}
            </ListGroup>
          </Col>
        </>
    );
  }

  publicationDetails(chapterListItem) {
    return Object.assign({
      mainTitle: {
        text: chapterListItem.title
      },
      scientificFields: this.props.book.scientificFields,
      bookInfo: {
        id: this.props.book.id,
        title: this.props.book.mainTitle.text,
        publishingCompanyInfo: this.props.book.publishingCompanyInfo
      },
      externalIdentifiers: this.props.book.externalIdentifiers
    }, chapterListItem)
  }
}

export default withTranslation()(ChapterList);
