import sanitizeHtml from 'sanitize-html';

export function cleanFormattedContent(html) {

  let cleaned = sanitizeHtml(html, {
    allowedTags: [ ],
    allowedAttributes: { },
    disallowedTagsMode: 'discard'
  });
  cleaned = cleaned
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&');
  return cleaned;
}

export function sanitizeFormattedContent(html) {
  return sanitizeHtml(html, {
    transformTags: {
      'crossref:b': 'b',
      'crossref:i': 'i',
      'crossref:sup': 'sup',
      'crossref:sub': 'sub',

      'w:title': 'h3',
      'w:p': 'p',
      'w:list': 'ul',
      'w:listitem': 'li',
      'w:sub': 'sub',
      'w:sup': 'sup',

      'ieee:p': 'p',
      'ieee:sub': 'sub',
      'ieee:sup': 'sup',
      'ieee:inf': 'sup',
      'ieee:emphasis': 'i',

      'esgml:p': 'p',
      'esgml:b': 'b',
      'esgml:it': 'i',
      'esgml:sup': 'sup',
      'esgml:inf': 'sub',

      'ce:abstract-sec': 'section',
      'ce:simple-para': 'p',
      'ce:section-title': 'h3',
      'ce:italic': 'i',
      'ce:bold': 'b',
      'ce:sup': 'sup',
      'ce:inf': "sub",

      'smjr:p': 'p',

      'sapp:abstractsection': 'section',
      'sapp:heading': 'h3',
      'sapp:para': 'p',
      'sapp:orderedlist': 'ol',
      'sapp:unorderedlist': 'ul',
      'sapp:itemcontent': 'li',
      'sapp:subscript': 'sub',
      'sapp:supscript': 'sup',
      'sapp:emphasis': function(tagName, attribs) {
        let finalTagName = tagName;
        if (attribs.type === 'Italic') {
          finalTagName = 'i';
        } else if (attribs.type === 'Bold') {
          finalTagName = 'b';
        }
        return {
          tagName: finalTagName,
          attribs: { }
        };
      },
      'sapp:equationsource': function(tagName, attribs) {
        if (attribs.format === 'TEX') {
          return {tagName: 'ignore', attribs: {}};
        }
        return {
          tagName: tagName,
          attribs: { }
        };
      },
      'sapp:blockquote': 'blockuote'
    },
    allowedTags: [
      'p', 'sub', 'sup', 'b', 'i', 'ol', 'ul', 'li', 'h3', 'section', 'blockquote',
      // MathML tags
      'math',
      'mi', 'mn', 'mo', 'ms', 'mspace', 'mtext',
      'menclose', 'merror', 'mfenced', 'mfrac', 'mpadded', 'mphantom', 'mroot', 'mrow', 'msqrt', 'mstyle',
      'mmultiscripts', 'mover', 'mprescripts', 'msub', 'msubsup', 'msup', 'munder', 'munderover',
      'mtable', 'mtd', 'mtr',
      'maction'
       ],
    allowedAttributes: {
      'math'          : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'display'],
      'mi'            : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'mn'            : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'mo'            : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'accent', 'fence', 'lspace', 'maxsize', 'minsize', 'movablelimits', 'rspace', 'separator', 'stretchy', 'symmetric'],
      'ms'            : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'lquote', 'rquote'],
      'mspace'        : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'height', 'width'],
      'mtext'         : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'menclose'      : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'notation'],
      'merror'        : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'], 
      'mfenced'       : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'close', 'open', 'separators'],
      'mfrac'         : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'denomalign', 'linethickness', 'numalign'],
      'mpadded'       : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'depth', 'height', 'lspace', 'voffset', 'width'],
      'mphantom'      : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'mroot'         : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'mrow'          : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'msqrt'         : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'mstyle'        : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'scriptminsize', 'scriptsizemultiplier'],
      'mmultiscripts' : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'subscriptshift', 'superscriptshift'],
      'mover'         : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'accent', 'accentunder'],
      'mprescripts'   : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'msub'          : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'subscriptshift', 'superscriptshift'],
      'msubsup'       : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'subscriptshift', 'superscriptshift'],
      'msup'          : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'munder'        : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel'],
      'munderover'    : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'accent', 'accentunder'],
      'mtable'        : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'align', 'columnalign', 'columnlines', 'columnspacing', 'frame', 'framespacing', 'rowalign', 'rowlines', 'rowspacing', 'width'],
      'mtd'           : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'columnalign', 'columnspan', 'rowalign', 'rowspan'],
      'mtr'           : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'columnalign', 'rowalign'],
      'maction'       : ['dir', 'displaystyle', 'mathvariant', 'scriptlevel', 'actiontype', 'selection']
    },
    disallowedTagsMode: 'discard',
    nonTextTags: [ 'style', 'script', 'textarea', 'option', 'ignore', 'sapp:itemnumber' ]
  });
}