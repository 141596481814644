import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from "react-bootstrap/Dropdown"
import {LinkContainer} from 'react-router-bootstrap';
import {useTranslation} from "react-i18next";
import SkipToContentLinks from './SkipToContentLinks';
import LanguageSwitcher from './LanguageSwitcher';
import i18n from "../i18n/i18n";
import {getCurrentLanguageShortCode} from "../common/LanguageUtils";
import MainLogo from './MainLogo';

/**
 * Renders a navigation bar at the top of the page.
 */
export default function Header(props) {
  const {t} = useTranslation();
  let currentLanguage = getCurrentLanguageShortCode(i18n.languages);

  return (
    <>
    <SkipToContentLinks />
    <Navbar bg="light" variant="light" expand="lg" className="border-bottom py-3">
      <div className="container row justify-content-between align-items-center">
        <LinkContainer to="/">
          <Navbar.Brand>
            <MainLogo classes="d-inline-block align-top" emptyAlt={true} />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="col-auto">
          <Nav className="mr-auto btn-group">
            <div className="header-link-container">
              <LinkContainer to="/publishing-companies" className="btn mr-3">
                <Nav.Link>{t('navbar.menuitem.resources')}</Nav.Link>
              </LinkContainer>
            </div>
            <div className="header-link-container">
              <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/about/`} className="btn mr-3 nav-link">
                {t('navbar.menuitem.aboutproject')}
                <span className="sr-only">{t('link.externalLink.label')}</span>
              </a>
            </div>
            <div className="header-link-container">
              <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/help/`} className="btn mr-3 nav-link">
                {t('navbar.menuitem.help')}
                <span className="sr-only">{t('link.externalLink.label')}</span>
              </a>
            </div>
            <div className="header-link-container">
              <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/contact/`} className="btn mr-3 nav-link">
                {t('navbar.menuitem.contact')}
                <span className="sr-only">{t('link.externalLink.label')}</span>
              </a>
            </div>
            <Dropdown className="btn header-dropdown-btn">
              <Dropdown.Toggle variant="primary" size="sm" className="nav-link">
                {t('navbar.menuitem.forpublishers')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/join-publishers/`} variant="link" className="nav-link">
                  {t('navbar.menuitem.joinpublishers')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </Dropdown.Item>
                <Dropdown.Item target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/faq/`} variant="link" className="nav-link">
                  {t('navbar.menuitem.faq')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </Dropdown.Item>
                <Dropdown.Item target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PUEDIT_URL}`} variant="link" className="nav-link">
                  {t('navbar.menuitem.login')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <LanguageSwitcher/>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
    </>
  );
}
