import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { parse } from 'query-string';

const issnTypes = ['ISSN', 'EISSN', 'SERIES_ISSN', 'SERIES_EISSN'];

/**
 * Formats external identifiers. For ISSN and e-ISSN values adds a dash in the
 * middle. For other identifier types returns the value unchanged.
 */
export function formatExternalIdentifier(extId) {
  return issnTypes.includes(extId.type) ? formatIssn(extId.text) : extId.text;
}

/**
 * Formats ISSN values. For strings of length 8 inserts a dash in the middle.
 * Values of length other than 8 are returned unchanged.
 */
export function formatIssn(value) {
  if (!value || value.length !== 8) {
    return value;
  }
  const groups = value.match(/^(.{4})(.{4})$/);
  return `${groups[1]}-${groups[2]}`;
}

/**
 * Formats date value. Skips unprovided parts.
 */
export function formatDate(ymdDate) {
  function padZeros(number) {
    return (number < 10 ? "0"+number : number)
  }

  const parts = [];
  parts.push(ymdDate.year);
  if (ymdDate.month) {
    parts.push(padZeros(ymdDate.month))
  }
  if (ymdDate.day) {
    parts.push(padZeros(ymdDate.day))
  }
  return parts.join('-');
}

/**
 * Assembles journal issue title from year, volume and number.
 */
export function formatIssueLabel(issue) {
  return formatIssueTitle(issue.year, issue.volume, issue.number);
}

/**
 * Assembles journal issue title from year, volume and number.
 */
export function formatIssueTitle(year, volume, number) {
  let title = year;
  if (volume) {
    title = `${title}/${volume}`
  }
  if (number) {
    title = `${title}/${number}`
  }
  return title;
}

/**
 * Builds fullName from first and last name 
 */
export function buildContributorFullName(firstName, lastName) {
  if (firstName) {
    return `${firstName} ${lastName}`
  }
  return lastName;
}

/**
 * Extract pageStart and pageEnd from pageRange if possible.
 * Otherwise function assumes that pageRange is described
 * in more complex way and returns unchanged pageRange in
 * pagination field.
 * Example:
 * extractPageRangesOrPagination('12-15') = {pageStart: '12', pageEnd: '15', pagination: undefined}
 * extractPageRangesOrPagination('I-IX,1-15') = {pageStart: undefined, pageEnd: undefined, pagination: 'I-IX,1-15'}
 */
export function extractPageRangesOrPagination(pageRange) {

  let pageStart = undefined;
  let pageEnd = undefined;
  let pagination = undefined;

  const parts = pageRange.split('-');
  if (parts.length === 2) {
    pageStart = parts[0];
    pageEnd = parts[1];
  } else {
    pagination = pageRange;
  }

  return {
    pageStart: pageStart,
    pageEnd: pageEnd,
    pagination: pagination
  };
}

export function extractPageRange(pageRange) {
  return pageRange.match(/^\d+$/)
    ? { pageStart: pageRange, pageEnd: pageRange }
    : extractPageRangesOrPagination(pageRange);
}

/**
 * Returns full url to pubro api.
 * If 'process.env.REACT_APP_PUBRO_API_URL_RELATIVE' is
 * set to true then function assumes that url to api
 * is exposed on the same address as react app and
 * 'process.env.REACT_APP_PUBRO_API_URL' is just the suffix
 * for the full url.
 */
export function getAbsoluteApiUrl() {
  if (process.env.REACT_APP_PUBRO_API_URL_RELATIVE.toLowerCase() === 'true') {
    return window.location.origin + process.env.REACT_APP_PUBRO_API_URL;
  }
  return process.env.REACT_APP_PUBRO_API_URL;
}

/**
 * Extracts the currently viewed tab key from page URL.
 */
export function extractTabKey(location, availableTabs, defaultTab) {
  location = location.replace(/\/$/, "");
  for (const availableTab of availableTabs) {
    if (availableTab && location.endsWith(availableTab)) {
      return availableTab;
    }
  }
  return defaultTab;
}

/**
 * Extracts the base URL (omitting currently viewed tab) from given link.
 */
export function extractLocationWithoutTabKey(location, availableTabs) {
  location = location.replace(/\/$/, "");
  for (const availableTab of availableTabs) {
    if (availableTab && location.endsWith(availableTab)) {
      return location.substring(0, location.length - availableTab.length);
    }
  }
  return location;
}

/**
 * Abbreviates given string value at `limit` characters and appends '...'
 * at the end.
 */
export function abbreviate(value, limit) {
  if (value && limit > 0) {
    return value.length > limit
      ? value.substring(0, limit) + '...'
      : value;
  }
  return value;
}

/**
 * Returns given string with replaced some characters to non breakable
 * versions:
 *  - replace dash to non-breakable dash
 *  - replace space with non-breakable space if word before space is 'i' or 'o'
 */
export function insertNonBreakableCharacters(value) {
  return value
    .split('-').join('\u2011')
    .split(' i ').join(' i\u00A0')
    .split(' o ').join(' o\u00A0');
}

/**
 * Converts delta json string to html string
 */
export function convertDeltaStringToHtml(deltaString) {
  const descDelta = JSON.parse(deltaString);
  const converter = new QuillDeltaToHtmlConverter(descDelta["ops"]);

  return converter.convert();
}

/**
 * Returns true if given array's length is zero, or the array contains nulls
 * only.
 */
export function isEmptyOrContainsOnlyNulls(array) {
  return array.length === 0
    || array.filter(value => value !== null).length === 0
}

/**
 * Returns undefined if the given array is empty.
 * Otherwise it returns given array.
 */
export function emptyArrayToUndefined(array) {
  return (array.length > 0) ? array: undefined;
}

export function groupBy(array, extractKeyFunction) {
  return array.reduce((accumulator, current)  => {
    const key = extractKeyFunction(current);

    accumulator[key] = accumulator[key] || [];
    accumulator[key].push(current);

    return accumulator;
  }, {});
}

/**
 * Translated aria live messages for react-select component instances.
 * Based on default messages defined at https://github.com/JedWatson/react-select/blob/react-select%404.3.1/packages/react-select/src/accessibility/index.js
 */
export function getSelectAriaLiveMessages(t) {
  return {
    guidance: props => {
      const { isSearchable, isMulti, isDisabled, tabSelectsValue, context } = props;
      switch (context) {
        case 'menu':
          return `${t('react-select.aria.live.guidance.menu.choose')}${
            isDisabled
              ? ''
              : ', ' + t('react-select.aria.live.guidance.menu.select')
          }, ${t('react-select.aria.live.guidance.menu.exit')}${
            tabSelectsValue
              ? ', ' + t('react-select.aria.live.guidance.menu.select-and-exit')
              : ''
          }.`;
        case 'input':
          return `${props['aria-label'] || 'Select'} ${t('react-select.aria.live.guidance.input.is-focused')} ${
            isSearchable ? ', ' + t('react-select.aria.live.guidance.input.refine') : ''
          }, ${t('react-select.aria.live.guidance.input.open-menu')}, ${
            isMulti ? ' ' + t('react-select.aria.live.guidance.input.focus-selected') : ''
          }`;
        case 'value':
          return t('react-select.aria.live.guidance.value');
        default:
          return '';
      }
    },

    onChange: props => {
      const { action, label = '', labels, isDisabled } = props;
      switch (action) {
        case 'deselect-option':
        case 'pop-value':
        case 'remove-value':
          return `${t('react-select.aria.live.option')} ${label}, ${t('react-select.aria.live.on-change.deselected')}.`;
        case 'clear':
          return t('react-select.aria.live.on-change.clear');
        case 'initial-input-focus':
          return `${t('react-select.aria.live.option' + (labels.length > 1 ? 's' : ''))} ${labels.join(
            ','
          )}, ${t('react-select.aria.live.on-change.initial-input-focus.selected' + (labels.length > 1 ? '.plural' : ''))}.`;
        case 'select-option':
          return isDisabled
            ? `${t('react-select.aria.live.option')} ${label} ${t('react-select.aria.live.on-change.select-option.disabled')}`
            : `${t('react-select.aria.live.option')} ${label}, ${t('react-select.aria.live.on-change.select-option.selected')}`;
        default:
          return '';
      }
    },

    onFocus: props => {
      const {context, focused, options, label = '', selectValue, isDisabled, isSelected} = props;
      const getArrayIndex = (arr, item) => arr && arr.length
        ? `${arr.indexOf(item) + 1} ${t('react-select.aria.live.on-focus.of')} ${arr.length}`
        : '';

      if (context === 'value' && selectValue) {
        const value = t('react-select.aria.live.on-focus.value.value');
        const focused = t('react-select.aria.live.on-focus.value.focused');
        return `${value} ${label} ${focused}, ${getArrayIndex(selectValue, focused)}.`;
      }

      if (context === 'menu') {
        const disabled = isDisabled ? ' ' + t('react-select.aria.live.on-focus.menu.disabled') : '';
        const status = `${t('react-select.aria.live.on-focus.menu.' + (isSelected ? 'selected' : 'focused'))}${disabled}`;
        return `${t('react-select.aria.live.option')} ${label} ${status}, ${getArrayIndex(options, focused)}.`;
      }
      return '';
    },

    onFilter: props => {
      const { inputValue, resultsMessage } = props;
      return `${resultsMessage}${inputValue ? ' ' + t('react-select.aria.live.on-filter') + ' ' + inputValue : ''}.`;
    },
  };
}

/**
 * Checks whether the current page is in draft preview mode.
 */
export function isInPreviewMode(component) {
  return !!parse(component.props.location.search).previewToken;
}

/**
 * Replaces occurences of $ with \$ in the given text,
 * but only if $ is not inside delimiter pairs [\(, \)], [\[, \]].
 * It also do not transform double dollars ($$) - alternative opening
 * and close of tex formula.
 * Sometimes tex formulas can contain $ sign. Such cases are misleading
 * for MathJax. It looks like asciimath renderer is interfering with
 * the way how tex formula is displayed.
 * This is workaround to change asciimath delimiter to be more specific,
 * so it wont clash with tex.
 * Some example of incorrectly rendering tex without this fix:
 * \[ \begin{aligned} 1/2{\text{H}}_{2} ({\text{g}}) &= \underset{\raise0.3em\hbox{$\smash{\scriptscriptstyle-}$}}{{\text{H} ,}} \hfill \\ \underset{\raise0.3em\hbox{$\smash{\scriptscriptstyle-}$}}{\text{B}} + \underset{\raise0.3em\hbox{$\smash{\scriptscriptstyle-}$}}{\text{H}} + {\text{H}}_{2} {\text{O(g)}} &= {\text{HBO(g)}} + {\text{H}}_{2} . \hfill \\ \end{aligned} \]
 */
export function transformAsciiMathDelimiter(text) {

  let transformed = text;
  let currentIndex = transformed.indexOf('$', 0);
  
  while (currentIndex !== -1) {
    let transformedToCurrentIndex = transformed.substring(0, currentIndex);
    
    if (transformed[currentIndex-1] === '$') {
      currentIndex = transformed.indexOf('$', currentIndex+1);
    } else if (transformed[currentIndex+1] === '$') {
      currentIndex = transformed.indexOf('$', currentIndex+1);
    } else if (transformedToCurrentIndex.lastIndexOf('\\[') > transformedToCurrentIndex.lastIndexOf('\\]')) {
      currentIndex = transformed.indexOf('$', currentIndex+1);
    } else if (transformedToCurrentIndex.lastIndexOf('\\(') > transformedToCurrentIndex.lastIndexOf('\\)')) {
      currentIndex = transformed.indexOf('$', currentIndex+1);
    } else if (transformedToCurrentIndex[currentIndex-1] === '\\') {
      currentIndex = transformed.indexOf('$', currentIndex+1);
    } else {
      transformed = transformed.substring(0, currentIndex) + '!$!' + transformed.substring(currentIndex+1)
      currentIndex = transformed.indexOf('$', currentIndex+3);
    }
  }

  return transformed
}

/**
 * Decodes Base64 string while keeping proper character encoding.
 */
export function decodeBase64(string) {
  try {
    return decodeURIComponent(escape(atob(string)));
  }
  catch (e) {
    return "(invalid Base64 string)";
  }
}

/**
 * Checks whether the license is "All rights reserved".
 */
export function isAllRightsReserved(license) {
  return license.id === 13;
}
