import {useTranslation} from 'react-i18next';
import React from 'react';
import {formatExternalIdentifier} from '../../common/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ExternalIdentifierList.css';
import { canCreatUrlExternalId, createUrlExternalId } from './ExternalIdentifierUtil';
import { FaExternalLinkAlt } from 'react-icons/fa';

/**
 * Renders a simple list of external identifiers. Expects the data to be passed
 * as 'externalIdentifiers' prop. Also expects a translation label prefix, 
 * passed as 'labelPrefix' prop.
 */
export default function ExternalIdentifierList({ externalIdentifiers, labelPrefix }) {
  const { t } = useTranslation();

  const filteredExternalIdentifiers = externalIdentifiers ? externalIdentifiers.filter(extId => extId.type !== 'EISBN') : [];

  if (filteredExternalIdentifiers.length > 0) {
    return (
      <Row>
        <Col>
          {filteredExternalIdentifiers.map((extId, index) => 
            <p className="external-id" key={extId.type + index}>
              {t(labelPrefix + extId.type.toLowerCase()) + ': '}
              { canCreatUrlExternalId(extId) ? 
                <a href={createUrlExternalId(extId)} target="_blank" rel="noopener noreferrer">
                  <strong>{formatExternalIdentifier(extId)}</strong>
                  <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                </a>
                :
                <strong>{formatExternalIdentifier(extId)}</strong>
              }
            </p>)}
        </Col>
      </Row>
    );
  }
}
