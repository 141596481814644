import React from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/**
 * Renders a generic frame for search result list items. Takes the following
 * props:
 *  - `mainBody` - main content of the frame: title, contributors, abstract, etc.
 *  - `sidebar` - content of the narrow right column: cover, parent entity title, etc.
 *  - `accessRestricted` - true if document is only accessible for organizations, provided here for visual differentiation
 *
 * @author sniewcza
 */
export default function SearchResultFrame({mainBody, sidebar, accessRestricted}) {
  return (
    <Card className={'search-result-list-item' + (accessRestricted ? ' access-restricted' : '')}>
      <Card.Body>
        <Container>
          <Row>
            <Col md={9} sm={12}>{mainBody}</Col>
            <Col md={3} sm={12} className='search-result-sidebar text-right'>{sidebar}</Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
