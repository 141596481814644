import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { withTranslation } from 'react-i18next';
import { abbreviate, transformAsciiMathDelimiter } from '../common/utils';
import PubroSpinner from '../common/PubroSpinner';
import ProcessMath from '../common/ProcessMath';
import WbnAccessIcon from '../common/WbnAccessIcon';
import {cleanFormattedContent} from '../publication/common/FormattedContentUtil';

class Showcase extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      books: null,
      articles: null,
      journals: null,
      isLoadingBooks: true,
      isLoadingArticles: true,
      isLoadingJournals: true,
      bookError: false,
      articleError: false,
      journalError: false
    };
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedScientificFieldId !== this.props.selectedScientificFieldId) {
      this.setState({
        books: null,
        articles: null,
        journals: null,
        isLoadingBooks: true,
        isLoadingArticles: true,
        isLoadingJournals: true,
        bookError: false,
        articleError: false,
        journalError: false
      }, this.updateData);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    return (
      <Row>
        <Col md={3}>
          <h2>{t(this.props.headerKey)}</h2>
        </Col>

        <Col md={9} className="card-container">
          <Container>
            <Row>
              <Col lg={6} className="margin-top-lt-lg">
                <Row className="main-page-section-column-header">
                  <Col xs={12} sm={5}>
                    <h3>
                      {t('welcome.books.header')}
                    </h3>
                  </Col>

                  {this.props.showAllButton && 
                  <Col xs={12} sm={7} className="main-page-section-show-all">
                     <LinkContainer to="/search?scope=books">
                       <Button variant="link" aria-label={t('welcome.books.show-all.ariaLabel')}>{t('welcome.publications.show-all')}</Button>
                     </LinkContainer>
                   </Col>
                   }
                 
                </Row>

                {this.bookDataSegment()}
              </Col>

              <Col lg={6} className="margin-top-lt-lg">
                <Row className="main-page-section-column-header">
                  <Col xs={12} sm={5}>
                    <h3>
                      {t('welcome.articles.header')}
                    </h3>
                  </Col>

                  {this.props.showAllButton && 
                  <Col xs={12} sm={7} className="main-page-section-show-all">
                    <LinkContainer to="/search?scope=journals">
                      <Button variant="link" aria-label={t('welcome.articles.show-all.ariaLabel')}>{t('welcome.publications.show-all')}</Button>
                    </LinkContainer>
                  </Col>
                  }
                  
                </Row>

                {this.articleDataSegment()}
              </Col>

              <Col lg={12} className="main-page-section-fullwidth">
                <div className="main-page-section-column-header">
                  <h3>
                    {t('welcome.journals.header')}
                  </h3>
                </div>

                <Row>
                  {this.journalDataSegment()}
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    );
  }

  // -------------------- PRIVATE --------------------

  bookDataSegment() {
    if (this.state.bookError) {
      return this.loadingFailedMessage('welcome.books.loadingFailed');
    }

    if (!this.state.isLoadingBooks && this.state.books.length === 0) {
      return this.noResultsMessage('welcome.books.emptyScientificField', 'welcome.books.empty');
    }

    return this.state.isLoadingBooks
      ? <PubroSpinner/>
      : this.state.books.map((book, index) => this.bookLink(book, 'book-' + index));
  }

  bookLink(book, key) {
    return (
      <Link to={`/books/${book.id}`} key={this.props.keyPrefix + key} className="card-block-link" data-book-link>
        <Card className={book.restricted ? "wbn-access-restricted" : ""}>
          <Card.Body>
            <h4><ProcessMath>{abbreviate(transformAsciiMathDelimiter(cleanFormattedContent(book.title)), 70)}</ProcessMath></h4>
            {book.contributors && !!book.contributors.length && 
              <p className="card-contributors">
                {book.contributors.map((contributor) => [contributor.firstName, contributor.lastName].join(' ')).join(', ')}
              </p>
            }
            {book.restricted && <WbnAccessIcon type={book.collectiveWork ? 'COLLECTIVE_WORK' : 'SIMPLE_BOOK'}/>}
          </Card.Body>
        </Card>
      </Link>
    );
  }

  articleDataSegment() {
    if (this.state.articleError) {
      return this.loadingFailedMessage('welcome.articles.loadingFailed');
    }

    if (!this.state.isLoadingArticles && this.state.articles.length === 0) {
      return this.noResultsMessage('welcome.articles.emptyScientificField', 'welcome.articles.empty');
    }

    return this.state.isLoadingArticles
      ? <PubroSpinner/>
      : this.state.articles.map((article, index) => this.articleLink(article, 'article-' + index));
  }

  articleLink(article, key) {
    return (
      <Link to={`/articles/${article.id}`} key={this.props.keyPrefix + key} className="card-block-link" data-article-link>
        <Card className={article.restricted ? "wbn-access-restricted" : ""}>
          <Card.Body>
            <h4><ProcessMath>{abbreviate(transformAsciiMathDelimiter(cleanFormattedContent(article.title)), 70)}</ProcessMath></h4>
            {article.contributors && !!article.contributors.length && 
              <p className="card-contributors">
                {article.contributors.map((contributor) => [contributor.firstName, contributor.lastName].join(' ')).join(', ')}
              </p>
            }
            {article.restricted && <WbnAccessIcon type="ARTICLE"/>}
          </Card.Body>
        </Card>
      </Link>
    );
  }

  journalDataSegment() {
    if (this.state.journalError) {
      return this.loadingFailedMessage('welcome.journals.loadingFailed');
    }

    if (!this.state.isLoadingJournals && this.state.journals.length === 0) {
      return (
        <Col>
          {this.noResultsMessage('welcome.journals.emptyScientificField', 'welcome.journals.empty')}
        </Col>
      );
    }

    return this.state.isLoadingJournals
      ? <PubroSpinner/>
      : this.state.journals.map((journal, index) => this.journalLink(journal, 'journal-' + index));
  }

  journalLink(journal, key) {
    return (
      <Col lg={6} key={this.props.keyPrefix + key}>
        <Link to={`/journals/${journal.id}`} className="card-block-link" data-journal-link>
          <Card className={journal.restricted ? "wbn-access-restricted" : ""}>
            <Card.Body>
              <h4>{abbreviate(journal.mainTitle, 70)}</h4>
              {journal.restricted && <WbnAccessIcon type="JOURNAL"/>}
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );
  }

  loadingFailedMessage(messageKey) {
    return (
      <div className="loading-failed-info">
        <em>{this.props.t(messageKey)}</em>
      </div>
    );
  }

  noResultsMessage(scientificFieldSelectedKey, noScientificFieldKey) {
    return (
      <div className="no-elements-info">
        <em>{this.isAnyScientificFieldSelected() ? this.props.t(scientificFieldSelectedKey) : this.props.t(noScientificFieldKey)}</em>
      </div>
    );
  }

  isAnyScientificFieldSelected() {
    return this.props.selectedScientificFieldId !== undefined;
  }

  updateData() {
    const scientificFieldParam = {field: this.props.selectedScientificFieldId};

    this.request(this.props.urls.books, 'books', 'isLoadingBooks', 'bookError', scientificFieldParam);
    this.request(this.props.urls.articles, 'articles', 'isLoadingArticles', 'articleError', scientificFieldParam);
    this.request(this.props.urls.journals, 'journals', 'isLoadingJournals', 'journalError', scientificFieldParam);
  }

  request(url, dataKey, loadingKey, errorKey, scientificFieldParam) {
    this.props.api.unintercepted.get(url, {params: scientificFieldParam})
      .then(fulfilled => this.setState({[dataKey]: fulfilled.data, [loadingKey]: false, [errorKey]: false}),
            rejected => this.setState({[dataKey]: null, [loadingKey]: false, [errorKey]: true}))
      .catch(error => this.setState({[dataKey]: null, [loadingKey]: false, [errorKey]: true}));
  }
}

export default withTranslation()(Showcase);
