import React from 'react';
import {useTranslation} from 'react-i18next';
import SearchResultTitle from '../common/SearchResultTitle';
import SearchResultContributorList from '../common/SearchResultContributorList';
import SearchResultAbstract from '../common/SearchResultAbstract';

/**
 * Renders main parts of a publication that do not have a fulltext search result.
 *
 * Displays the following data:
 *  - title
 *  - horizontal list of contributors
 *  - summary
 *
 *  All these parts can have highlighting data associated with them.
 *  Highlighting snippets will be rendered in place of original data, whenever
 *  they are present.
 *
 *  Expects the document to be passed in as `document` prop.
 *
 *  @author sniewcza
 */
export default function SearchResultNoFulltextBody({document, headerKey, urlQueryValues}) {
  const {t} = useTranslation();
  return (
    <>
      <div className="publication-kind">{t(headerKey)}</div>
      <SearchResultTitle title={document.mainTitle}
                         titleSnippets={document.mainTitleSnippets}
                         publicationId={document.publicationId}
                         publicationType={document.publicationType}/>
      {document.contributors.length > 0 && <SearchResultContributorList contributorNames={document.contributors}
                                                                        contributorSnippets={document.contributorSnippets}
                                                                        urlQueryValues={urlQueryValues}/>}
      <h3>{t('search.result.list.item.summary.header')}</h3>
      <SearchResultAbstract abstract={document.mainAbstract} abstractSnippets={document.mainAbstractSnippets} />
    </>
  );
}
