import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoLibrarySharp } from 'react-icons/io5';
import AccessibleOverlayTrigger from './AccessibleOverlayTrigger';
import { useContext } from 'react';
import { WbnOrganizationNameContext } from './WbnOrganizationNameProvider';
import { getCurrentLanguageShortCode } from './LanguageUtils';

/**
 * Renders the WBN Access icon with appropriate tooltip.
 */
export default function WbnAccessIcon({ type }) {
  const { t, i18n } = useTranslation();
  const { namePl, setNamePl, nameEn, setNameEn } = useContext(WbnOrganizationNameContext);

  let currentLanguage = getCurrentLanguageShortCode(i18n.languages);

  const organizationNames = {
    pl: namePl,
    en: nameEn
  }

  type = type ? type : 'UNKNOWN';

  return (
    <AccessibleOverlayTrigger placement='top' overlay={
      <Tooltip id='license-icon-tooltip'>
        {t(`wbnAccessIcon.tooltip.${type}`) + " " + t("wbnAccessIcon.tooltip") + organizationNames[currentLanguage]} 
      </Tooltip>
    }>
      <span className="wbn-icon-outer-container">
        <span className="wbn-icon-container">
          <IoLibrarySharp />
        </span>
      </span>
    </AccessibleOverlayTrigger>
  );
}
