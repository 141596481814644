import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import { withTranslation } from "react-i18next";
import ChapterListItem from '../chapter/ChapterListItem';

/**
 * Renders a list of chapters passed in component property.
 */
class BookPartList extends React.Component {

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    if (this.props.book.bookParts.length === 0) {
      return <em>{t('bookparts.list.empty')}</em>;
    }

    let bookPartList;

    if (this.props.chapters.filter(chapter => chapter.bookPartUuid === null).length > 0) {
      bookPartList = [{
        title: null,
        uuid: null
      }].concat(this.props.book.bookParts);
    }
    else {
      bookPartList = this.props.book.bookParts;
    }

    const bookPartListElements = bookPartList.map(bookPartListItem => (
      <ListGroup.Item key={bookPartListItem.id + 'partList'}>
        {bookPartListItem.title && <h3>{bookPartListItem.title}</h3>}
        <ListGroup key={bookPartListItem.id + 'partListItem'}>
          {this.props.chapters.filter(chapter => chapter.bookPartUuid === bookPartListItem.uuid).map(chapterListItem => (
            <ChapterListItem key={chapterListItem.id} chapter={chapterListItem} book={this.props.book} isBookPart={true} api={this.props.api} />
          ))}
          {this.props.chapters.filter(chapter => chapter.bookPartUuid === bookPartListItem.uuid).length === 0 && <em>-</em>}
        </ListGroup>
      </ListGroup.Item>
    ));

    return (
        <>
          <h2>{t('book.details.header.tableofcontents')}</h2>
          <Col>
            <ListGroup className="book-details-bookparts">
              {bookPartListElements}
            </ListGroup>
          </Col>
        </>
    );
  }
}

export default withTranslation()(BookPartList);
