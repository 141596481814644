import React from 'react';
import SearchResultFrame from '../common/SearchResultFrame';
import SimpleBookSearchResultSidebar from './SimpleBookSearchResultSidebar';
import SearchResultCommonBody from '../common/SearchResultCommonBody';
import SearchResultNoFulltextBody from '../common/SearchResultNoFulltextBody';

/**
 * Search result list item for simple books. Renders a common search result
 * frame with simple book-specific body and sidebar.
 *
 * @author sniewcza
 */
export default function SimpleBookSearchResultListItem({document, urlQueryValues, api}) {
  
  const hasFulltext = document.fullTextFormats.length > 0;

  let mainBody;

  if (hasFulltext) {
    mainBody = <SearchResultCommonBody headerKey={'search.result.list.item.frame.simplebook.header'}
                                           abstractHeaderKey={'search.result.list.item.summary.header'}
                                           document={document} urlQueryValues={urlQueryValues} api={api}
                                           accessRestricted={document.accessRestricted}/>;
  } else {
    mainBody = <SearchResultNoFulltextBody headerKey={'search.result.list.item.frame.simplebook.header'}
                                           document={document} urlQueryValues={urlQueryValues}/>;
  }

  const sidebar = <SimpleBookSearchResultSidebar document={document} urlQueryValues={urlQueryValues}/>;
  return (
    <SearchResultFrame mainBody={mainBody}
                       sidebar={sidebar}/>
  );
}
