import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';

/**
 * Renders a simple list of institutions. Expects the data to be passed as
 * 'publicationInstitutions' prop.
 */
export default function InstitutionList({ publicationInstitutions }) {
  const {t} = useTranslation();
  if (publicationInstitutions && publicationInstitutions.length > 0) {
    return (
      <Row>
        <Col>
          <h2>{t('publication.details.affiliations.header')}</h2>
          <ol>
            {publicationInstitutions.map((inst, index) => <li key={index}>{inst.publicationInstitutionName}</li>)}
          </ol>
        </Col>
      </Row>
    );
  }
}
