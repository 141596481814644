import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Tooltip from 'react-bootstrap/Tooltip';
import { Helmet } from 'react-helmet-async'
import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa';
import SimpleSearch from '../search/form/SimpleSearch';
import JournalDatabaseList from '../journal/JournalDatabaseList';
import { MAIN_CONTENT_ID } from './SkipToContentLinks';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import StatCounters from './StatCounters';
import RestrictedStatCounters from './RestrictedStatCounters';
import ScientificFieldLinks from './ScientificFieldLinks';
import RandomPublishingCompanies from './RandomPublishingCompanies';
import AccessibleOverlayTrigger from '../common/AccessibleOverlayTrigger';
import Showcase from './Showcase';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import { generateMetaTitle, generateMetaDescription } from "../common/meta";

/**
 * Renders the front page of the application.
 */
class Welcome extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      selectedScientificFieldId: this.extractScientificFieldId(this.props.location.search),
      urlsLatest: {
        books: '/books/latest',
        articles: '/articles/latest',
        journals: '/journals/latest'
      },
      urlsPopular: {
        books: '/statistics/popular/books',
        articles: '/statistics/popular/articles',
        journals: '/statistics/popular/journals'
      },
      video: {
        paused: localStorage.getItem('welcomeVideoPaused') === 'true'
      },
      restrictedStatistics: null
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.props.api.get(`/statistics/homepage/restricted`).then(response => {
      this.setState({
        restrictedStatistics: response.data
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        selectedScientificFieldId: this.extractScientificFieldId(this.props.location.search),
      });
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    const containerClass = this.shouldShowRestrictedStats() ? "wbn-restricted" : "";

    const playPause = () => {
        if (this.state.video.paused) {
            this.videoRef.current.play();
            this.setState({video: {paused: false}});
            localStorage.setItem('welcomeVideoPaused', 'false');
        } else {
            this.videoRef.current.pause();
            this.setState({video: {paused: true}});
            localStorage.setItem('welcomeVideoPaused', 'true');
        }
    };

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: t('welcome.title') });

    return (
      <main id={MAIN_CONTENT_ID}>
        <Helmet title={ generateMetaTitle(t, {'label' : 'meta.home.title'}) }
                meta={[{name: 'description', content: generateMetaDescription(t, {'label' : 'meta.home.description', 'noLimit': true}) }]}/>
        <Jumbotron>
          <video playsInline={true} autoPlay={!this.state.video.paused} loop={true} defaultmuted="true" className="jumbotron-video"
               tabIndex="-1" ref={this.videoRef}>
            <source src="/headerBg.mp4" type="video/mp4"></source>
          </video>
          <Container className={containerClass}>
            <Row>
              <Col className='simple-search-container'>
                <Row className="justify-content-between align-items-center">
                  <Col xs="auto" className="simple-search-header">
                    <SimpleSearch initialValues={{}}/>
                  </Col>
                  <Col xl className="front-page-stats">
                    <StatCounters api={this.props.api}/>
                  </Col>
                  { this.shouldShowRestrictedStats() && <Col xl className="front-page-stats">
                    <RestrictedStatCounters stats={this.state.restrictedStatistics}/>
                  </Col> }
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="video-controls">
            <AccessibleOverlayTrigger overlay={
                <Tooltip className='in-dark-background-tooltip'>
                  {this.state.video.paused ? t('welcome.search.backgroundVideo.play') : t('welcome.search.backgroundVideo.pause')}
                </Tooltip>}>
              <Button variant="secondary" onClick={playPause}>
                {this.state.video.paused ?
                  <FaPlayCircle aria-label={t('welcome.search.backgroundVideo.play')} />
                  : <FaPauseCircle aria-label={t('welcome.search.backgroundVideo.pause')} />}
              </Button>
            </AccessibleOverlayTrigger>
          </div>
        </Jumbotron>

        <div className="main-page-categories">
          <Container>
            <ScientificFieldLinks selectedScientificFieldId={this.state.selectedScientificFieldId} api={this.props.api}/>
          </Container>
        </div>

        <hr></hr>

        <Container className="main-page-section">
          <Showcase urls={this.state.urlsLatest}
                    selectedScientificFieldId={this.state.selectedScientificFieldId}
                    headerKey={'welcome.newest.header'}
                    keyPrefix={'latest-'}
                    showAllButton={true}
                    api={this.props.api}/>
        </Container>

        <hr></hr>

        <Container className="main-page-section">
          <Showcase urls={this.state.urlsPopular}
                    selectedScientificFieldId={this.state.selectedScientificFieldId}
                    headerKey={'welcome.popular.header'}
                    keyPrefix={'popular-'}
                    showAllButton={false}
                    api={this.props.api}/>
        </Container>

        <hr></hr>

        <Container className="main-page-section">
          <Row>
            <Col md={3}>
              <h2 className="margin">{t('welcome.databases.header')}</h2>
            </Col>
            <Col md={9} className="card-container">
              <JournalDatabaseList api={this.props.api}/>
            </Col>
          </Row>
        </Container>

        <div className="main-page-publishers">
          <Container>
            <RandomPublishingCompanies api={this.props.api}/>
          </Container>
        </div>

      </main>
    );
  }

  // -------------------- PRIVATE --------------------

  extractScientificFieldId(urlQuery) {
    const values = queryString.parse(urlQuery);
    return values.scientificField ? decodeURIComponent(values.scientificField) : undefined;
  }

  shouldShowRestrictedStats() {
    const stats = this.state.restrictedStatistics;
    return !!stats && (stats.articleCount + stats.bookCount + stats.journalCount !== 0);
  }

}

export default withTranslation()(Welcome);
