import React from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async'
import i18n from "../i18n/i18n";
import { fallbackLanguage, getCurrentLanguageShortCode } from "../common/LanguageUtils";
import Dropdown from "react-bootstrap/Dropdown"

/**
 * Renders the language switching button.
 */
class LanguageSwitcher extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      languages: [ "pl", "en" ]
    };

    const reportedLanguage = getCurrentLanguageShortCode(i18n.languages);

    if (this.state.languages.includes(reportedLanguage)) {
      this.state.currentLanguage = reportedLanguage;
    }
    else {
      this.state.currentLanguage = fallbackLanguage;
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    const changeLanguage = (language) =>  {
      i18n.changeLanguage(language)
        .then(() => this.setState({currentLanguage: language}));
    }

    const getFlagImagePath = (language) => {
      return "/flag-" + language.toUpperCase() + ".png";
    }

    const generateLabel = (language, long) =>  {

      return <>
            <img src={getFlagImagePath(language)} alt="" className="change-language-flag"></img> 
            {long ? t("navbar.menuitem.switchlanguage.button.labellong." + language) : t("navbar.menuitem.switchlanguage.button.label." + language)}
          </>;
    }

    const getCurrentLanguage = () =>  {
      return this.state.currentLanguage;
    }

    return (
        <>
          <Helmet htmlAttributes={{lang: getCurrentLanguage()}} />
          
          <Dropdown className="btn header-dropdown-btn" id="language-dropdown-container">
            <Dropdown.Toggle variant="link" id="language-dropdown">
                <span className="sr-only">{t('navbar.menuitem.switchlanguage.tooltip')}</span>
                <span className="sr-only">{', ' + t('navbar.menuitem.switchlanguage.current.' + getCurrentLanguage()) + ' - '}</span>
                {generateLabel(getCurrentLanguage(), false)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.languages.filter(lang => lang !== getCurrentLanguage())
              .map((lang) =>
                <Dropdown.Item aria-label={t("navbar.menuitem.switchlanguage.button.tooltip." + lang)}
                               onSelect={() => changeLanguage(lang)}
                               lang={lang} key={lang}>
                  {generateLabel(lang, true)}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </>
    );
  }
}

export default withTranslation()(LanguageSwitcher);
