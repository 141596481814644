import React from 'react';
import SafeMail from '../publication/common/SafeMail';
import { withTranslation } from 'react-i18next';
import { FaUniversalAccess } from 'react-icons/fa';
import AccessibleOverlayTrigger from './AccessibleOverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


class AccessibilityRequestLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null
    }
  }

  componentDidMount() {
    this.props.api.get('/info/contact')
      .then( response => this.setState({ email: response.data.helpdeskEmail }) );
  }

  render () {
    const label = this.props.t('publication.details.fulltext.accessibility.request');
    const nonce = Math.random().toString(16).substr(2, 10);
    const tooltip = (<Tooltip id={'accessibility-request-tooltip-' + nonce }>{label}</Tooltip>);
    return (
      <AccessibleOverlayTrigger placement='top' overlay={tooltip}>
        <span>
          <SafeMail renderAsLink encoded={this.state.email}>
            <FaUniversalAccess className="accessibility-request-icon" size={'2em'} aria-label={label}/>
          </SafeMail>
        </span>
      </AccessibleOverlayTrigger>
    );
  }
}

export default withTranslation()(AccessibilityRequestLink);
