import React from 'react';
import {Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SafeMail from './SafeMail';
import {FaExternalLinkAlt} from "react-icons/fa";
import {createOrcidUrl, formatOrcid} from "./OrcidUtils";
import { buildContributorFullName } from '../../common/utils';
import FormattedContent from '../common/FormattedContent';

/**
 * Renders a popup with contributor's data. Expects the contributor data
 * to be passed as 'contributor' prop.
 */
export default function ContributorInfo(props) {
  const { t } = useTranslation();

  if (props.contributor) {
    const affiliations = getContributorAffiliationDisplayNames(props.contributor.publicationInstitutions).join(', ');
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="info-title"
        centered
      >
        <Modal.Header closeButton closeLabel={t('modal.close.button.label')}>
          <Modal.Title id="info-title">{t('contributor.modal.header')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="info-item modal-panel contributor-info">
            <Col data-contributor="name" sm="12">
              <h6>{t('details.publication.contributors.name')}</h6>
              <strong>{buildContributorFullName(props.contributor.firstName, props.contributor.lastName) }</strong>
            </Col>
            <Col data-contributor="email" sm="12">
              <h6>{t('details.publication.contributors.email')}</h6>
              {props.contributor.email ?
                <strong><SafeMail encoded={props.contributor.email}></SafeMail></strong>
                : <em>{t('details.publication.contributors.section.noInfo')}</em>
              }
            </Col>
            <Col data-contributor="degrees" sm="12">
              <h6>{t('details.publication.contributors.degrees')}</h6>
              {props.contributor.degrees ?
                <strong>{props.contributor.degrees}</strong>
                : <em>{t('details.publication.contributors.section.noInfo')}</em>
              }
            </Col>
          </Row>

          {props.contributor.corresponding && <Row className="info-item modal-panel contributor-info">
            <Col data-contributor="corresponding">
              <h6>{t('details.publication.contributors.corresponding')}</h6>
              <strong>{isCorresponding(props.contributor, t)}</strong>
            </Col>
          </Row>}

          <Row className="info-item modal-panel contributor-info">
            <Col data-contributor="affiliations">
              <h6>{t('details.publication.contributors.affiliations')}</h6>
              {affiliations ?
                <strong>{affiliations}</strong>
                : <em>{t('details.publication.contributors.section.noInfo')}</em>
              }
            </Col>
          </Row>
          <Row className="info-item modal-panel contributor-info">
            <Col data-contributor="orcid">
              <h6>{t('details.publication.contributors.orcid')}</h6>
              {props.contributor.orcid ?
                <a href={createOrcidUrl(props.contributor.orcid)} target="_blank" rel="noopener noreferrer" className="orcid-link">
                  <strong>{formatOrcid(props.contributor.orcid)}</strong>
                  <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                </a>
                : <em>{t('details.publication.contributors.section.noInfo')}</em>
              }
            </Col>
          </Row>
          <Row className="info-item modal-panel contributor-info">
            <Col data-contributor="bio" className="preserve-whitespace">
              <h6>{t('details.publication.contributors.bio')}</h6>
              {props.contributor.biography ?
                <FormattedContent content={props.contributor.biography} />
                : <em>{t('details.publication.contributors.section.noInfo')}</em>
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t('contributor.modal.button.close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

// -------------------- PRIVATE --------------------

function getContributorAffiliationDisplayNames(affiliations) {
  return affiliations.map(institution => (institution.position + 1) + '. ' + institution.publicationInstitutionName);
}

function isCorresponding(contributor, t) {
  return t('details.publication.contributors.corresponding.' + !!contributor.corresponding);
}
