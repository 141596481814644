import React from 'react';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { withTranslation } from 'react-i18next';
import { getCurrentLanguageShortCode } from '../common/LanguageUtils';
import PubroSpinner from '../common/PubroSpinner';
import { insertNonBreakableCharacters } from '../common/utils';


class ScientificFieldLinks extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      scientificFields: null,
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.props.api.get('/scientific-fields').then(response => {
      this.setState({
        scientificFields: response.data,
        isLoading: false
      });
    });
  }

  // -------------------- LOGIC --------------------

  render() {
    if (this.state.isLoading) {
      return <PubroSpinner/>;
    }

    const t = this.props.t;
    const i18n = this.props.i18n;

    return (
      <>
        <LinkContainer to="/">
          <Button variant="link"
                  aria-label={t('welcome.scientificFields.all.ariaLabel')}
                  aria-current={!this.isAnyScientificFieldSelected() ? 'page' : 'false'}>
            {t('welcome.scientificFields.all')}
          </Button>
        </LinkContainer>
        {this.state.scientificFields.map(scientificField =>
          <LinkContainer to={`?scientificField=${scientificField.id}`} key={'scientific-field-' + scientificField.id}>
            <Button variant="link"
                    aria-label={this.getScientificFieldName(scientificField, i18n) + ' ' + t('welcome.scientificFields.ariaLabelPostfix')}
                    aria-current={this.isScientificFieldSelected(scientificField.id) ? 'page' : 'false'}>
              {insertNonBreakableCharacters((this.getScientificFieldName(scientificField, i18n)))}
            </Button>
          </LinkContainer>
        )}
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  getScientificFieldName(scientificField, i18n) {
    return getCurrentLanguageShortCode(i18n.languages) === 'pl' ? scientificField.namePl : scientificField.nameEn;
  }

  isScientificFieldSelected(scientificFieldId) {
    if (!this.props.selectedScientificFieldId) {
      return scientificFieldId === null || scientificFieldId === undefined;
    }
    return scientificFieldId === parseInt(this.props.selectedScientificFieldId);
  }

  isAnyScientificFieldSelected() {
    return this.props.selectedScientificFieldId !== undefined;
  }
}

export default withTranslation()(ScientificFieldLinks);
