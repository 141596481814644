import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { convertToIetfLanguageTag } from '../common/LanguageUtils';
import WbnAccessIcon from "../common/WbnAccessIcon";


export default function JournalListItems({journalListItems}) {
  const {t} = useTranslation();

  if (journalListItems.length === 0) {
    return <em>{t('journal.list.empty')}</em>;
  }

  const journalElements = journalListItems.map(journalListItem => (
    <LinkContainer to={`/journals/${journalListItem.id}`} key={journalListItem.id} className={journalListItem.restricted ? "wbn-access-restricted" : ""}>
      <ListGroup.Item action>
        {journalListItem.restricted && <WbnAccessIcon type={'JOURNAL'}/>}
        <h3 lang={convertToIetfLanguageTag(journalListItem.mainTitleLanguage)}>
          {journalListItem.mainTitle}
        </h3>
      </ListGroup.Item>
    </LinkContainer>
  ));

  return (
    <ListGroup>
      {journalElements}
    </ListGroup>
  );
}
