import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {formatDate} from '../../common/utils';

/**
 * Render a simple list of dates. Expects the 'dates' prop.
 */
export default function DateList({ dates }) {
  const { t } = useTranslation();
  if (dates && dates.length > 0) {
    return (
      <Row>
        <Col>
          {dates.map((date, index) => (
            <Row key={date.type + index}>
              <Col sm={6} md={12} lg={6} className='dateType'>
                {t('dictionary.publication_date_type.' + date.type.toLowerCase())}
              </Col>
              <Col sm={6} md={12} lg={6} className='dateValue font-weight-bold'>{formatDate(date.date)}</Col>
            </Row>
          ))}
        </Col>
      </Row>
    );
  }
}
