import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { LinkContainer } from 'react-router-bootstrap';
import { withTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import Col from 'react-bootstrap/Col';
import { generateMetaDescription, generateMetaTitle } from '../../common/meta';
import PubroSpinner from '../../common/PubroSpinner';
import { MAIN_CONTENT_ID } from '../../main-structure/SkipToContentLinks';
import { extractNamePartFromUrlQuery, extractPageFromUrlQuery } from '../../main-structure/library-utils';
import LibrarySearch from '../../main-structure/LibrarySearch';
import { convertToIetfLanguageTag } from '../../common/LanguageUtils';
import ResultListPagination from '../../common/ResultListPagination';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';
import FormattedContent from '../common/FormattedContent';
import WbnAccessIcon from '../../common/WbnAccessIcon';


class BookList extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      books: null,
      totalElements: 0,
      pageSize: 100,
      namePart: extractNamePartFromUrlQuery(this.props.location.search),
      page: extractPageFromUrlQuery(this.props.location.search),
      isLoading: true
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.updateBooks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        namePart: extractNamePartFromUrlQuery(this.props.location.search),
        page: extractPageFromUrlQuery(this.props.location.search),
        isLoading: true
      }, this.updateBooks);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    if (this.state.isLoading) {
      return <PubroSpinner/>;
    }

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: t('book.list.title') });

    return (
      <>
        <Helmet title={ generateMetaTitle(t, {'label' : 'meta.books.title'}) }
                meta={[{ name: "description", content: generateMetaDescription(t, {'label' : 'meta.books.description'}) }]}/>
        <main id={MAIN_CONTENT_ID}>
          <div className="tabbed-details-name-container">
            <div className="tabbed-details-name-container tabbed-details-sidebar-present">
              <h1 className="library-list-header">{t('book.list.title')}</h1>
            </div>
            <div className="tabbed-details-sidebar">
              <LibrarySearch namePart={this.state.namePart} onSubmit={this.onFormSubmit}
                             placeholderKey='book.search.input.placeholder'
                             buttonTitleKey='book.search.button.title'/>
            </div>
          </div>
          <Container className="mt-0 container-without-cols">
            <Row>
              {this.state.books.length === 0 ? this.emptyBooks() : this.bookList()}
            </Row>
          </Container>
          {this.state.totalElements > this.state.pageSize && this.pagination()}
        </main>
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  emptyBooks() {
    const t = this.props.t;
    const emptyText = (this.state.namePart ? t('book.list.emptySearch') : t('book.list.empty'));
    return <em>{emptyText}</em>;
  }

  bookList() {
    const bookElements = this.state.books.map(bookListItem => (
      <LinkContainer to={`/books/${bookListItem.id}`} key={bookListItem.id}>
        <ListGroup.Item action className={bookListItem.restricted ? "wbn-access-restricted" : ""}>
          {bookListItem.restricted && <WbnAccessIcon type={bookListItem.collectiveWork ? 'COLLECTIVE_WORK' : 'SIMPLE_BOOK'}/>}
          <h3 lang={convertToIetfLanguageTag(bookListItem.titleLanguage)}>
            <ProcessMath><FormattedContent as='span' content={transformAsciiMathDelimiter(bookListItem.title)} /></ProcessMath>
          </h3>
        </ListGroup.Item>
      </LinkContainer>
    ));

    return (
      <Container className="resources-container">
        <ListGroup className="resources-list">
          {bookElements}
        </ListGroup>
      </Container>
    );
  }

  pagination() {
    const namePart = this.state.namePart;
    const linkFn = page => queryString.stringify({namePart: namePart ? namePart : undefined, page: page});

    return (
      <Container>
        <Row>
          <Col>
            <ResultListPagination currentPage={this.state.page}
                                  linkFn={linkFn}
                                  totalResults={this.state.totalElements}
                                  pageSize={this.state.pageSize}/>
          </Col>
        </Row>
      </Container>
    );
  }

  updateBooks() {
    this.props.api.get(`/books`, {params: {namePart: this.state.namePart, page: this.state.page, pageSize: this.state.pageSize}})
      .then(response => this.setState({
        books: response.data.content,
        totalElements: response.data.totalElements,
        isLoading: false
      }));
  }

  onFormSubmit(formValues) {
    this.props.history.push('/books?namePart=' + encodeURIComponent(formValues.namePart));
  }
}

export default withTranslation()(BookList);
