import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";

/**
 * Renders all basic journal issue information.
 */
export default function IssueBasicInfo({ issue }) {
  const { t } = useTranslation();
  return (
    <div>
      {issue.journalInfo && issue.journalInfo.title && journalTitle(issue.journalInfo.title, t)}
      {issue.year && year(issue.year, t)}
      {issue.volume && volume(issue.volume, t)}
      {issue.number && number(issue.number, t)}
    </div>
  );
}

// -------------------- PRIVATE --------------------

function journalTitle(journalTitle, t) {
  return (
    <Row>
      <Col>{t('issue.details.journal')}: <strong data-issue="journal">{journalTitle}</strong></Col>
    </Row>
  );
}

function year(issueYear, t) {
  return (
    <Row>
      <Col>{t('issue.details.year')}: <strong data-issue="year">{issueYear}</strong></Col>
    </Row>
  );
}

function volume(issueVolume, t) {
  return (
    <Row>
      <Col>{t('issue.details.volume')}: <strong data-issue="volume">{issueVolume}</strong></Col>
    </Row>
  );
}

function number(issueNumber, t) {
  return (
    <Row>
      <Col>{t('issue.details.number')}: <strong data-issue="number">{issueNumber}</strong></Col>
    </Row>
  );
}

