import React from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { isBlank } from './AdvancedSearchUtils';
import { getSelectAriaLiveMessages } from '../../common/utils';


class SortForm extends React.Component {

  // -------------------- LOGIC --------------------

  render() {
    const options = this.getOptions()
    return <Select inputId={this.props.inputId}
                   options={options}
                   value={this.getSelectedOptions(options)}
                   onChange={selectedOption => this.props.onChange(selectedOption.value)}
                   placeholder={''}
                   ariaLiveMessages={getSelectAriaLiveMessages(this.props.t)}
                   isSearchable={false} isClearable={false}/>;
  }

  getOptions() {
    return [
      { label: this.props.t('search.result.sort.score.asc'), value: { field: 'score', direction: 'ASC' }},
      { label: this.props.t('search.result.sort.score.desc'), value: { field: 'score', direction: 'DESC' }},
      { label: this.props.t('search.result.sort.date.asc'), value: { field: 'publishedDate', direction: 'ASC' }},
      { label: this.props.t('search.result.sort.date.desc'), value: { field: 'publishedDate', direction: 'DESC' }}
    ]
  }

  getSelectedOptions(allOptions) {
    const field = this.props.urlQueryValues.sortField;
    const direction = this.props.urlQueryValues.sortDirection;
    const selected = allOptions.filter(opt => opt.value.field === field && opt.value.direction === direction);
    if (selected.length > 0) {
      return selected;
    }

    const defaultField = isBlank(this.props.urlQueryValues) ? 'publishedDate' : 'score';
    const defaultDirection = 'DESC';
    return allOptions.filter(opt => opt.value.field === defaultField && opt.value.direction === defaultDirection);
  }
}

export default withTranslation()(SortForm);
