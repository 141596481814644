import React, { useState } from 'react';
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";

/**
 * Accessible version of Accordion.Toggle.
 * It modifies its aria-expanded property
 * to reflect whether accordion is expanded
 * or not.
 */
export default function AccessibleAccordionToggle({ children, eventKey }) {
  const [expanded, setExpanded] = useState(true)
  
  const handleToggle = (e) => {
    setExpanded(!expanded);
  };
  
  return (
    <Accordion.Toggle as={Button} className="expand-button" variant="light" eventKey={eventKey} aria-expanded={expanded} onClick={handleToggle}>
      {children}
    </Accordion.Toggle>
  );
}