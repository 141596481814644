
// -------------------- LOGIC --------------------

/**
 * Returns ISO 8601 date complaint string from structured date.
 * 
 * @see https://en.wikipedia.org/wiki/ISO_8601#Dates
 * @see YearMonthDayDateVO java class
 */
export function convertToIsoDate(ymdDate) {
  if (ymdDate.day) {
    const dayString = formatNumberToZeroPrefixedString(ymdDate.day, 2);
    const monthString = formatNumberToZeroPrefixedString(ymdDate.month, 2);
    return `${ymdDate.year}-${monthString}-${dayString}`;
  }
  if (ymdDate.month) {
    const monthString = formatNumberToZeroPrefixedString(ymdDate.month, 2);
    return `${ymdDate.year}-${monthString}`;
  }
  return '' + ymdDate.year;
}

/**
 * Returns encoding type from format string. Function
 * supports formats defined in FullTextFormat java class.
 */
export function convertFormatToEncodingType(format) {
  return FORMAT_TO_ENCODING_MAPPING[format];
}

// -------------------- PRIVATE --------------------

/**
 * Returns the given number in a string format with
 * leading zeros (number of zeros is determined by amountOfDigits
 * argument).
 * Example:
 * formatNumberToZeroPrefixedString(9, 3) = '009'
 * formatNumberToZeroPrefixedString(9, 2) = '09'
 * formatNumberToZeroPrefixedString(109, 2) = '109'
 */
function formatNumberToZeroPrefixedString(number, amountOfDigits) {
  const numberString = '' + number;
  if (numberString.length < amountOfDigits) {
    return '0'.repeat(amountOfDigits - numberString.length) + numberString;
  }
  return numberString;
}

const FORMAT_TO_ENCODING_MAPPING = {
  'PDF': 'application/pdf',
  'EPUB': 'application/epub+zip',
  'MOBI': 'application/x-mobipocket-ebook',
  'ZIP': 'application/zip'
}
