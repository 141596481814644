import React from 'react';
import Image from 'react-bootstrap/Image';
import {useTranslation} from 'react-i18next';
import './BookCover.css';

BookCover.defaultProps = {
  thumbnail: false,
  emptyAlt: false
}

/**
 * Renders the cover of the book. Expects coverId prop.
 */
export default function BookCover({ coverId, thumbnail, emptyAlt, bookTitle }) {
  const { t } = useTranslation();

  let imageUrl = '/bookCoverThumbnailPlaceholder.png';
  if (coverId) {
    imageUrl = thumbnail ? `${process.env.REACT_APP_PUBRO_API_URL}/images/book-cover-thumbnails/${coverId}`
                         : `${process.env.REACT_APP_PUBRO_API_URL}/images/book-covers/${coverId}`;
  }

  let alt = '';
  if (!emptyAlt) {
    if (!bookTitle) {
      throw new Error('bookTitle must be specified if emptyAlt is set to true');
    }
    alt = coverId ? (t('book.cover.alt') + ' ' + bookTitle) : t('book.cover.default.alt');
  }

  return (
    <Image className={`book-cover ${coverId ? 'custom-image' : ''}`}
           src={imageUrl}
           alt={alt}
           loading='lazy'/>
  );
};
