import React from 'react';
import './ContributorsDetails.css';
import ContributorInfo from './ContributorInfo'
import { Button } from 'react-bootstrap';
import { FaAngleLeft, FaEllipsisH } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import AccessibleOverlayTrigger from '../../common/AccessibleOverlayTrigger';
import { buildContributorFullName } from '../../common/utils';
import Tooltip from 'react-bootstrap/Tooltip';

/**
 * Renders a horizontal list of contributors (first name, last name, affiliation
 * indices, corresponding role mark). Expects the contributor data to be passed as 'contributors' prop.
 */
class ContributorsDetails extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      contributorDisplayThreshold: 5,
      currentContributorDisplayCount: 5,
      contributors: this.props,
      contributorWithOpenModalIndex: null
    };
    this.overlayTriggerRef = React.createRef();
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    if (this.props.contributors && this.props.contributors.length > 0) {
      const contributorElements = this.props.contributors.map((contributor, index) => {

        return (<React.Fragment key={'contributor-' + index}>
          <span>
            <Button variant="link" className="contributor-full-name" onClick={() => this.setState({ contributorWithOpenModalIndex: index })}>
              {buildContributorFullName(contributor.firstName, contributor.lastName)}
            </Button>
            {(contributor.publicationInstitutions.length > 0 || contributor.corresponding) &&
              <span className="annotations">
                {contributor.corresponding &&
                  <AccessibleOverlayTrigger key='corresponding' overlay={
                      <Tooltip className='tooltip'>
                        {t('dictionary.contributor_role.' + contributor.role.toLowerCase() + '.corresponding')}
                      </Tooltip>
                    }>
                    <sup>{t('details.publication.contributors.corresponding.asterisk')} </sup>
                  </AccessibleOverlayTrigger>}
                {contributor.publicationInstitutions.length > 0 &&
                  contributor.publicationInstitutions
                    .sort((a, b) => (a.position > b.position) ? 1 : -1)
                    .map((pubInst, index) =>
                    <AccessibleOverlayTrigger key={(pubInst.position + 1)} overlay={
                        <Tooltip className='tooltip'>{pubInst.publicationInstitutionName}</Tooltip>
                      }>
                      <sup>{(pubInst.position + 1)}{(index < contributor.publicationInstitutions.length-1) ? ', ' : ''}</sup>
                    </AccessibleOverlayTrigger>
                  )
                }
              </span>
            }

            <ContributorInfo contributor={contributor}
                            show={this.state.contributorWithOpenModalIndex === index}
                            onHide={() => this.setState({ contributorWithOpenModalIndex: null })}/>
          </span>
          {(index < this.props.contributors.length-1) ? ', ' : ''}
        </React.Fragment>);
      });

      return (<span className='contributors'>
        {contributorElements.slice(0, this.state.currentContributorDisplayCount)}
        {(contributorElements.length > 5) && (contributorElements.length > this.state.currentContributorDisplayCount ?
          this.showMoreContributorsButton() : this.showLessContributorsButton()
        )}
        </span>
      );
    }
  }

  // -------------------- PRIVATE --------------------

  showMoreContributorsButton() {
    const t = this.props.t;
    const buttonAriaLabel = t('details.publication.contributors.more.tooltip') +
      ' ' + t('details.publication.contributors.more.tooltip.' + this.props.contributors[0].role);
    const showAllContributorsTooltip = <Tooltip>{buttonAriaLabel}</Tooltip>;

    return (
      <div className="contributor-button-container" key={"showMoreContributors"}>
        <AccessibleOverlayTrigger overlay={showAllContributorsTooltip}>
          <Button variant="light" aria-label={buttonAriaLabel} data-show-all-button
                  onClick={() => this.setState({ currentContributorDisplayCount: this.state.contributors.length })}>
            <FaEllipsisH />
          </Button>
        </AccessibleOverlayTrigger>
      </div>
    );
  }

  showLessContributorsButton() {
    const t = this.props.t;
    const buttonAriaLabel = t('details.publication.contributors.less.tooltip')
    const showLessContributorsTooltip = <Tooltip>{buttonAriaLabel}</Tooltip>;

    return (
      <div className="contributor-button-container" key={"showLessContributors"}>
        <AccessibleOverlayTrigger overlay={showLessContributorsTooltip}>
          <Button variant="light" aria-label={buttonAriaLabel} data-show-less-button
                  onClick={() => this.setState({ currentContributorDisplayCount: this.state.contributorDisplayThreshold })}>
            <FaAngleLeft />
          </Button>
        </AccessibleOverlayTrigger>
      </div>
    );
  }
}

export default withTranslation()(ContributorsDetails);
