import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PubroSpinner from '../../common/PubroSpinner';
import Col from 'react-bootstrap/Col';
import Breadcrumbs from '../../common/Breadcrumbs';
import { formatIssn, formatIssueLabel } from '../../common/utils';
import { Helmet } from 'react-helmet-async'
import { parse } from 'query-string'
import PublicationDetails from '../common/PublicationDetails';
import ArticleDetailsSidePanel from './ArticleDetailsSidePanel';
import { getCommonPublicationMetaTagValues } from '../common/MetaUtils';
import { buildArticleJsonLd } from './ArticleJsonLd';
import { MAIN_CONTENT_ID } from '../../main-structure/SkipToContentLinks';
import { withTranslation } from 'react-i18next';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import { isInPreviewMode } from '../../common/utils';
import { generateMetaTitle, generateMetaDescription, generateSeparatedList, mergeTitleElements } from '../../common/meta';
import { cleanFormattedContent } from '../common/FormattedContentUtil';

/**
 * Renders article details. Id of the article is determined from the 'articleId'
 * path variable. Contacts the backend to fetch ArticleVO when mounted.
 */
class ArticleDetails extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      article: null,
      isLoading: true,
      pbnExportUrl: null
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    const { articleId } = this.props.match.params;
    const { previewToken } = parse(this.props.location.search);
    const config = previewToken ? { params: { previewToken } } : undefined;

    Promise.all([
      this.props.api.get(`/articles/${articleId}`, config),
      this.props.api.head(`/pbn/articles/${articleId}`)
    ])
    .then(([articleResponse, pbnResponse]) => {
      this.setState({
        article: articleResponse.data,
        pbnExportUrl: pbnResponse.headers.location,
        isLoading: false
      });
    });
  }

  // -------------------- LOGIC --------------------

  render() {
    const article = this.state.article;
    const t = this.props.t;

    if (!article) {
      return <PubroSpinner />
    }

    const meta = [{ name: "description", content: this.getMetaDescription(t) }];
    meta.push(...getCommonPublicationMetaTagValues(article));
    meta.push(...getArticleSpecificMetaTagValues(article));

    const jsonLd = buildArticleJsonLd(this.state.article);

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: article.mainTitle.text });

    return (
      <>
        <Helmet title={this.getMetaTitle(t)} meta={meta}
                script={[{type: "application/ld+json", innerHTML: JSON.stringify(jsonLd)}]}/>
        <Breadcrumbs items={this.getBreadcrumbs(article, this.props.t)} maxItemLength={40} />
        <Container id={MAIN_CONTENT_ID} as="main" className="mt-0">
          <Row>
            <Col md={8} className="publication-details-container">{this.basicDetails()}</Col>
            <Col md={4} className="publication-details-sidebar">{this.sidePanel()}</Col>
          </Row>
        </Container>
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  basicDetails() {
    if (this.state.article) {
      const { previewToken } = parse(this.props.location.search);
      return (
        <Row>
          <PublicationDetails publication={this.state.article}
                              publicationKind='article.details.kind'
                              publicationType='ARTICLE'
                              abstractLabel=''
                              publicationTypePrefix='dictionary.article_type.'
                              previewMode={isInPreviewMode(this)}
                              api={this.props.api}
                              previewToken={previewToken}/>
        </Row>
      )
    }
    return <PubroSpinner />
  }

  sidePanel() {
    if (this.state.article) {
      return (
        <Row>
          <ArticleDetailsSidePanel article={this.state.article}
                                   pbnExportUrl={this.state.pbnExportUrl}
                                   previewMode={isInPreviewMode(this)}
                                   api={this.props.api}/>
        </Row>
      )
    }
    return <PubroSpinner />
  }

  getBreadcrumbs(article, t) {
    return [
      { label: article.issueInfo.journalInfo.publishingCompanyInfo.name, link: `/publishing-companies/${article.issueInfo.journalInfo.publishingCompanyInfo.id}` },
      { label: article.issueInfo.journalInfo.title, link: `/journals/${article.issueInfo.journalInfo.id}` },
      { label: formatIssueLabel(article.issueInfo), link: `/issues/${article.issueInfo.id}` },
      { label: article.mainTitle.text, link: null, math: true, html: true }
    ];
  }

  getMetaTitle(t) {
    const article = this.state.article;

    let metaTitle = cleanFormattedContent(article.mainTitle.text);

    if (article.contributors && article.contributors.length > 0) {
      let contributors = generateSeparatedList({"type": "contributors", "data": article.contributors});
      metaTitle = mergeTitleElements([metaTitle, contributors]);
    }

    return generateMetaTitle(t, {"text": metaTitle});
  }

  getMetaDescription(t) {
    const defaultDescription = generateMetaDescription(t, {});
    const article = this.state.article;

    if (article) {
      let description = "";

      if (article.mainAbstract && article.mainAbstract.text.length > 0) {
        description = cleanFormattedContent(article.mainAbstract.text);
      }
      else if (article.keywords && article.keywords.length > 0) {
        description = generateSeparatedList({"type": "keywords", "data": article.keywords, "language": article.language});
      }
      else if (article.issueInfo && article.issueInfo.journalInfo && article.issueInfo.journalInfo.title.length > 0) {
        description = article.issueInfo.journalInfo.title;
      }

      if (description.length === 0) {
        return defaultDescription;
      }
      else {
        return generateMetaDescription(t, {"text": description})
      }
    }
    else {
      return defaultDescription;
    }
  }
}

function getArticleSpecificMetaTagValues(article) {
  const meta = [{name: 'citation_journal_title', content: article.issueInfo.journalInfo.title}];

  if (article.issueInfo.volume) {
    meta.push({name: 'citation_volume', content: article.issueInfo.volume});
  }
  meta.push({name: 'citation_issue', content: article.issueInfo.number});

  if (article.pageRange && /^.+-.+$/.test(article.pageRange)) {
    const parts = article.pageRange.split('-');
    meta.push({name: 'citation_firstpage', content: parts[0]});
    meta.push({name: 'citation_lastpage', content: parts[1]});
  }

  if (article.issueInfo.journalInfo.issn) {
    meta.push({name: 'citation_issn', content: formatIssn(article.issueInfo.journalInfo.issn)});
  }
  if (article.issueInfo.journalInfo.eissn) {
    meta.push({name: 'citation_issn', content: formatIssn(article.issueInfo.journalInfo.eissn)});
  }

  return meta;
}

export default withTranslation()(ArticleDetails);
