import React from "react";
import {useTranslation} from "react-i18next";

/**
 * Component rendering language name from translation labels for a given
 * ISO 639-2/T designation passed as 'lang', eg. for the value of 'ENG'
 * the component will render 'Angielski' if the application language is set to
 * Polish.
 */
export default function Language({ language }) {
  const { t } = useTranslation();
  return (<>{t('dictionary.language.' + language)}</>);
}
