import React from 'react';
import SearchResultFrame from '../common/SearchResultFrame';
import CollectiveWorkSearchResultSidebar from './CollectiveWorkSearchResultSidebar';
import SearchResultNoFulltextBody from '../common/SearchResultNoFulltextBody';

/**
 * Search result list item for collective works. Renders a common search result
 * frame with no fulltext specific body and collective work specific sidebar.
 *
 * @author sniewcza
 */
export default function CollectiveWorkSearchResultListItem({document, urlQueryValues}) {
  const mainBody = <SearchResultNoFulltextBody headerKey={'search.result.list.item.frame.collectivework.header'} document={document} urlQueryValues={urlQueryValues}/>;
  const sidebar = <CollectiveWorkSearchResultSidebar document={document} urlQueryValues={urlQueryValues}/>;
  return (
    <SearchResultFrame mainBody={mainBody}
                       sidebar={sidebar}/>
  );
}
