import React from 'react';
import AsyncSelect from 'react-select/async';
import { withTranslation } from "react-i18next";
import { getSelectAriaLiveMessages } from '../../common/utils';

/**
 * Renders an input field in which multiple keywords can be entered. Duplicate
 * values are not allowed. Sets the values of selected options back to formik
 * `keywords` field.
 *
 * Relies on react-select component. See: https://react-select.com
 *
 * @author sniewcza
 */
class KeywordForm extends React.Component {

  minInputValueLength = 3;

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.loadOptions = this.loadOptions.bind(this);
    this.updateValues = this.updateValues.bind(this);
    this.noOptionsMessage = this.noOptionsMessage.bind(this);
    this.state = { value: [] }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.setState({ value: this.props.formik.values.keywords.map(convertKeywordToOption) });
  }

  /**
   * Resetting the whole form triggered from AdvancedSearchForm clears the
   * related formik value, but this is not enough to reset react-select.
   * To reset the select, we store the selected options as `this.state.value`,
   * and replace it with empty array when formik state does not match it
   * anymore.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.needsReset()) {
      this.reset();
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    return <AsyncSelect inputId={this.props.inputId}
                        isMulti
                        value={this.state.value}
                        loadOptions={this.loadOptions}
                        onChange={selectedOptions => this.updateValues(selectedOptions)}
                        placeholder={''}
                        openMenuOnClick={false}
                        hideSelectedOptions={false}
                        loadingMessage={() => this.props.t('search.form.select.async.loading.message')}
                        noOptionsMessage={this.noOptionsMessage}
                        ariaLiveMessages={getSelectAriaLiveMessages(this.props.t)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}/>
  }

  loadOptions(inputValue) {
    if (inputValue && inputValue.length >= this.minInputValueLength) {
      return this.props.api.get('/search/keywords?substring=' + encodeURIComponent(inputValue))
        .then(response => moveExactMatchToTop(inputValue, response.data.suggestions)
          .filter(keyword => !this.props.formik.values.keywords.includes(keyword))
          .map(convertKeywordToOption));
    }
    return Promise.resolve([]);
  }

  updateValues(selectedOptions) {
    this.props.formik.setFieldValue('keywords', selectedOptions ? selectedOptions.map(opt => opt.value) : []);
    this.setState({ value: selectedOptions });
  }

  noOptionsMessage(input) {
    return input.inputValue && input.inputValue.length < this.minInputValueLength
      ? this.props.t('search.form.advanced.keyword.minimum.length.message')
      : this.props.t('search.form.select.nooptions.message');
  }

  needsReset() {
    return this.props.formik.values.keywords.length === 0 && this.state.value && this.state.value.length > 0;
  }

  reset() {
    this.setState({ value: [] });
  }
}

export default withTranslation()(KeywordForm);

// -------------------- PRIVATE --------------------

function convertKeywordToOption(keyword) {
  return {
    label: keyword,
    value: keyword
  };
}

function moveExactMatchToTop(inputValue, suggestions) {
  const lowerCaseInputValue = inputValue.toLowerCase();
  if (!suggestions.includes(lowerCaseInputValue)) {
    return suggestions;
  }
  const exactMatchRemoved = suggestions.filter(item => item !== lowerCaseInputValue);
  exactMatchRemoved.unshift(lowerCaseInputValue);
  return exactMatchRemoved;
}
