import React from "react";
import HighlightedContent from './HighlightedContent';
import { useTranslation } from 'react-i18next';
import ProcessMath from '../../../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../../../common/utils';

/**
 * Renders abstract part of search result list item. Displays highlighted
 * snippet if snippets are present, otherwise renders the abstract itself.
 * If there is no abstract either, then renders a 'no abstract provided'
 * message.
 *
 * @author sniewcza
 */
export default function SearchResultAbstract({abstract, abstractSnippets}) {
  const {t} = useTranslation();
  return abstractSnippets.length > 0 && abstractSnippets[0]
    ? <ProcessMath><HighlightedContent content={transformAsciiMathDelimiter(abstractSnippets[0]) + '...'}/></ProcessMath>
    : <ProcessMath><p className='clamped'>{abstract ? transformAsciiMathDelimiter(abstract) : '-'}</p></ProcessMath>;
}
