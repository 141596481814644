import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PubroSpinner from '../../common/PubroSpinner';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../common/Breadcrumbs';
import { Helmet } from 'react-helmet-async'
import { parse } from 'query-string';
import PublicationDetails from '../common/PublicationDetails';
import ChapterDetailsSidePanel from './ChapterDetailsSidePanel';
import { getCommonPublicationMetaTagValues } from '../common/MetaUtils';
import { buildChapterJsonLd } from './ChapterJsonLd';
import { MAIN_CONTENT_ID } from '../../main-structure/SkipToContentLinks';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import { isInPreviewMode } from '../../common/utils';
import { generateMetaDescription, generateMetaTitle, generateSeparatedList, mergeTitleElements } from '../../common/meta';
import { cleanFormattedContent } from '../common/FormattedContentUtil';

/**
 * Renders chapter details. Id of the chapter is determined from the 'chapterId'
 * path variable. Contacts the backend to fetch ChapterVO when mounted.
 */
class ChapterDetails extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      chapter: null,
      pbnExportUrl: null,
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    const { chapterId } = this.props.match.params;
    const { previewToken } = parse(this.props.location.search);
    const config = previewToken ? { params: { previewToken } } : undefined;

    Promise.all([
      this.props.api.get(`/chapters/${chapterId}`, config),
      this.props.api.head(`/pbn/chapters/${chapterId}`)
    ])
    .then(([chapterResponse, pbnResponse]) => {
      this.setState({
        chapter: chapterResponse.data,
        pbnExportUrl: pbnResponse.headers.location,
        isLoading: false
      });
    });
  }

  // -------------------- LOGIC --------------------

  render() {
    const chapter = this.state.chapter;
    const t = this.props.t;

    if (!chapter) {
      return <PubroSpinner/>
    }

    const meta = [{name: "description", content: this.getMetaDescription(t)}];
    meta.push(...getCommonPublicationMetaTagValues(chapter));
    meta.push(...getChapterSpecificMetaTagValues(chapter))

    const jsonLd = buildChapterJsonLd(this.state.chapter);

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: chapter.mainTitle.text });

    return (
      <>
        <Helmet title={this.getMetaTitle(t)} meta={meta}
                script={[{type: "application/ld+json", innerHTML: JSON.stringify(jsonLd)}]}/>
        <Breadcrumbs items={this.getBreadcrumbs(this.state.chapter)} maxItemLength={75}/>
        <Container id={MAIN_CONTENT_ID} as="main" className="mt-0">
          <Row>
            <Col md={8} className="publication-details-container">{this.basicDetails()}</Col>
            <Col md={4} className="publication-details-sidebar">{this.sidePanel()}</Col>
          </Row>
        </Container>
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  basicDetails() {
    if (this.state.chapter) {
      const { previewToken } = parse(this.props.location.search);
      return (
        <Row>
          <PublicationDetails publication={this.state.chapter}
                              publicationKind='chapter.details.kind'
                              publicationType='CHAPTER'
                              abstractLabel={'publication.details.chapter.abstracts.header'}
                              publicationTypePrefix='dictionary.chapter_type.'
                              previewMode={isInPreviewMode(this)}
                              api={this.props.api}
                              previewToken={previewToken}/>
        </Row>
      )
    }
    return <PubroSpinner />
  }

  sidePanel() {
    if (this.state.chapter) {
      return (
        <Row>
          <ChapterDetailsSidePanel chapter={this.state.chapter}
                                   pbnExportUrl={this.state.pbnExportUrl}
                                   previewMode={isInPreviewMode(this)}
                                   api={this.props.api}/>
        </Row>
      )
    }
    return <PubroSpinner />
  }

  getBreadcrumbs(chapter) {
    return [
      { label: chapter.bookInfo.publishingCompanyInfo.name, link: `/publishing-companies/${chapter.bookInfo.publishingCompanyInfo.id}`},
      { label: chapter.bookInfo.title, link: `/books/${chapter.bookInfo.id}`, math: true, html: true},
      { label: chapter.mainTitle.text, link: null, math: true, html: true},
    ];
  }

  getMetaTitle(t) {
    const chapter = this.state.chapter;

    let metaTitle = cleanFormattedContent(chapter.mainTitle.text);

    if (chapter.contributors && chapter.contributors.length > 0) {
      let contributors = generateSeparatedList({"type": "contributors", "data": chapter.contributors});
      metaTitle = mergeTitleElements([metaTitle, contributors]);
    }

    return generateMetaTitle(t, {"text": metaTitle});
  }

  getMetaDescription(t) {
    const defaultDescription = generateMetaDescription(t, {});
    const chapter = this.state.chapter;

    if (chapter) {
      let description = "";

      if (chapter.mainAbstract && chapter.mainAbstract.text.length > 0) {
        description = generateMetaDescription(t, {"text": cleanFormattedContent(chapter.mainAbstract.text)});
      }
      else if (chapter.keywords.length > 0) {
        description = generateSeparatedList({"type": "keywords", "data": chapter.keywords, "language": chapter.language});
      }
      else if (chapter.bookInfo && chapter.bookInfo.title.length > 0) {
        description = generateMetaDescription(t, {"text": cleanFormattedContent(chapter.bookInfo.title)});
      }

      if (description.length === 0) {
        return defaultDescription;
      }
      else {
        return generateMetaDescription(t, {"text": description})
      }
    }
    else {
      return defaultDescription;
    }
  }
}

export default withTranslation()(ChapterDetails);

function getChapterSpecificMetaTagValues(chapter) {
  return chapter.bookInfo.isbn ? [{name: 'citation_isbn', content: chapter.bookInfo.isbn}] : [];
}
