import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import JournalBasicInfo from './JournalBasicInfo';
import IssueList from '../issue/IssueList';
import JournalIndexationAndScores from './JournalIndexationAndScores';
import JournalContact from './JournalContact';
import JournalForAuthors from './JournalForAuthors';
import { extractTabKey, extractLocationWithoutTabKey } from '../common/utils';
import { generateMetaDescription, generateMetaTitle, generateSeparatedList } from "../common/meta";


const DEFAULT_TAB_KEY = '';
const ISSUES_TAB_KEY = '/issues';
const INDEXATION_TAB_KEY = '/indexation';
const CONTACT_TAB_KEY = '/contact';
const FOR_AUTHORS_TAB_KEY = '/for-authors';
export const AVAILABLE_TABS = [DEFAULT_TAB_KEY, ISSUES_TAB_KEY, INDEXATION_TAB_KEY, CONTACT_TAB_KEY, FOR_AUTHORS_TAB_KEY];


/**
 * Renders journal tabs
 * {journal, issues, latestIssue}
 *
 * @author dbojanek
 */
class JournalTabs extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      activeTab: DEFAULT_TAB_KEY
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.setState({activeTab: extractTabKey(this.props.location.pathname, AVAILABLE_TABS, DEFAULT_TAB_KEY)});
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({activeTab: extractTabKey(this.props.location.pathname, AVAILABLE_TABS, DEFAULT_TAB_KEY)});
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    const location = this.props.location;

    const journal = this.props.journal;
    const issues = this.props.issues;
    const latestIssue = this.props.latestIssue;

    return (
      <>
        {this.props.journal &&
          <>
            <Helmet title={this.getMetaTitle(t)}
                    meta={[{name: "description", content: this.getMetaDescription(t)}]}/>
          </>
        }
        <div className="tabbed-details-tabs-container">

          <Tab.Container activeKey={this.state.activeTab} onSelect={this.handleTabSelect}>
            <div className="tabbed-details-tab-nav-container">
              <Nav variant="tabs">
                <Nav.Link eventKey={DEFAULT_TAB_KEY}
                          href={extractLocationWithoutTabKey(location.pathname, AVAILABLE_TABS) + DEFAULT_TAB_KEY}>
                  {t('publishing.company.details.header.basicinfo')}
                </Nav.Link>
                <Nav.Link eventKey={ISSUES_TAB_KEY}
                          href={extractLocationWithoutTabKey(location.pathname, AVAILABLE_TABS) + ISSUES_TAB_KEY}>
                  {t('journal.details.header.issues')}
                </Nav.Link>
                <Nav.Link eventKey={INDEXATION_TAB_KEY}
                        href={extractLocationWithoutTabKey(location.pathname, AVAILABLE_TABS) + INDEXATION_TAB_KEY}>
                  {t('journal.details.section.header.indexation')}
                </Nav.Link>
                <Nav.Link eventKey={CONTACT_TAB_KEY}
                          href={extractLocationWithoutTabKey(location.pathname, AVAILABLE_TABS) + CONTACT_TAB_KEY}>
                  {t('journal.details.section.header.contact')}
                </Nav.Link>
                <Nav.Link eventKey={FOR_AUTHORS_TAB_KEY}
                          href={extractLocationWithoutTabKey(location.pathname, AVAILABLE_TABS) + FOR_AUTHORS_TAB_KEY}>
                  {t('journal.details.section.header.for-authors')}
                </Nav.Link>
              </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey={DEFAULT_TAB_KEY}>
                <h2 className="sr-only">{t('publishing.company.details.header.basicinfo')}</h2>
                <Row className="tabbed-details-tab-basic-info">
                  <JournalBasicInfo journal={journal} lastIssue={latestIssue}/>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey={ISSUES_TAB_KEY}>
                <h2 className="sr-only">{t('journal.details.header.issues')}</h2>
                <IssueList issues={issues} />
              </Tab.Pane>
              <Tab.Pane eventKey={INDEXATION_TAB_KEY}>
                <h2 className="sr-only">{t('journal.details.section.header.indexation')}</h2>
                <JournalIndexationAndScores journal={journal}/>
              </Tab.Pane>
              <Tab.Pane eventKey={CONTACT_TAB_KEY}>
                <h2 className="sr-only">{t('journal.details.section.header.contact')}</h2>
                <JournalContact journal={journal}/>
              </Tab.Pane>
              <Tab.Pane eventKey={FOR_AUTHORS_TAB_KEY}>
                <h2 className="sr-only">{t('journal.details.section.header.for-authors')}</h2>
                <JournalForAuthors journal={journal}/>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

        </div>
      </>
    )
  }

  // -------------------- PRIVATE --------------------

  handleTabSelect = (tabKey, event) => {

    const locationWithoutTabKey = extractLocationWithoutTabKey(this.props.location.pathname, AVAILABLE_TABS);
    event.preventDefault();
    this.setState({activeTab: tabKey});
    this.props.history.push(locationWithoutTabKey + tabKey);
  };

  getMetaTitle(t) {
    const journal = this.props.journal;

    if (this.state.activeTab === DEFAULT_TAB_KEY) {
      return generateMetaTitle(t, {"text": journal.mainTitle.text});
    }
    else {
      return generateMetaTitle(t, {"text": journal.mainTitle.text, "subLabel": "journal.details.section.header." + this.state.activeTab.substring(1)});
    }
  }

  getMetaDescription(t) {
    const descriptionSeparator = ", ";
    const defaultDescription = generateMetaDescription(t, {});
    const tab = this.state.activeTab
    const journal = this.props.journal;
    const issues = this.props.issues;

    if (journal) {
      let description = "";

      if (tab == DEFAULT_TAB_KEY) {
        if (journal.mainDescription && journal.mainDescription.text.length > 0) {
          description = JSON.parse(journal.mainDescription.text).ops.map(op => op.insert).join(' ');
        }
        else if (journal.scientificFields && journal.scientificFields.length > 0) {
          description = generateSeparatedList({"type": "scientificFields", "data": journal.scientificFields})
        }
      }
      else if (tab == ISSUES_TAB_KEY && issues && issues.length > 0) {
        description = t('meta.journal.issues.description') + " " + journal.mainTitle.text + ": " + generateSeparatedList({"type": "issues", "data": issues});
      }
      else if (tab == INDEXATION_TAB_KEY) {
        if (journal.mainDatabases && journal.mainDatabases.length > 0) {
          journal.mainDatabases.forEach(function(db) {
            description += t('dictionary.journal_database_type.' + db.toLowerCase()) + descriptionSeparator;
          });
        }

        if (journal.additionalDatabases && journal.additionalDatabases.length > 0) {
          journal.additionalDatabases.forEach(function(db) {
            description += db + descriptionSeparator;
          });
        }

        if (journal.mainScores && journal.mainScores.length > 0) {
          journal.mainScores.forEach(function(score) {
            description += t('dictionary.journal_score_type.no_html.' + score.type.toLowerCase()) + ": " + score.text + descriptionSeparator;
          });
        }

        if (journal.additionalScores && journal.additionalScores.length > 0) {
          journal.additionalScores.forEach(function(score) {
            description += score.key + ": " + score.value + descriptionSeparator;
          });
        }

        description = description.slice(0, -descriptionSeparator.length);
      }
      else if (tab == CONTACT_TAB_KEY) {
        if (journal.contact && journal.contact.length > 0) {
          description += journal.contact + descriptionSeparator;
        }

        if (journal.links && journal.links.length > 0) {
          journal.links.forEach(function(link) {
            description += link + descriptionSeparator;
          });
        }

        if (description.length === 0 && journal.publishingCompanyInfo) {
          description = journal.publishingCompanyInfo.name;
        }
        else {
          description = description.slice(0, -descriptionSeparator.length);
        }
      }

      if (description.length === 0) {
        return defaultDescription;
      }
      else {
        return generateMetaDescription(t, {"text": description})
      }
    }
    else {
      return defaultDescription;
    }
  }

}

export default withTranslation()(withRouter(JournalTabs));
export { JournalTabs as TestJournalTabs };
