import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class StatCounters extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      statistics: null,
      isLoading: true
    };
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.props.api.get(`/statistics/homepage`).then(response => this.setState({
      statistics: response.data,
      isLoading: false
    }));
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    const classes = this.state.statistics ? "front-page-stat" : "front-page-stat hidden";
    
    return (
      <>
        <Row>

          <h2 className="stats-header">{t('welcome.stats.openAccess.header')}</h2>

          <Col xs={6} sm={6} md={3} lg={3} xl={12} className={classes}>
            <div className="stats-number">{this.state.statistics ? this.state.statistics.articlesCount : 0}</div>
            <div className="stats-desc">{t('search.form.simple.stats.articles')}</div>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={12} className={classes}>
            <div className="stats-number">{this.state.statistics ? this.state.statistics.journalsCount : 0}</div>
            <div className="stats-desc">{t('search.form.simple.stats.journals')}</div>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={12} className={classes}>
            <div className="stats-number">{this.state.statistics ? this.state.statistics.booksCount : 0}</div>
            <div className="stats-desc">{t('search.form.simple.stats.books')}</div>
          </Col>

          <Col xs={6} sm={6} md={3} lg={3} xl={12} className={classes}>
            <div className="stats-number">{this.state.statistics ? calculateOnCcLicensePercent(this.state.statistics) : 0}%</div>
            <div className="stats-desc">{t('search.form.simple.stats.oncclicence')}</div>
          </Col>

        </Row>
      </>
    );
  }
}

export default withTranslation()(StatCounters);

// -------------------- PRIVATE --------------------

function calculateOnCcLicensePercent(statistics) {
  const totalNumberOfPublications = statistics.articlesCount + statistics.booksCount + statistics.chaptersCount;
  if (totalNumberOfPublications === 0) {
    return 0;
  }
  return parseInt((statistics.numberOfPublicationsOnCcLicense / (totalNumberOfPublications)) * 100);
}
