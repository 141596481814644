import React from "react";
import {sanitizeHighlightedContent} from './SearchResultUtils';

/**
 * Renders the html markup passed in as `content` prop as a paragraph with
 * highlighting. Fragments wrapped in `<em>` tags have a distinctive background,
 * and the rest of html markup is escaped.
 *
 * Tag to wrap the content in can be customized using `as` prop (the default
 * is `p`)
 *
 * @author sniewcza
 */
export default function HighlightedContent({content, as}) {
  const Tag = as ? as : 'p';
  return (<Tag className='with-highlighting' dangerouslySetInnerHTML={{__html: sanitizeHighlightedContent(content)}}/>);
}
