import React from "react";
import {withTranslation} from 'react-i18next';
import PubroSpinner from '../../common/PubroSpinner';
import Select from 'react-select';
import { getSelectAriaLiveMessages } from '../../common/utils';

/**
 * Renders a select field in which multiple languages can be selected.
 * Downloads languages from the REST service on initialization. The languages
 * are then reordered, so that a few selected languages appears on top of the
 * list. Sets the values of selected options back to formik `languages` field
 * values.
 *
 * Relies on react-select component. See: https://react-select.com
 *
 * @author sniewcza
 */
class LanguageForm extends React.Component {

  firstLanguages = [ 'POL', 'ENG', 'FRA', 'DEU', 'RUS' ];
  lastLanguages = [ 'MIS', 'MUL', 'UND', 'ZXX' ];

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      isLoading: true
    };
    this.processLanguages = this.processLanguages.bind(this);
    this.convertLanguageToOption = this.convertLanguageToOption.bind(this);
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    if (this.state.languages.length === 0) {
      this.props.api.get('/dictionaries/languages')
        .then(response => {
          this.setState({
            languages: response.data,
            isLoading: false
          });
        });
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const options = this.processLanguages(this.state.languages)
    return this.state.isLoading
      ? <PubroSpinner/>
      : <Select inputId={this.props.inputId}
                isMulti options={options}
                value={this.getSelectedOptions(options)}
                onChange={selectedOptions => this.updateValues(selectedOptions)}
                placeholder={''}
                noOptionsMessage={() => this.props.t('search.form.select.nooptions.message')}
                ariaLiveMessages={getSelectAriaLiveMessages(this.props.t)}
                menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>;
  }

  /**
   * Reorders languages to place a few selected options at the top of the list.
   * The rest is sorted by language label.
   */
  processLanguages(allLanguages) {
    const first = this.firstLanguages.map(this.convertLanguageToOption);
    const last = this.lastLanguages.map(this.convertLanguageToOption);

    const theRest = allLanguages.filter(lang => !this.firstLanguages.includes(lang) && !this.lastLanguages.includes(lang))
      .map(this.convertLanguageToOption)
      .sort((a, b) =>  a.label.localeCompare(b.label));

    return first.concat(theRest).concat(last);
  }

  convertLanguageToOption(language) {
    return {
      label: `${this.props.t('dictionary.language.' + language)} (${language})`,
      value: language
    };
  }

  getSelectedOptions(allOptions) {
    return allOptions.filter(language => this.props.formik.values.languages.includes(language.value))
  }

  updateValues(selectedOptions) {
    this.props.formik.setFieldValue('languages', selectedOptions ? selectedOptions.map(option => option.value) : [])
  }
}

export default withTranslation()(LanguageForm);
