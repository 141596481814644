import React from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import {LinkContainer} from "react-router-bootstrap";
import PubroSpinner from "../../common/PubroSpinner";
import {withTranslation} from "react-i18next";
import { Helmet } from 'react-helmet-async'
import { convertToIetfLanguageTag } from '../../common/LanguageUtils';
import { generateMetaDescription, generateSeparatedList } from "../../common/meta";
import { buildContributorFullName } from '../../common/utils';
import ProcessMath from '../../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../../common/utils';
import FormattedContent from '../common/FormattedContent';
import WbnAccessIcon from "../../common/WbnAccessIcon";
import { extractNamePartFromUrlQuery, extractPageFromUrlQuery } from '../../main-structure/library-utils';
import ResultListPagination from '../../common/ResultListPagination';
import queryString from 'query-string';

/**
 * Renders a list of all books assigned to given publishing company.
 * Id of the publishing company is determined from the 'publishingCompanyId'
 * path variable. The component contacts the backend to fetch book list items
 * when mounted.
 */
class PublishingCompanyBooks extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      books: null,
      totalElements: 0,
      pageSize: 100,
      page: extractPageFromUrlQuery(this.props.location.search),
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.updateBooks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        page: extractPageFromUrlQuery(this.props.location.search),
        isLoading: true
      }, this.updateBooks);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    if (this.state.isLoading) {
      return (
        <>
          {this.props.active && <Helmet meta={[{name: "description", content: ""}]}/>}
          <PubroSpinner/>
        </>
      );
    }

    if (this.state.books.length === 0) {
      return (
        <>
          {this.props.active && <Helmet meta={[{name: "description", content: t('book.list.empty')}]}/>}
          <Helmet meta={[{name: "description", content: ""}]}/>
          <Container><em>{t('book.list.empty')}</em></Container>
        </>
      );
    }

    const bookListElements = this.state.books.map(bookListItem => (
      <LinkContainer to={`/books/${bookListItem.id}`} key={bookListItem.id}>
        <ListGroup.Item action className={bookListItem.restricted ? "wbn-access-restricted" : ""}>
          {bookListItem.restricted && <WbnAccessIcon type={bookListItem.collectiveWork ? 'COLLECTIVE_WORK' : 'SIMPLE_BOOK'}/>}
          <h3 lang={convertToIetfLanguageTag(bookListItem.titleLanguage)}>
            <ProcessMath><FormattedContent as='span' content={transformAsciiMathDelimiter(bookListItem.title)} /></ProcessMath>
          </h3>
          {bookListItem.contributors.length > 0 &&
            <ul className="publications-list-authors">
              {bookListItem.contributors.map((author, index) => <li key={index}>{buildContributorFullName(author.firstName, author.lastName)}</li>)}
            </ul>
          }
        </ListGroup.Item>
      </LinkContainer>
    ));

    return (
      <>
        {this.props.active && <Helmet meta={[{name: "description", content: this.getMetaDescription(t)}]}/>}
        <Container>
          <ListGroup>
            {bookListElements}
          </ListGroup>
        </Container>
        {this.state.totalElements > this.state.pageSize && this.pagination()}
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  getMetaDescription(t) {
    const defaultDescription = generateMetaDescription(t, {});

    if (!this.state.isLoading && this.state.books) {
      let description = generateSeparatedList({"type": "books", "data": this.state.books})

      if (description.length === 0) {
        return defaultDescription;
      }
      else {
        return generateMetaDescription(t, {"text": description})
      }
    }
    else {
      return defaultDescription;
    }
  }
  
  pagination() {
    const linkFn = page => queryString.stringify({page: page});

    return (
      <Container>
        <Row>
          <Col>
            <ResultListPagination currentPage={this.state.page}
                                  linkFn={linkFn}
                                  totalResults={this.state.totalElements}
                                  pageSize={this.state.pageSize}/>
          </Col>
        </Row>
      </Container>
    );
  }

  updateBooks() {
    this.props.api.get(`/publishing-companies/${this.props.publishingCompanyId}/books`, {params: {page: this.state.page, pageSize: this.state.pageSize}})
      .then(response => this.setState({
        books: [].concat(response.data.content),
        totalElements: response.data.totalElements,
        isLoading: false
      }));
  }

}

export default withTranslation()(PublishingCompanyBooks);
