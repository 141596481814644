import React from 'react';
import BookCoverThumbnail from '../../../../publication/book/BookCoverThumbnail';
import { extractYear, joinWithYear } from '../common/SearchResultUtils';
import SearchResultScientificDisciplines from '../common/SearchResultScientificDisciplines';

/**
 * Renders sidebar part of simple book search result. Includes book cover,
 * release place and release year, publishing company name.
 *
 * @author sniewcza
 */
export default function CollectiveWorkSearchResultSidebar({document, urlQueryValues}) {
  return (
    <>
      <div>
        <BookCoverThumbnail coverId={document.coverId} bookTitle={document.mainTitle}/>
      </div>
      <div>
        <div className='search-result-release-place'>{document.releasePlace
          ? joinWithYear(document.releasePlace, document.publishedDate)
          : extractYear(document.publishedDate)}</div>
      </div>
      <div>
        <div className='search-result-publishing-company'>{document.publishingCompanyName}</div>
      </div>
      {document.scientificDisciplines.length > 0 && <SearchResultScientificDisciplines scientificDisciplines={document.scientificDisciplines}
                                                                                       urlQueryValues={urlQueryValues}/> }
    </>
  );
}
