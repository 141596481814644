import React from 'react';
import {Route, Switch} from "react-router-dom";
import PublishingCompanyDetails from "./publishing-company/PublishingCompanyDetails";
import { AVAILABLE_TABS as PUBLISHING_COMPANY_AVAILABLE_TABS} from "./publishing-company/PublishingCompanyDetails";
import Header from "./main-structure/Header";
import Welcome from "./main-structure/Welcome";
import Footer from "./main-structure/Footer";
import JournalDetails from "./journal/JournalDetails";
import { AVAILABLE_TABS as JOURNAL_AVAILABLE_TABS} from "./journal/JournalTabs";
import IssueDetails from "./issue/IssueDetails";
import ArticleDetails from "./publication/article/ArticleDetails";
import BookDetails from "./publication/book/BookDetails";
import ChapterDetails from "./publication/chapter/ChapterDetails";
import Contact from "./main-structure/Contact";
import Sitemap from "./main-structure/Sitemap";
import ErrorBoundary from './error/ErrorBoundary';
import NotFoundError from './error/NotFoundError';
import GeneralError from './error/GeneralError'
import withErrorHandling from './error/ErrorHandler';
import Search from './search/Search';
import DatabaseJournals from './journal/DatabaseJournals';
import ScrollToTop from './common/ScrollToTop';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Library from './main-structure/Library';
import { AVAILABLE_TABS as LIBRARY_AVAILABLE_TABS } from './main-structure/Library';
import Logout from "./main-structure/Logout";
import AccessDeniedError from "./error/AccessDeniedError";

/**
 * Main Pubro UI app component with routing configuration. Attached to root
 * element in index.js
 */
function App(props) {
  const { enableLinkTracking } = useMatomo()

  enableLinkTracking();

  return (
    <ErrorBoundary>
      <ScrollToTop/>
      <Header/>
      <div id="main-container">
        <Switch>
          <Route exact path={PUBLISHING_COMPANY_AVAILABLE_TABS.map(tabKey => '/publishing-companies/:publishingCompanyId' + tabKey)}
                 render={p => <PublishingCompanyDetails{...p} api={props.api}/>} />
          <Route exact path={LIBRARY_AVAILABLE_TABS} render={p => <Library{...p} api={props.api}/>} />
          <Route exact path={JOURNAL_AVAILABLE_TABS.map(tabKey => '/journals/:journalId' + tabKey)}
                 render={p => <JournalDetails{...p} api={props.api}/>} />
          <Route exact path="/databases/:database/journals" render={p => <DatabaseJournals{...p} api={props.api}/>} />
          <Route exact path="/issues/:issueId" render={p => <IssueDetails{...p} api={props.api}/>} />
          <Route exact path="/articles/:articleId" render={p => <ArticleDetails{...p} api={props.api}/>} />
          <Route exact path="/books/:bookId" render={p => <BookDetails{...p} api={props.api}/>} />
          <Route exact path="/chapters/:chapterId" render={p => <ChapterDetails{...p} api={props.api}/>} />
          <Route exact path="/search" render={p => <Search{...p} api={props.api}/>} />
          <Route exact path="/contact" render={p => <Contact{...p} api={props.api}/>} />
          <Route exact path="/sitemap" render={p => <Sitemap{...p} api={props.api}/>} />
          <Route exact path="/" render={p => <Welcome{...p} api={props.api}/>} />
          <Route exact path="/access-denied" component={AccessDeniedError} />
          <Route exact path="/error" component={GeneralError}/>
          <Route exact path="/logmeout" render={p => <Logout{...p} api={props.api} />} />
          <Route path="*" component={NotFoundError}/>
        </Switch>
      </div>
      <Footer api={props.api}/>
    </ErrorBoundary>
  );
}

export default withErrorHandling(App);
