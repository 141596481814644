export function createOrcidUrl(value) {
  return 'https://orcid.org/' + formatOrcid(value);
}

/**
 * Formats ORCID by adding dashes between the four four-digit groups, if the
 * value is sixteen characters long. Otherwise returns it as is.
 */
export function formatOrcid(value) {
  if (value.length !== 16) {
    return value;
  }
  const groups = value.match(/^(.{4})(.{4})(.{4})(.{4})$/);
  return `${groups[1]}-${groups[2]}-${groups[3]}-${groups[4]}`;
}
