import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import {useTranslation} from 'react-i18next';
import { getCurrentLanguageShortCode } from '../../common/LanguageUtils';
import { convertUrlQueryValuesToUrlQuery } from '../../search/form/AdvancedSearchUtils';
import { Link } from 'react-router-dom';
import AccessibleOverlayTrigger from '../../common/AccessibleOverlayTrigger';
import './ScientificDisciplineList.css';

/**
 * Renders a list of scientific disciplines. Expects the ScientificFieldVO[] to
 * be passed as 'scientificFields' prop.
 */
export default function ScientificDisciplineList({ scientificFields }) {
  const { i18n } = useTranslation();
  if (scientificFields && scientificFields.length > 0) {

    return (
      <Row>
        <Col>{scientificFields.map((field, index) =>
          <Col key={index} className="scientificField">
            <span className="scientificFieldHeader">{getScientificFieldName(field, i18n)}</span> 
            {(field.scientificDisciplines).map((scientificDiscipline, index) =>
              <ul key={index + field}>
                <li key={index + field + scientificDiscipline}>
                  {scientificDisciplineLink(scientificDiscipline, i18n)}
                </li>
              </ul>
            )}
          </Col>
        )}</Col>
      </Row>
    );
  }
}

// -------------------- PRIVATE --------------------

function scientificDisciplineLink(scientificDiscipline, i18n) {
  const tooltip = <Tooltip>{i18n.t('publication.details.scientificdisciplines.link.tooltip')}</Tooltip>;
  return (
    <AccessibleOverlayTrigger overlay={tooltip}>
      <Link to={'/search?' + convertUrlQueryValuesToUrlQuery({scientificDisciplines: [scientificDiscipline.id]})}>
        {getScientificDisciplineName(scientificDiscipline, i18n)}
      </Link>
    </AccessibleOverlayTrigger>
  );
}

function getScientificFieldName(scientificField, i18n) {
  return getCurrentLanguageShortCode(i18n.languages) === 'pl' ? scientificField.namePl : scientificField.nameEn;
}

function getScientificDisciplineName(scientificDiscipline, i18n) {
  return getCurrentLanguageShortCode(i18n.languages) === 'pl' ? scientificDiscipline.namePl : scientificDiscipline.nameEn;
}