import Spinner from 'react-bootstrap/Spinner'
import React from "react";
import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";

/**
 * Renders a spinner to indicate operation in progress.
 */
export default function PubroSpinner() {
  const { t } = useTranslation();
  return (
    <Container className='spinner-container'>
      <Spinner animation="border" role="status">
        <span className="sr-only">{t('spinner.sr-only')}</span>
      </Spinner>
    </Container>
  );
}
