import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async'
import { MAIN_CONTENT_ID } from '../main-structure/SkipToContentLinks';

export default function AccessDeniedError() {
  const {t} = useTranslation();
  return (
    <>
      <Helmet title={t('error.accessdenied.header')} meta={[{name: 'description', content: ''}]} />
      <Container id={MAIN_CONTENT_ID} as="main" className='access-denied-container'>
        <Row>
          <Col>
            <h1>{t('error.accessdenied.header')}</h1>
            <Button href='/'>{t('error.button.mainpage')}</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
