import React from "react";
import Button from 'react-bootstrap/Button';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import AccessibilityRequestLink from '../../../../common/AccessibilityRequestLink';

/**
 * Renders a horizontal list of links to full text files in form of small
 * buttons. This list differs from the one on publication details view: on the
 * details view PDF format is displayed above other formats. Here all buttons
 * are on the same level.
 *
 * @author sniewcza
 */
export default function SearchResultFullTextButtons({ document, api }) {
  const { t } = useTranslation();

  const hasPdf = document.fullTextFormats.filter(format => format === 'PDF').length > 0;
  const otherFormats = document.fullTextFormats.filter(format => format !== 'PDF');

  return (
    <>
      {hasPdf &&
        <Button href={buildFullTextUrl(document, 'PDF')}
                className='full-text-button' size='sm' variant='primary' key={'PDF'}>
          <FaDownload aria-label={t('icon.download.label')} /> {'PDF'}
          <span className="sr-only"> - {document.mainTitle}</span>
        </Button>
      }
      {otherFormats.length > 0 && otherFormats.map(format => (
        <Button href={buildFullTextUrl(document, format)}
                className='full-text-button' size='sm' variant='primary' key={format}>
          <FaDownload aria-label={t('icon.download.label')} /> {format}
          <span className="sr-only"> - {document.mainTitle}</span>
        </Button>
      ))}
      {document.fullTextFormats.length > 0 && <AccessibilityRequestLink api={api}/>}
    </>
  );
}

function resolveUrlPrefix(document) {
  switch (document.publicationType) {
    case 'ARTICLE':
      return '/articles/';
    case 'SIMPLE_BOOK':
    case 'COLLECTIVE_WORK':
      return '/books/';
    case 'CHAPTER':
      return '/chapters/';
    default:
      throw new Error('Unhandled publication type: ' + document.publicationType);
  }
}

function buildFullTextUrl(document, fullTextFormat) {
  return `${window.location.protocol}//${window.location.host}${resolveUrlPrefix(document)}${document.publicationId}.${fullTextFormat.toLowerCase()}`;
}

function publicationDetails(document) {
  switch (document.publicationType) {
    case 'ARTICLE':
      return {
        id: document.publicationId,
        mainTitle: {
          text: document.mainTitle
        },
        issueInfo: {
          id: document.issueId,
          year: document.issueYear,
          volume: document.issueVolume,
          number: document.issueNumber,
          journalInfo: {
            id: document.journalId,
            title: document.journalTitle,
            publishingCompanyInfo: {
              id: document.publishingCompanyId,
              name: document.publishingCompanyName
            }
          }
        },
        externalIdentifiers: [],
        scientificFields: document.scientificDisciplines
      };
    case 'SIMPLE_BOOK':
    case 'COLLECTIVE_WORK':
      return {
        id: document.publicationId,
        mainTitle: {
          text: document.mainTitle
        },
        publishingCompanyInfo: {
          id: document.publishingCompanyId,
          name: document.publishingCompanyName
        },
        externalIdentifiers: [],
        scientificFields: document.scientificDisciplines
      };
    case 'CHAPTER':
      return {
        id: document.publicationId,
        mainTitle: {
          text: document.mainTitle
        },
        bookInfo: {
          id: document.bookId,
          title: document.bookTitle,
          publishingCompanyInfo: {
            id: document.publishingCompanyId,
            name: document.publishingCompanyName
          }
        },
        externalIdentifiers: [],
        scientificFields: document.scientificDisciplines
      };
  }
}
