import React from "react";
import {Formik} from 'formik';
import Form from 'react-bootstrap/Form';
import {useTranslation} from 'react-i18next';
import {isValidDate, isValidDateRange} from './AdvancedSearchUtils';
import SimpleSearchForm from './SimpleSearchForm';
import AdvancedSearchPanel from './AdvancedSearchPanel';
import * as Yup from 'yup';

/**
 * Renders the advanced search form on the search page. Includes the general
 * search string input from simple search from, and expandable panel with
 * advanced search criteria fields.
 *
 * Changes the URL query on submit, and Search component detects that change and
 * sends a new search request.
 *
 * @author sniewcza
 */
function AdvancedSearch({initialValues, expand, onSubmit, activeFilters, api}) {
  const {t} = useTranslation();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} validationSchema={createValidationSchema(t)}>
      {( formik ) => (
        <Form noValidate onSubmit={formik.handleSubmit} className="advanced-search">
          <Form.Group>
            <SimpleSearchForm formik={formik}/>
          </Form.Group>
          <AdvancedSearchPanel formik={formik} expand={expand} activeFilters={activeFilters} api={api}/>
        </Form>
      )}
    </Formik>
  );
}

export default AdvancedSearch;

/**
 * Creates Yup validation schema for advanced search form.
 */
export function createValidationSchema(t) {
  const invalidDateFormatMessage = t('search.form.advanced.published-date.invalid');
  return Yup.object().shape({
    publishedDateFrom: Yup.string().test('date-test', invalidDateFormatMessage, isValidDate),
    publishedDateTo: Yup.string()
      .test('date-test', invalidDateFormatMessage, isValidDate)
      .when('publishedDateFrom', (from, schema) => {
        return schema.test('date-before', t('search.form.advanced.published-date.order.invalid'), to => isValidDateRange(from, to));
      }),
    publishingCompanies: Yup.array().max(30, t('search.form.advanced.pcj.publishing-companies.error.max')),
    journals: Yup.array().max(30, t('search.form.advanced.pcj.journals.error.max')),
  });
}
