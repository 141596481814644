import { buildCreativeWorkJsonLdPartFromPublication } from '../common/PublicationJsonLd';
import { canCreatUrlExternalId, createUrlExternalId } from '../common/ExternalIdentifierUtil';
import { sanitizeFormattedContent } from '../common/FormattedContentUtil';
import { emptyArrayToUndefined, getAbsoluteApiUrl } from '../../common/utils';

/**
 * Returns json-ld representation for the given article
 * using Book type of schema.org dictionary.
 * Function also takes list of chapters attached to the
 * given book for presenting it as 'hasPart' property of
 * Book.
 *
 * @see https://schema.org/Book
 * @see BookVO java class
 * @see ChapterListItemVO java class
 */
export function buildBookJsonLd(book, chapters) {

  const reactAppBaseUrl = window.location.origin;
  const restApiBaseUrl = getAbsoluteApiUrl();

  let bookJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Book',
    ...buildCreativeWorkJsonLdPartFromPublication(book),
    publisher: {
      '@type': 'Organization',
      name: book.publishingCompanyInfo.name,
      url: `${reactAppBaseUrl}/publishing-companies/${book.publishingCompanyInfo.id}`
    }
  }

  if (book.coverId) {
    bookJsonLd.image = `${restApiBaseUrl}/images/book-covers/${book.coverId}`;
  }

  if (book.pageCount) {
    bookJsonLd.numberOfPages = book.pageCount;
  }

  bookJsonLd.hasPart = emptyArrayToUndefined(chapters
                                    .map(chapter => buildChapterLdFromChapter(chapter, reactAppBaseUrl)));

  bookJsonLd.isbn = extractExternalIdentifierValueWithType(book, 'ISBN');

  bookJsonLd.sameAs = emptyArrayToUndefined(book.externalIdentifiers
                                    .filter(externalId => canCreatUrlExternalId(externalId))
                                    .map(externalId => createUrlExternalId(externalId)));

  return bookJsonLd;
}

function buildChapterLdFromChapter(chapter, reactAppBaseUrl) {
  return {
    '@type': 'Chapter',
    name: sanitizeFormattedContent(chapter.title),
    url: `${reactAppBaseUrl}/chapters/${chapter.id}`
  };
}

function extractExternalIdentifierValueWithType(book, idType) {
  const idWithType = book.externalIdentifiers.find(id => id.type === idType);
  return idWithType ? idWithType.text : undefined;
}

