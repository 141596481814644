import React from 'react';
import {Formik} from 'formik';
import Form from 'react-bootstrap/Form';
import {Link, withRouter} from 'react-router-dom';
import SimpleSearchForm from './SimpleSearchForm';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {convertFormValuesToUrlQuery} from './AdvancedSearchUtils';

/**
 * Renders a simple search form with one input field. Displayed on the welcome
 * page. Appends the search string as URL parameters and redirects to Search
 * component.
 */
function SimpleSearch(props) {
  const {t} = useTranslation();
  const onSubmit = (formValues, formik) => {
    props.history.push('/search?' + convertFormValuesToUrlQuery(formValues, 1));
  };

  return (
    <>
      <Row>
        <Col>
          <h1 className="font-weight-bold">{t('search.form.simple.title.main')}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="simple-search-subtitle">{t('search.form.simple.title.subtitle')}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Formik initialValues={props.initialValues} onSubmit={onSubmit} enableReinitialize={true}>
            {( formik ) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <SimpleSearchForm formik={formik}/>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to='/search?expand'>{t('search.form.simple.advanced-search.link')}</Link>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(SimpleSearch);
