import React from 'react';
import ArticleSearchResultListItem from './article/ArticleSearchResultListItem';
import ChapterSearchResultListItem from './chapter/ChapterSearchResultListItem';
import CollectiveWorkSearchResultListItem from './collective-work/CollectiveWorkSearchResultListItem';
import SimpleBookSearchResultListItem from './simple-book/SimpleBookSearchResultListItem';

const searchResultListItemComponents = {
  'ARTICLE': ArticleSearchResultListItem,
  'SIMPLE_BOOK': SimpleBookSearchResultListItem,
  'COLLECTIVE_WORK': CollectiveWorkSearchResultListItem,
  'CHAPTER': ChapterSearchResultListItem
};

/**
 * Renders a single result on the search result list. Takes the result to be
 * rendered as `document` property.
 *
 * @author sniewcza
 */
export default function SearchResultListItem({document, urlQueryValues, api}) {
  const Component = searchResultListItemComponents[document.publicationType];
  return <Component document={document} urlQueryValues={urlQueryValues} api={api}/>;
}
