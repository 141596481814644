import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PubroSpinner from '../common/PubroSpinner';
import SearchResultList from './result/SearchResultList';
import ResultListPagination from '../common/ResultListPagination';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import {
  convertFormValuesToUrlQueryValues,
  convertUrlQueryToUrlQueryValues,
  convertUrlQueryValuesToFormValues,
  convertUrlQueryValuesToSearchRequestWVO,
  convertUrlQueryValuesToUrlQuery, countActiveFilters
} from './form/AdvancedSearchUtils';
import SortForm from './form/SortForm';
import { withRouter } from 'react-router-dom';
import PageSizeForm from "./form/PageSizeForm";
import { MAIN_CONTENT_ID } from '../main-structure/SkipToContentLinks';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import { generateMetaTitle, generateMetaDescription } from "../common/meta";
import AdvancedSearch from './form/AdvancedSearch';

/**
 * Renders search page with search form and results. Parses URL parameters,
 * saves them as `urlQueryValues` state param, sends requests to the REST
 * endpoint and displays search results.
 *
 * This component, as well as multiple search-related components lower in the
 * component hierarchy, operates on three important terms:
 *
 *  - URL query - query string part of the url, containing search criteria,
 *    eg. `q=foo&researchDataLink=true`
 *
 *  - URL query values - URL query parsed to an object, eg.
 *    `{ q: 'foo', researchDataLink: true }`
 *
 *  - form values - object containing values for form components
 *
 * @author sniewcza
 */
class Search extends React.Component {

  static contextType = MatomoContext;

  pageSizes = ['20', '50', '100'];

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    const newUrlQueryValues = convertUrlQueryToUrlQueryValues(this.props.location.search);
    this.state = {
      urlQueryValues: newUrlQueryValues,
      pageSize: this.parsePageSize(newUrlQueryValues),
      searchResult: {
        totalResults: 0,
        documents: []
      },
      isLoading: true,
      licenses: [],
      scientificFields: []
    }
    this.onSortChange = this.onSortChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.setLicenseAndScientificDisciplines = this.setLicenseAndScientificDisciplines.bind(this);
  }

  // -------------------- LIFECYCLE --------------------

  /**
   * Sends search request to the backend when component did mount.
   */
  componentDidMount() {
    const licensePromise = this.props.api.get('/licenses');
    const scientificFieldsPromise = this.props.api.get('/scientific-fields');

    Promise.all([licensePromise, scientificFieldsPromise]).then(responses => {
      this.setState({
        licenses: responses[0].data,
        scientificDisciplines: [].concat.apply([], responses[1].data.map(field => field.scientificDisciplines))
      }, this.sendSearchRequest)
    })
  }

  /**
   * Updates state when the URL query string changes, and sends a new search
   * request, based on the new criteria.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        urlQueryValues: convertUrlQueryToUrlQueryValues(this.props.location.search)
      }, this.sendSearchRequest);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    return (
      <main id={MAIN_CONTENT_ID}>
        <Helmet title={ generateMetaTitle(this.props.t, {'label' : 'search.title'}) }
                meta={[{name: 'description', content: generateMetaDescription(this.props.t, {'label' : 'search.description'})}]}/>
        <Container>
          <Row>
            <Col>
              <AdvancedSearch initialValues={convertUrlQueryValuesToFormValues(this.state.urlQueryValues)}
                              expand={this.expandAdvancedSearchPanel()}
                              onSubmit={this.onFormSubmit} activeFilters={countActiveFilters(this.state.urlQueryValues)}
                              api={this.props.api}/>
            </Col>
          </Row>

          {this.state.isLoading && <Row>
            <Col>
              <PubroSpinner/>
            </Col>
          </Row>}
        </Container>
        {!this.state.isLoading && this.renderSearchResults()}
        {this.resultHasMultiplePages() && this.buildPaginationContainer()}
      </main>
    );
  }

  renderSearchResults() {
    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: this.props.t('search.title') });
    return <>
      <div className="search-results">
        <Container>
          <Row>
            <Col sm={6}>
              <h1>{this.props.t('search.result.list.header')}</h1>
              {this.state.searchResult.totalResults > 0 &&
              <div className='total-result-count'>{this.props.t('search.result.list.total.label') + " " + this.state.searchResult.totalResults}</div>
              }
            </Col>
            <Col sm={6} className="search-dropdown-filters text-right">
              <div className="search-dropdown-filter-container page-size-select">
                <label htmlFor="pageSizeSelect">{this.props.t('search.result.pagesize.select.label')}</label>
                <PageSizeForm inputId='pageSizeSelect' pageSizes={this.pageSizes} currentPageSize={this.state.pageSize} onChange={this.onPageSizeChange}/>
              </div>
              <div className="search-dropdown-filter-container sort-select">
                <label htmlFor="sortSelect">{this.props.t('search.result.sort.select.label')}</label>
                <SortForm inputId='sortSelect' urlQueryValues={this.state.urlQueryValues} onChange={this.onSortChange}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <SearchResultList searchResult={this.state.searchResult} urlQueryValues={this.state.urlQueryValues} api={this.props.api}/>
            </Col>
          </Row>
        </Container>
      </div>
    </>;
  }

  onSortChange(newSort) {
    const newUrlQueryValues = this.state.urlQueryValues;
    newUrlQueryValues.sortField = newSort.field;
    newUrlQueryValues.sortDirection = newSort.direction;
    this.resetPageAndResearchDataLink(newUrlQueryValues);
    this.setStateAndNavigate(newUrlQueryValues);
  }

  onPageSizeChange(newPageSize) {
    const newUrlQueryValues = this.state.urlQueryValues;
    newUrlQueryValues.pageSize = newPageSize;
    localStorage.setItem('searchPageSize', newPageSize);
    this.resetPageAndResearchDataLink(newUrlQueryValues);
    this.setStateAndNavigate(newUrlQueryValues);
  }

  resetPageAndResearchDataLink(newUrlQueryValues) {
    newUrlQueryValues.page = newUrlQueryValues.page === 1 ? undefined : newUrlQueryValues.page;
    newUrlQueryValues.researchDataLink = newUrlQueryValues.researchDataLink ? newUrlQueryValues.researchDataLink : undefined;
  }

  onFormSubmit(formValues) {
    const newUrlQueryValues = convertFormValuesToUrlQueryValues(formValues);
    newUrlQueryValues.sortField = this.state.urlQueryValues.sortField;
    newUrlQueryValues.sortDirection = this.state.urlQueryValues.sortDirection;
    newUrlQueryValues.pageSize = this.state.urlQueryValues.pageSize;
    this.navigate(newUrlQueryValues);
  }

  setStateAndNavigate(newUrlQueryValues) {
    this.setState({
      urlQueryValues: newUrlQueryValues,
      pageSize: this.parsePageSize(newUrlQueryValues)
    }, () => this.navigate(newUrlQueryValues))
  }

  navigate(urlQueryValues) {
    this.props.history.push('/search?' + convertUrlQueryValuesToUrlQuery(urlQueryValues));
  }

  /**
   * Sends a new search request and sets the result as state.
   */
  sendSearchRequest() {
    this.setState({isLoading: true});
    this.props.api.post(`/search`, convertUrlQueryValuesToSearchRequestWVO(this.state.urlQueryValues, this.state.pageSize))
      .then(response => {
        response.data.documents.forEach(document => this.setLicenseAndScientificDisciplines(document));
        this.setState({
          searchResult: response.data,
          isLoading: false
        });
      });
  }

  setLicenseAndScientificDisciplines(document) {
    document.license = this.state.licenses.find(license => license.id === document.licenseId);
    document.scientificDisciplines = this.state.scientificDisciplines.filter(discipline => document.scientificDisciplineIds.includes(discipline.id));
  }

  resultHasMultiplePages() {
    return this.state.searchResult.totalResults > this.state.pageSize;
  }

  buildPaginationContainer() {
    const currentUrlQueryValues = this.state.urlQueryValues;

    const linkFn = page => {
      const newUrlQueryValues = {
        ...currentUrlQueryValues,
        researchDataLink: currentUrlQueryValues.researchDataLink ? currentUrlQueryValues.researchDataLink : undefined,
        page: page
      }
      return convertUrlQueryValuesToUrlQuery(newUrlQueryValues, page);
    }

    return (
      <div className='pagination-search'>
        <Container>
          <Row>
            <Col>
              <ResultListPagination currentPage={this.state.urlQueryValues.page}
                                    linkFn={linkFn}
                                    totalResults={this.state.searchResult.totalResults}
                                    pageSize={this.state.pageSize}/>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  /**
   * Returns true if advanced search param should be expanded, false otherwise.
   * The panel should be expanded if `expand` has been explicitly included in
   * URL query ("advanced search" link from the main page), or when search
   * criteria include fields featured on the advanced search panel.
   */
  expandAdvancedSearchPanel() {
    const values = this.state.urlQueryValues;
    return !!(values.expand !== undefined
      || values.contributors
      || values.pubDateFrom
      || values.pubDateTo
      || values.researchDataLink
      || values.lang.length > 0
      || values.licenses.length > 0
      || values.scientificDisciplines.length > 0
      || values.publishingCompanies.length > 0
      || values.journals.length > 0
      || values.keywords.length > 0
    );
  }

  parsePageSize(newUrlQueryValues) {
    let localPageSize = localStorage.getItem('searchPageSize');

    if (this.pageSizes.includes(newUrlQueryValues.pageSize)) {
      return newUrlQueryValues.pageSize;
    }
    else if ((!newUrlQueryValues.page || newUrlQueryValues.page === 1) && this.pageSizes.includes(localPageSize)) {
      return localPageSize;
    }
    else {
      return this.pageSizes[0];
    }
  }
}

export default withTranslation()(withRouter(Search));
export { Search as TestSearch };
