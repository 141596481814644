import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Renders book cover if `coverId` prop is present, otherwise renders
 * a placeholder image.
 *
 * @author sniewcza
 */
export default function BookCoverThumbnail({coverId, bookTitle}) {
  const { t } = useTranslation();
  const alt = coverId ? (t('book.cover.alt') + ' ' + bookTitle) : t('book.cover.default.alt');

  const imageUrl = coverId ? `${process.env.REACT_APP_PUBRO_API_URL}/images/book-cover-thumbnails/${coverId}` : '/bookCoverThumbnailPlaceholder.png';
  return <img className={`search-result-cover-thumbnail ${coverId ? "custom-thumbnail" : ""}`} src={imageUrl} alt={alt} loading="lazy"/>
}
