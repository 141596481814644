import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FaSearch } from 'react-icons/fa';


export default function LibrarySearch({ namePart, onSubmit, placeholderKey, buttonTitleKey }) {
  const {t} = useTranslation();

  const initialFormValues = { namePart: namePart || '' };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit} enableReinitialize={true}>
      {( formik ) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <InputGroup className='mb-3 search-form'>
            <FormControl type='text' {...formik.getFieldProps('namePart')}
                         aria-label={t(placeholderKey)}
                         placeholder={t(placeholderKey)} />
            <InputGroup.Append>
              <Button type='submit' variant='primary' title={t(buttonTitleKey)}><FaSearch /></Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
}
