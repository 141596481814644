import {firstBy} from 'thenby';

/**
 * Returns sorted linked journals array.
 * Sorting is performed on according to rules below:
 *  - first by description placing entries with empty descriptions at the end
 *  - second by target journal title
 */
export function sortLinkedJournals(linkedJournals) {
  return linkedJournals.sort(
    firstBy(connectionDescriptionCompare())
    .thenBy((linkedJournal) => linkedJournal.targetJournal.mainTitle));
}

/**
 * Compares linked journals by connection description, puts empty entries at the end of the list.
 */
function connectionDescriptionCompare() {
  return (linkedJournal1, linkedJournal2) => {
    if (!linkedJournal1.description && !linkedJournal2.description) {
      return 0;
    }
    if (!linkedJournal1.description) {
      return 1;
    }
    if (!linkedJournal2.description) {
      return -1;
    }
    return linkedJournal1.description.localeCompare(linkedJournal2.description);
  };
}
