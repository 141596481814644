import React from 'react';
import PubroSpinner from '../../common/PubroSpinner';
import Card from 'react-bootstrap/Card';
import ExternalIdentifierList from './ExternalIdentifierList';
import ScientificDisciplineList from './ScientificDisciplineList';
import PublicationAttributeList from './PublicationAttributeList';
import DateList from './DateList';
import ResearchDataLinkDetails from './ResearchDataLinkDetails';
import PublicationLanguageDetails from './PublicationLanguageDetails';

/**
 * Renders publication details for book publications,
 * displayed on the right side. Expects the following props:
 * - publication
 */
export default function PublicationCommonDetailsSidePanel({publication, externalIdLabelPrefix}) {

  if (!publication) {
    return <PubroSpinner />
  }

  return (
    <>
      {publication.language && <Card className='section'>
        <Card.Body>
          <PublicationLanguageDetails language={publication.language} />
        </Card.Body>
      </Card>}
      <Card className='section'>
        <Card.Body>
          {publication.dates.length > 0 && <DateList dates={publication.dates} />}
        </Card.Body>
      </Card>
      {publication.externalIdentifiers.length > 0 && <Card className='section'>
        <Card.Body>
          <ExternalIdentifierList externalIdentifiers={publication.externalIdentifiers}
            labelPrefix={externalIdLabelPrefix} />
        </Card.Body>
      </Card>}
      <Card className='section'>
        <Card.Body>
          {(publication.reviewedPublication || publication.postConferencePublication || publication.reprintPublication || publication.translationPublication) &&
            <PublicationAttributeList reviewedPublication={publication.reviewedPublication}
              reprintPublication={publication.reprintPublication}
              translationPublication={publication.translationPublication}
              postConferencePublication={publication.postConferencePublication}
              conferenceName={publication.conferenceName} />}
          {publication.scientificFields.length > 0 && <ScientificDisciplineList scientificFields={publication.scientificFields} />}
          {publication.researchDataLink && <ResearchDataLinkDetails researchDataLink={publication.researchDataLink} publicationTitle={publication.mainTitle.text} />}
        </Card.Body>
      </Card>
    </>
  );
}
