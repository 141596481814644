import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProcessMath from '../../common/ProcessMath';
import FormattedContent from './FormattedContent';
import { transformAsciiMathDelimiter } from '../../common/utils';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import AccessibleAccordionToggle from '../../common/AccessibleAccordionToggle';
import Container from 'react-bootstrap/Container';

/**
 * Renders bibliographic entries in form of a list. Expects the entries to be
 * passed as 'bibEntries' prop.
 */
export default function BibEntryList({ bibEntries }) {
  const { t } = useTranslation();
  if (bibEntries && bibEntries.length > 0) {
    return (
      <Row>
        <Col>
          <Accordion defaultActiveKey="bibliography">
            <h2 className="sr-only">{t('publication.details.bibliography.header')}</h2>
            <Card className='section'>
              <AccessibleAccordionToggle eventKey="bibliography">
                {t('publication.details.bibliography.header')}
              </AccessibleAccordionToggle>
              <Accordion.Collapse eventKey="bibliography">
                <Card.Body>
                  <Container>
                    <ol>{bibEntries.map((entry, index) =>
                      <li key={index}>
                        <ProcessMath>
                          <FormattedContent as='span' content={transformAsciiMathDelimiter(entry)} />
                        </ProcessMath>
                      </li>)}
                    </ol>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    );
  }
}
