import React from 'react';
import PubroSpinner from '../../common/PubroSpinner';
import AsyncSelect from 'react-select/async';
import './PublishingCompanyAndJournalOption.css';
import PublishingCompanyAndJournalOption from './PublishingCompanyAndJournalOption';
import './PublishingCompanyAndJournalForm.css';
import {withTranslation} from 'react-i18next';
import { getSelectAriaLiveMessages } from '../../common/utils';

/**
 * Renders a select field in which multiple publishing companies and journals
 * can be selected. Downloads PublishingCompanyInfoVO and JournalInfoVO for
 * specified identifiers from REST endpoints on initialization. When the user
 * starts typing, sends requests to REST API to download options.
 * Sets the values of selected options back to formik `publishingCompanies` and
 * `journals` field values.
 *
 * Note: Suggestion options will not include journals if search scope is limited
 * to books.
 *
 * Relies on react-select component. See: https://react-select.com
 *
 * @author sniewcza
 */
class PublishingCompanyAndJournalForm extends React.Component {

  customStyles = {
    control: (base, state) => {
      return {
        ...base,
        borderColor: state.selectProps.error ? '#d43f21' : '#d8d8d8',
        menuPortal: base => ({...base, zIndex: 9999})
      };
    },
  };

  minInputValueLength = 3;

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.loadOptions = this.loadOptions.bind(this);
    this.isOptionDisabled = this.isOptionDisabled.bind(this);
    this.noOptionsMessage = this.noOptionsMessage.bind(this);
    this.state = {
      value: [],
      isLoading: this.props.formik.values.publishingCompanies.length > 0 || this.props.formik.values.journals.length > 0,
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    const pubCompPromise = this.props.formik.values.publishingCompanies.length > 0
      ? this.props.api.get('/publishing-companies/info?publishingCompanyIds=' + this.props.formik.values.publishingCompanies.join(','))
      : Promise.resolve({data: []});
    const journalPromise = this.props.formik.values.journals.length > 0
      ? this.props.api.get('/journals/info?journalIds=' + this.props.formik.values.journals.join(','))
      : Promise.resolve({data: []});

    Promise.all([pubCompPromise, journalPromise]).then(response => {
      const initialPubCoOptions = response[0].data.map(convertPublishingCompanyInfoToOption);
      const initialJournalOptions = response[1].data.map(convertJournalInfoToOption);
      this.setState({
        value: initialPubCoOptions.concat(initialJournalOptions),
        isLoading: false
      });
    });
  }

  /**
   * Resetting the whole form triggered from AdvancedSearchForm clears the
   * related formik values, but this is not enough to reset react-select.
   * To reset the select, we store the selected options as `this.state.value`,
   * and replace it with empty array when formik state does not match it
   * anymore.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.needsReset()) {
      this.reset();
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    return this.state.isLoading
      ? <PubroSpinner/>
      : (
        <>
          <AsyncSelect inputId={this.props.inputId}
                       isMulti
                       value={this.state.value}
                       loadOptions={this.loadOptions}
                       onChange={selectedOptions => this.updateValues(selectedOptions)}
                       placeholder={''}
                       openMenuOnClick={false}
                       hideSelectedOptions={false}
                       isOptionSelected={option => false}
                       isOptionDisabled={this.isOptionDisabled}
                       loadingMessage={() => this.props.t('search.form.select.async.loading.message')}
                       noOptionsMessage={this.noOptionsMessage}
                       ariaLiveMessages={getSelectAriaLiveMessages(this.props.t)}
                       menuPortalTarget={document.body}
                       components={{DropdownIndicator: () => null, IndicatorSeparator: () => null, Option: PublishingCompanyAndJournalOption}}
                       error={this.hasError()}
                       styles={this.customStyles}/>
          {this.hasPublishingCompanyError()
            && <div className='validation-error'>{this.props.t('search.form.advanced.pcj.publishing-companies.error.max')}</div>}
          {this.hasJournalError()
            && <div className='validation-error'>{this.props.t('search.form.advanced.pcj.journals.error.max')}</div>}
        </>
      );
  }

  isOptionDisabled(option) {
    return (option.type === 'publishing-company' && this.props.formik.values.publishingCompanies.includes(option.id))
      || (option.type === 'journal' && this.props.formik.values.journals.includes(option.id))
  }

  loadOptions(inputValue) {
    if (inputValue && inputValue.length >= this.minInputValueLength) {
      const pubCoPromise = this.props.api.get('/publishing-companies/info/autocomplete?namePart=' + encodeURIComponent(inputValue));
      const journalPromise = this.props.formik.values.scope === 'books'
        ? Promise.resolve({data: []})
        : this.props.api.get('/journals/info/autocomplete?namePart=' + encodeURIComponent(inputValue));
      return Promise.all([pubCoPromise, journalPromise])
        .then(response => convertResponseDataToOptions(response[0].data, response[1].data));
    }
    return Promise.resolve([]);
  }

  updateValues(selectedOptions) {
    const publishingCompanyIds = selectedOptions ? selectedOptions.filter(val => val.type === 'publishing-company').map(val => val.id) : [];
    const journalIds = selectedOptions ? selectedOptions.filter(val => val.type === 'journal').map(val => val.id) : [];
    this.props.formik.setFieldValue('publishingCompanies', publishingCompanyIds, true);
    this.props.formik.setFieldValue('journals', journalIds, true);
    this.setState({ value: selectedOptions });
  }

  hasError() {
    return this.hasPublishingCompanyError() || this.hasJournalError();
  }

  hasPublishingCompanyError() {
    return this.props.formik.touched.publishingCompanies
      && this.props.formik.errors.publishingCompanies
      && this.props.formik.errors.publishingCompanies.length > 0;
  }

  hasJournalError() {
    return this.props.formik.touched.journals
      && this.props.formik.errors.journals
      && this.props.formik.errors.journals.length > 0;
  }

  noOptionsMessage(input) {
    return input.inputValue && input.inputValue.length < this.minInputValueLength
      ? this.props.t('search.form.advanced.pcj.minimum.length.message')
      : this.props.t('search.form.select.nooptions.message');
  }

  needsReset() {
    return this.props.formik.values.publishingCompanies.length === 0
      && this.props.formik.values.journals.length === 0
      && this.state.value
      && this.state.value.length > 0;
  }

  reset() {
    this.setState({ value: [] });
  }
}

export default withTranslation()(PublishingCompanyAndJournalForm);

/*
 * Since PublishingCompanyAndJournalForm component now requires translation
 * tools, it has been wrapped in withTranslation higher order component, and
 * some tests based on new PublishingCompanyAndJournalForm(...) would no longer
 * pass due to illegal hook use outside of function component. A workaround for
 * this is a double export: the default export is still withTranslation()(...),
 * and this is the additional export with an alias, for tests only.
 * The workaround is intended to be temporary, until we figure out an actual
 * solution.
 */
export { PublishingCompanyAndJournalForm as BasePublishingCompanyAndJournalForm };

// -------------------- PRIVATE --------------------

function convertResponseDataToOptions(publishingCompanies, journals) {
  const options = [];

  const allPublishingCompanies = [].concat(publishingCompanies);
  journals.map(journalInfo => journalInfo.publishingCompanyInfo).forEach(pubCompany => {
    if (allPublishingCompanies.filter(pc => pc.id === pubCompany.id).length === 0) {
      allPublishingCompanies.push(pubCompany);
    }
  });

  allPublishingCompanies.sort((a, b) =>  a.name.localeCompare(b.name, undefined, { ignorePunctuation: true }))
    .forEach(pubCompany => {
      options.push(convertPublishingCompanyInfoToOption(pubCompany));
      journals.filter(journal => journal.publishingCompanyInfo.id === pubCompany.id)
        .forEach(journal => options.push(convertJournalInfoToOption(journal)));
    });

  return options;
}

function convertJournalInfoToOption(journalInfo) {
  return {
    label: journalInfo.title,
    value: `journal-${journalInfo.id}`,
    type: 'journal',
    id: journalInfo.id,
    issn: journalInfo.issn,
    eissn: journalInfo.eissn
  }
}

function convertPublishingCompanyInfoToOption(publishingCompanyInfo) {
  return {
    label: publishingCompanyInfo.name,
    value: `publishing-company-${publishingCompanyInfo.id}`,
    type: 'publishing-company',
    id: publishingCompanyInfo.id
  }
}
