import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import PubroSpinner from "../common/PubroSpinner";
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

class JournalDatabaseList extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      databases: null,
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.props.api.get('/journal-databases')
      .then(response => this.setState({
        databases: response.data,
        isLoading: false
      }));
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    if (this.state.databases) {

      if (this.state.databases.length === 0) {
        return <Container>{t('journal.database.list.empty')}</Container>;
      }

      return (
        <Container>
            <Row>
              {this.state.databases.map(database => (
                <Col lg={6} key={database}>
                  <Link to={'/databases/' + database + '/journals'} className="card-block-link">
                    <Card>
                      <Card.Body>
                          <h3>{t('journal.database.name.' + database)}</h3>
                          <p>{t('journal.database.description.' + database)}</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
        </Container>
      );
    } else {
      return <PubroSpinner/>
    }
  }
}

export default withTranslation()(JournalDatabaseList);
