import React from 'react';
import { useTranslation } from 'react-i18next';
import ContributorsDetails from './ContributorsDetails';
import './ContributorList.css';
import Col from 'react-bootstrap/Col';

/**
 * Renders a horizontal list of contributors (first name, last name, affiliation
 * indices).  Expects the following props:
 *  - contributors
 *  - dictionaryRolePrefix
 */
export default function ContributorList({ contributors }) {

  const { t } = useTranslation();
  if (contributors && contributors.length > 0) {
    return (getContributorsRole().map((role, index) =>
      <Col className="roleLabel" key={index + role} >{t('dictionary.contributor_role.' + role.toLowerCase() + 's') + ' '}
        <ContributorsDetails contributors={getContributorsByRole(role)} />
        {isAnyContributorCorresponding(getContributorsByRole(role)) &&
          <p className="corresponding" >{t('details.publication.contributors.corresponding.asterisk')
            + t('dictionary.contributor_role.' + role.toLowerCase() + '.corresponding')}</p>
        }
      </Col>
    ))
  }

  // -------------------- PRIVATE --------------------

  function getContributorsByRole(contributorRole) {
    return contributors.filter(contributor => contributor.role === contributorRole);
  }

  function getContributorsRole() {
    return Array.from(new Set(contributors.map(contributor => contributor.role)));
  }

  function isAnyContributorCorresponding(contributorsByRole) {
    return contributorsByRole
      .filter(contributor => contributor.corresponding === true).length > 0;
  }
}
