import React from "react";
import {css} from 'emotion';
import './PublishingCompanyAndJournalOption.css';
import JournalOption from './JournalOption';

/**
 * Custom select option for rendering publishing companies and journals on
 * publishing company & journal select field. Renders an ordinary span for
 * publishing companies and JournalOption for journals.
 *
 * @see JournalOption
 * @author sniewcza
 */
export default function PublishingCompanyAndJournalOption(props) {
  return (
    <div style={props.getStyles('option', props)}
         className={props.cx(
           css(props.getStyles("option", props)),
           {
             option: true,
             "option--is-disabled": props.isDisabled,
             "option--is-focused": props.isFocused,
             "option--is-selected": props.isSelected
           },
           props.className
         )}
         {...props.innerProps}
         key={`${props.data.type}-${props.data.id}`}>
      {props.data.type === 'publishing-company'
        ? <span>{props.data.label}</span>
        : <JournalOption journal={props.data}/>}
    </div>
  );
}
