import queryString from 'query-string';
import moment from 'moment';

const scopeMapping = {
  'journals': ['ARTICLE'],
  'books': ['SIMPLE_BOOK', 'COLLECTIVE_WORK', 'CHAPTER']
};

const datePattern = /^(\d{1,4}|\d{1,4}-\d{1,2}|\d{1,4}-\d{1,2}-\d{1,2})$/;
const whitespaceOnlyPattern = /^\s*$/;

// -------------------- LOGIC --------------------

/**
 * Converts advanced search form values to URL query.
 */
export function convertFormValuesToUrlQuery(formValues, pageNumber) {
  const urlQueryValues = {
    q: formValues.q ? encodeURIComponent(formValues.q) : undefined,
    scope: formValues.scope === 'journals' || formValues.scope === 'books' ? formValues.scope : undefined,
    contributors: formValues.contributors ? encodeURIComponent(formValues.contributors) : undefined,
    researchDataLink: formValues.researchDataLink ? formValues.researchDataLink : undefined,
    pubDateFrom: formValues.publishedDateFrom ? formatDate(formValues.publishedDateFrom) : undefined,
    pubDateTo: formValues.publishedDateTo ? formatDate(formValues.publishedDateTo) : undefined,
    lang: formValues.languages,
    scientificDisciplines: formValues.scientificDisciplines && formValues.scientificDisciplines.length > 0 ? formValues.scientificDisciplines : undefined,
    licenses: formValues.licenses && formValues.licenses.length > 0 ? formValues.licenses : undefined,
    publishingCompanies: formValues.publishingCompanies && formValues.publishingCompanies.length > 0 ? formValues.publishingCompanies : undefined,
    journals: formValues.journals && formValues.journals.length > 0 ? formValues.journals : undefined,
    keywords: formValues.keywords && formValues.keywords.length > 0 ? formValues.keywords.map(encodeURIComponent) : undefined,
    page: pageNumber
  };
  return queryString.stringify(urlQueryValues, {arrayFormat: 'comma'});
}

export function convertFormValuesToUrlQueryValues(formValues) {
  let licenses;

  if (formValues.licenses && formValues.licenses.length > 0) {
    licenses = new Set();
    for (const license of formValues.licenses) {
      if (typeof license === "string") {
        for(const entry of license.split(",")) {
          licenses.add(parseInt(entry, 10));
        }
      }
      else {
        licenses.add(license);
      }
    }
    licenses = Array.from(licenses);
  }

  return {
    q: formValues.q ? encodeURIComponent(formValues.q) : undefined,
    scope: formValues.scope === 'journals' || formValues.scope === 'books' ? formValues.scope : undefined,
    contributors: formValues.contributors ? encodeURIComponent(formValues.contributors) : undefined,
    researchDataLink: formValues.researchDataLink ? formValues.researchDataLink : undefined,
    pubDateFrom: formValues.publishedDateFrom ? formatDate(formValues.publishedDateFrom) : undefined,
    pubDateTo: formValues.publishedDateTo ? formatDate(formValues.publishedDateTo) : undefined,
    lang: formValues.languages,
    scientificDisciplines: formValues.scientificDisciplines && formValues.scientificDisciplines.length > 0 ? formValues.scientificDisciplines : undefined,
    licenses: licenses,
    publishingCompanies: formValues.publishingCompanies && formValues.publishingCompanies.length > 0 ? formValues.publishingCompanies : undefined,
    journals: formValues.journals && formValues.journals.length > 0 ? formValues.journals : undefined,
    keywords: formValues.keywords && formValues.keywords.length > 0 ? formValues.keywords.map(encodeURIComponent) : undefined
  };
}

/**
 * Converts parsed URL query values to advanced search form values.
 */
export function convertUrlQueryValuesToFormValues(urlQueryValues) {
  return {
    q: urlQueryValues.q ? urlQueryValues.q : '',
    scope: urlQueryValues.scope ? urlQueryValues.scope : null,
    contributors: urlQueryValues.contributors ? urlQueryValues.contributors : '',
    researchDataLink: urlQueryValues.researchDataLink ? urlQueryValues.researchDataLink : false,
    publishedDateFrom: urlQueryValues.pubDateFrom ? urlQueryValues.pubDateFrom : '',
    publishedDateTo: urlQueryValues.pubDateTo ? urlQueryValues.pubDateTo : '',
    languages: urlQueryValues.lang ? urlQueryValues.lang : [],
    scientificDisciplines: urlQueryValues.scientificDisciplines ? urlQueryValues.scientificDisciplines : [],
    licenses: urlQueryValues.licenses ? urlQueryValues.licenses : [],
    publishingCompanies: urlQueryValues.publishingCompanies ? urlQueryValues.publishingCompanies : [],
    journals: urlQueryValues.journals ? urlQueryValues.journals : [],
    keywords: urlQueryValues.keywords ? urlQueryValues.keywords : []
  }
}

/**
 * Converts URL query values to URL query.
 */
export function convertUrlQueryValuesToUrlQuery(urlQueryValues) {
  return queryString.stringify(urlQueryValues, {arrayFormat: 'comma'});
}

/**
 * Parses url query (eg `q=foo&p=bar`) to an object, eg `{q: 'foo', p: 'bar'}`.
 */
export function convertUrlQueryToUrlQueryValues(urlQuery) {
  const values = queryString.parse(urlQuery);
  values.q = values.q ? decodeURIComponent(values.q) : undefined;
  values.contributors = values.contributors ? decodeURIComponent(values.contributors) : undefined;
  values.page = parsePage(values.page);
  values.scope = parseScope(values.scope);
  values.researchDataLink = !!(values.researchDataLink && values.researchDataLink === 'true');
  values.lang = values.lang ? values.lang.split(',').map(v => v.toUpperCase()) : [];
  values.scientificDisciplines = values.scientificDisciplines ? parseIntegers(values.scientificDisciplines) : [];
  values.licenses = values.licenses ? parseIntegers(values.licenses) : [];
  values.publishingCompanies = values.publishingCompanies ? parseIntegers(values.publishingCompanies) : [];
  values.journals = values.journals ? parseIntegers(values.journals) : [];
  values.pubDateFrom = parseDateUrlParam(values.pubDateFrom);
  values.pubDateTo = parseDateUrlParam(values.pubDateTo);
  if (!isValidDateRange(values.pubDateFrom, values.pubDateTo)) {
    values.pubDateFrom = '';
    values.pubDateTo = '';
  }
  values.keywords = values.keywords ? values.keywords.split(',').map(decodeURIComponent) : [];
  return values;
}

/**
 * Builds PublicationSearchRequestWVO object based on the current component
 * state. Uses searchCriteria.q as a general search string, since we want to
 * put the form values in URL query and `q` seems to be the common name for
 * search string (eg. https://google.com/search?q=test,
 * https://bing.com/search?q=test, https://duckduckgo.com/?q=test, etc).
 */
export function convertUrlQueryValuesToSearchRequestWVO(urlQueryValues, pageSize) {
  return {
    searchCriteria: {
      generalSearchString: urlQueryValues.q,
      publicationTypes: convertScopeToPublicationTypes(urlQueryValues.scope),
      contributors: urlQueryValues.contributors,
      researchDataLink: urlQueryValues.researchDataLink ? urlQueryValues.researchDataLink : false,
      publishedDateFrom: urlQueryValues.pubDateFrom,
      publishedDateTo: urlQueryValues.pubDateTo,
      languages: urlQueryValues.lang,
      scientificDisciplines: urlQueryValues.scientificDisciplines,
      licenses: [...new Set(urlQueryValues.licenses)],
      publishingCompanies: urlQueryValues.publishingCompanies,
      journals: urlQueryValues.journals,
      keywords: urlQueryValues.keywords
    },
    paginationCriteria: {
      pageNumber: urlQueryValues.page ? urlQueryValues.page : 1,
      pageSize: pageSize,
      sortingCriteria: {
        fieldName: isValidSortField(urlQueryValues.sortField)
          ? urlQueryValues.sortField
          : isBlank(urlQueryValues) ? 'publishedDate' : 'score',
        direction: isValidSortDirection(urlQueryValues.sortDirection)
          ? urlQueryValues.sortDirection
          : 'DESC'
      }
    }
  };
}

function isValidSortField(value) {
  return value === 'publishedDate' || value === 'score';
}

function isValidSortDirection(value) {
  return value === 'ASC' || value === 'DESC';
}

/**
 * Returns true if date is blank or when it matches the pattern and is an
 * actual, correct date. Returns false otherwise.
 */
export function isValidDate(date) {
  if (date) {
    return datePattern.test(date) && moment(date, 'YYYY-MM-DD').isValid();
  }
  return true;
}

/**
 * Validates date range. Only checks the range correctness when both parameters
 * are not blank and are valid dates, otherwise range validation error could
 * show up when one of the dates does not match the date pattern.
 *
 * Returns true when start date is same or before end date, false otherwise.
 * Accepts shortened dates as part of the range, eg.:
 *
 *   2000-04 - 2000 => from the beginning of April 2000 until the end of 2000
 *   2000 - 2000-04 => from the beginning of 2000 until the end of April 2000
 *
 * Ranges with equal values are valid as well:
 *
 *   2000 - 2000 => the whole 2000
 *   2000-04 - 2000-04 => the whole April 2000
 */
export function isValidDateRange(start, end) {
  if (start && end && isValidDate(start) && isValidDate(end)) {
    const startMoment = moment(formatDate(start), 'YYYY-MM-DD');
    const formattedEnd = formatDate(end);
    const endMoment = moment(formattedEnd, 'YYYY-MM-DD');
    const granularity = getGranularity(formattedEnd);
    return startMoment.isSameOrBefore(endMoment, granularity);
  }
  return true;
}

export function isBlank(urlQueryValues) {
  return (!urlQueryValues.q || whitespaceOnlyPattern.test(urlQueryValues.q))
    && (!urlQueryValues.contributors || whitespaceOnlyPattern.test(urlQueryValues.contributors))
}

const activeFilterClassifiers = urlQueryValues => [
  !!urlQueryValues.contributors,
  (!!urlQueryValues.pubDateFrom || !!urlQueryValues.pubDateTo),
  urlQueryValues.researchDataLink,
  urlQueryValues.lang.length > 0,
  urlQueryValues.scientificDisciplines.length > 0,
  urlQueryValues.licenses.length > 0,
  (urlQueryValues.publishingCompanies.length > 0 || urlQueryValues.journals.length > 0),
  urlQueryValues.keywords.length > 0,
];

/**
 * Returns count of filters applied to current search results via the advanced
 * search form. General search string and search scope are excluded from the
 * count.
 *
 * @param urlQueryValues current search criteria
 * @returns number of active filters - zero or more
 */
export function countActiveFilters(urlQueryValues) {
  return activeFilterClassifiers(urlQueryValues).reduce((acc, curr) => acc + curr);
}

// -------------------- PRIVATE --------------------

function parsePage(pageString) {
  const page = parseInt(pageString, 10);
  return isNaN(page) || page < 1 ? 1 : page;
}

function convertScopeToPublicationTypes(scope) {
  return Object.keys(scopeMapping).includes(scope) ? scopeMapping[scope] : [];
}

function parseScope(scope) {
  return scope === 'journals' || scope === 'books' ? scope : undefined;
}

function parseIntegers(valueStr) {
  return valueStr.split(',').filter(id => /\d*/.test(id)).map(id => parseInt(id, 10))
}

/**
 * Takes date value from URL param and checks if it's a valid date. If so,
 * returns the value as is. Returns empty string as the default form value
 * otherwise.
 */
function parseDateUrlParam(date) {
  return isValidDate(date) ? date : '';
}

/**
 * Takes valid date values and formats them using YYYY, YYYY-MM or YYYY-MM-DD
 * format, which basically comes to adding leading zeroes where necessary.
 *
 * Note: Moment will format `01` as `2001`, so we explicitly append `00` to
 * dates with two-digit year values to stay consistent with single and
 * triple-digit year formatting.
 *
 *  `1-1-1`    =>  `0001-01-01`
 *  `1-1`      =>  `0001-01`
 *  `1`        =>  `0001`
 *
 *  `01-1-1`   =>  `0001-01-01`
 *  `01-1`     =>  `0001-01`
 *  `01`       =>  `0001`
 *
 *  `001-1-1`  =>  `0001-01-01`
 *  `001-1`    =>  `0001-01`
 *  `001`      =>  `0001`
 */
function formatDate(date) {
  const value = /^(\d{2}|\d{2}-\d{1,2}|\d{2}-\d{1,2}-\d{1,2})$/.test(date) ? '00' + date : date;
  const fullDateFormat = 'YYYY-MM-DD';
  if (/^\d{1,4}$/.test(value)) {
    return moment(value, fullDateFormat).format('YYYY');
  }
  if (/^\d{1,4}-\d{1,2}$/.test(value)) {
    return moment(value, fullDateFormat).format('YYYY-MM');
  }
  return moment(value, fullDateFormat).format(fullDateFormat);
}

/**
 * Returns granularity for date comparison. We're comparing with:
 *  - year granularity when end date consists of year only
 *  - month granularity when end date consists of year and month
 *  - day granularity when end date is a full year-month-day date
 */
function getGranularity(end) {
  if (/^\d{4}$/.test(end)) {
    return 'year';
  }
  if (/^\d{4}-\d{2}$/.test(end)) {
    return 'month'
  }
  return 'day';
}

