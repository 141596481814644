import React from "react";
import {LinkContainer} from 'react-router-bootstrap';

/**
 * Wraps links to specific pages in LinkContainer to make them both
 * a traditional link (`<a href=...`), and a React router link. Expects the
 * following to be passed as props:
 *  - page - page to link to
 *  - linkFn - function (a closure) to generate links with
 *  - children - child elements
 *
 *  Usage samples:
 *
 *  ```
 *  <PaginationLink key={'first'}
 *                  linkFn={linkFn}
 *                  page={1}>
 *    <Pagination.First/>
 *  </PaginationLink>
 *  ```
 *  ```
 *  <PaginationLink key={currentPage - i}
 *                  linkFn={linkFn}
 *                  page={currentPage - i}>
 *    <Pagination.Item>{currentPage - i}</Pagination.Item>
 *  </PaginationLink>
 *  ```
 */
export default function PaginationLink({linkFn, page, children}) {
  return (
    <LinkContainer to={'?' + linkFn(page)}>
      {children}
    </LinkContainer>
  );
}
