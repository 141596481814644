import React from "react";
import HighlightedContent from './HighlightedContent';

/**
 * Renders full text snippet with highlighting.
 *
 * @author sniewcza
 */
export default function SearchResultFullText({fullTextSnippet}) {
  return (<HighlightedContent content={'...' + fullTextSnippet + '...'}/>);
}
