import React from 'react';
import PubroSpinner from '../../common/PubroSpinner';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BookCover from './BookCover';
import { useTranslation } from 'react-i18next';
import './BookDetailsSidePanel.css';
import Citation, { CitationType } from "../common/Citation";
import PublicationCommonDetailsSidePanel from '../common/PublicationCommonDetailsSidePanel';
import LicenseDetails from '../common/LicenseDetails';
import PbnExportButton from '../common/PbnExportButton';

/**
 * Renders publication details for book publications,
 * displayed on the right side. Expects the following props:
 * - book
 * - previewMode – when set to true disables all controls
 *   that allow processing only published items (citation, export)
 */
export default function BookDetailsSidePanel({book, pbnExportUrl, previewMode, api}) {

  const { t } = useTranslation();

  if (!book) {
    return <PubroSpinner />
  }

  return (
    <>
      <Card className='section image-section'>
        <Card.Body>
          <div className='text-center'><BookCover coverId={book.coverId} thumbnail={false} bookTitle={book.mainTitle.text}/></div>
        </Card.Body>
      </Card>
      <Card className='section'>
        <Card.Body>
          <Row>
            <Col className="space-apart">
              <Citation citationType={CitationType.BOOK} publication={book} disabled={previewMode} api={api}/>
              <PbnExportButton pbnExportUrl={pbnExportUrl} disabled={previewMode}/>
            </Col>
          </Row>
          {book.chaptered && book.license && <Row className="sidebar-license">
            <Col>
              <LicenseDetails license={book.license} />
            </Col>
          </Row>}

          {book.pageCount > 0 &&
            <Row className='page-count'>
              <Col sm={6} md={12} lg={6}>{t('book.details.pagecount.prefix')}</Col>
              <Col sm={6} md={12} lg={6}><strong>{book.pageCount}</strong></Col>
            </Row>
          }
          {book.releasePlace &&
            <Row className='release-place'>
              <Col sm={6} md={12} lg={6}>{t('book.details.releaseplace.prefix')}</Col>
              <Col sm={6} md={12} lg={6}><strong>{book.releasePlace}</strong></Col>
            </Row>
          }
        </Card.Body>
      </Card>
      <PublicationCommonDetailsSidePanel publication={book} externalIdLabelPrefix='dictionary.book_external_id_type.' />
    </>
  );
}
