import React from "react";
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { Col, Row } from 'react-bootstrap';
import CookiesBanner from "./CookiesBanner";
import SafeMail from '../publication/common/SafeMail';
import {withTranslation} from "react-i18next";
import MainLogo from './MainLogo';
import i18n from "../i18n/i18n";
import {getCurrentLanguageShortCode} from "../common/LanguageUtils";

/**
 * Simple footer component. Renders a bar with copyright notice at the bottom
 * of the page.
 */
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helpdeskEmail: null
    }
  }

  componentDidMount() {
    this.props.api.get('/info/contact')
      .then( response => this.setState({ helpdeskEmail: response.data.helpdeskEmail }) );
  }

  render () {
    const t = this.props.t;
    const copyrightYear = new Date().getFullYear();
    let currentLanguage = getCurrentLanguageShortCode(i18n.languages);

    return (
      <footer>
        <CookiesBanner/>
        <div className="container footer-logos row justify-content-between align-items-center">
          <div className="md-row top-row">
            <div className="sm-row">
              <div className="footer-img-container">
                <MainLogo classes="d-inline-block align-top" emptyAlt={false} />
              </div>
              <div className="footer-img-container">
                <img src="/logoUW.png" className="d-inline-block align-top" alt={t('footer.logo.alt.uw')}/>
              </div>
            </div>
            <div className="footer-img-container">
              <img src="/logoICM.png" className="d-inline-block align-top" alt={t('footer.logo.alt.icm')}/>
            </div>
          </div>
          <div className="md-row">
            <div className="footer-img-container">
              <img src="/logoFE.jpg" className="d-inline-block align-top logo-fe" alt={t('footer.logo.alt.fe')}/>
            </div>
            <div className="footer-img-container">
              <img src="/logoRP.svg" className="d-inline-block align-top" alt={t('footer.logo.alt.rp')}/>
            </div>
            <div className="footer-img-container">
              <img src="/logoUE.jpg" className="d-inline-block align-top" alt={t('footer.logo.alt.ue')}/>
            </div>
          </div>
        </div>
        <div className="container">
          <Nav>
            <Row className="footer-links justify-content-between align-items-center">
              <Col md={12} xl={true} className="copyright-text">
                <span>
                {t('footer.copyright')} {copyrightYear}
                </span>
              </Col>
              <Col xs={12} sm={6} md={4} xl={true}>
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/about/`} className="btn nav-link">
                  {t('navbar.menuitem.aboutproject')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </a>
              </Col>
              <Col xs={12} sm={6} md={4} xl={true}>
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/privacy-policy-and-cookies/`} className="btn nav-link">
                  {t('navbar.menuitem.privacypolicy')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </a>
              </Col>
              <Col xs={12} sm={6} md={4} xl={true}>
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/accessibility/`} className="btn nav-link">
                  {t('navbar.menuitem.accessibility')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </a>
              </Col>
              <Col xs={12} sm={6} md={4} xl={true}>
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/terms-of-use/`} className="btn nav-link">
                  {t('navbar.menuitem.termsandconditions')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </a>
              </Col>
              <Col xs={12} sm={6} md={4} xl={true}>
                <SafeMail renderAsLink textLabel={t('navbar.menuitem.reporterror')} encoded={this.state.helpdeskEmail} className="btn nav-link"/>
              </Col>
              <Col xs={12} sm={6} md={4} xl={true}>
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/contact/`} className="btn nav-link">
                  {t('navbar.menuitem.contact')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
                </a>
              </Col>
              <Col xs={12} sm={6} md={4} xl={true}>
                <Link to={`/sitemap`} className="btn nav-link">
                  {t('navbar.menuitem.sitemap')}
                </Link>
              </Col>
            </Row>
          </Nav>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer)
