import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-bootstrap/Tooltip';
import AccessibleOverlayTrigger from './AccessibleOverlayTrigger';
import { useContext } from 'react';
import { WbnOrganizationNameContext } from './WbnOrganizationNameProvider';
import { getCurrentLanguageShortCode } from './LanguageUtils';
import { FaInfoCircle } from 'react-icons/fa';

/**
 * Renders the WBN Access info icon with appropriate tooltip.
 */
export default function WbnAccessInfoIcon({ publisherNames }) {
  const { t, i18n } = useTranslation();
  const { namePl, setNamePl, nameEn, setNameEn } = useContext(WbnOrganizationNameContext);

  let currentLanguage = getCurrentLanguageShortCode(i18n.languages);

  const organizationNames = {
    pl: namePl,
    en: nameEn
  }

  return (
    <AccessibleOverlayTrigger placement='bottom' overlay={
      <Tooltip id='restricted-stats-tooltip'>
        {t('welcome.stats.restrictedAccess.tooltip', { organizationName: organizationNames[currentLanguage], publisherNames: publisherNames })}
      </Tooltip>
    }><FaInfoCircle />
    </AccessibleOverlayTrigger>
  );
}
