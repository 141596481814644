import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { withTranslation } from 'react-i18next';
import PubroSpinner from '../common/PubroSpinner';
import i18n from "../i18n/i18n";
import {getCurrentLanguageShortCode} from "../common/LanguageUtils";

class RandomPublishingCompanies extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      publishingCompanies: null,
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.props.api.get(`/publishing-companies/random`).then(response => {
      this.setState({
        publishingCompanies: response.data,
        isLoading: false
      });
    });
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    let currentLanguage = getCurrentLanguageShortCode(i18n.languages);

    if (this.state.isLoading) {
      return <PubroSpinner/>;
    }

    return (
      <>
        <Row className="main-page-publishers-header">
          <Col xs={6}>
            <h2>{t('welcome.publishers.header')}</h2>
          </Col>
          <Col xs={6} className="text-right">
            <LinkContainer to="/publishing-companies">
              <Button variant="link" aria-label={t('welcome.publishers.show-all.ariaLabel')}>{t('welcome.publishers.show-all')}</Button>
            </LinkContainer>
          </Col>
        </Row>

        <Row>
          <Col md={9} className="main-page-publishers-links-container">
            <Row>
              {this.state.publishingCompanies.map(publishingCompany =>
                <Col md={6} key={'publishing-company' + publishingCompany.id}>
                  <LinkContainer to={`/publishing-companies/${publishingCompany.id}`} data-publishing-company-link>
                    <Button variant="link" size="lg">{publishingCompany.name}</Button>
                  </LinkContainer>
                </Col>
              )}
            </Row>
          </Col>
          <Col md={3} className="main-page-publishers-button-container">
            <a className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/join-publishers/`}>
              {t('navbar.menuitem.joinpublishers')}
              <span className="sr-only">{t('link.externalLink.label')}</span>
            </a>
          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation()(RandomPublishingCompanies);
