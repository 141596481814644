import React from "react";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaExternalLinkAlt } from 'react-icons/fa';
import {withTranslation} from "react-i18next";
import { Helmet } from 'react-helmet-async'
import { MAIN_CONTENT_ID } from './SkipToContentLinks';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import i18n from "../i18n/i18n";
import {getCurrentLanguageShortCode} from "../common/LanguageUtils";

/**
 * Renders the "Sitemap" page.
 */
class Sitemap extends React.Component {

  static contextType = MatomoContext;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  render() {
    const t = this.props.t;
    let currentLanguage = getCurrentLanguageShortCode(i18n.languages);

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: t('sitemap.title') });

    return (
      <>
        <Helmet title={t('sitemap.title')} meta={[{name: 'description', content: t('sitemap.description')}]}/>
        <Container id={MAIN_CONTENT_ID} as="main">
          <Row>
            <Col md={6}>
              <h1>{t('sitemap.title')}</h1>
              <ul className="sitemap-links">
                <li><Link to={`/`}>{t('sitemap.home')}</Link></li>
                <li><Link to={`/search`}>{t('sitemap.search')}</Link></li>
                <li>
                  {t('sitemap.resources')}
                  <ul>
                    <li><Link to={`/publishing-companies`}>{t('sitemap.publishingcompanies')}</Link></li>
                    <li><Link to={`/journals`}>{t('sitemap.journals')}</Link></li>
                    <li><Link to={`/books`}>{t('sitemap.books')}</Link></li>
                  </ul>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/about/`}>
                    {t('sitemap.about')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  {t('sitemap.help')}
                  <ul>
                  
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/help/`}>
                    {t('sitemap.pubrodocumentation')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/publisher-manual/`}>
                    {t('sitemap.pueditdocumentation')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/embedding/`}>
                    {t('sitemap.embeddingdocumentation')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/api-documentation/`}>
                    {t('sitemap.apidocumentation')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                  </ul>
                  </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/contact/`}>
                    {t('sitemap.contact')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/join-publishers/`}>
                    {t('sitemap.joinpublishers')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/faq/`}>
                    {t('sitemap.faq')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/privacy-policy-and-cookies/`}>
                    {t('sitemap.privacypolicy')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/accessibility/`}>
                    {t('sitemap.accessibility')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/terms-of-use/`}>
                    {t('sitemap.termsandconditions')}
                    <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
                  </a>
                </li>
                
              </ul>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation()(Sitemap)