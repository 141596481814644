import { buildCreativeWorkJsonLdPartFromPublication } from '../common/PublicationJsonLd';
import { canCreatUrlExternalId, createUrlExternalId } from '../common/ExternalIdentifierUtil';
import { extractPageRangesOrPagination, emptyArrayToUndefined } from '../../common/utils';

// -------------------- LOGIC --------------------

/**
 * Returns json-ld representation for the given article
 * using ScholarlyArticle type of schema.org dictionary. 
 * 
 * @see https://schema.org/ScholarlyArticle
 * @see ArticleVO java class
 */
export function buildArticleJsonLd(article) {

  const reactAppBaseUrl = window.location.origin;

  let articleJsonLd = {
    "@context": "https://schema.org",
    "@type": "ScholarlyArticle",
    ...buildCreativeWorkJsonLdPartFromPublication(article),
    isPartOf: `${reactAppBaseUrl}/issues/${article.issueInfo.id}`,
    publisher: { "@type": "Organization", 
        name: article.issueInfo.journalInfo.publishingCompanyInfo.name, 
        url: `${reactAppBaseUrl}/publishing-companies/${article.issueInfo.journalInfo.publishingCompanyInfo.id}`}
  }

  if (article.pageRange) {
    const pageRangesOrPagination = extractPageRangesOrPagination(article.pageRange);
    articleJsonLd.pageStart = pageRangesOrPagination.pageStart;
    articleJsonLd.pageEnd = pageRangesOrPagination.pageEnd;
    articleJsonLd.pagination = pageRangesOrPagination.pagination;
  }

  articleJsonLd.sameAs = emptyArrayToUndefined(article.externalIdentifiers
                                    .filter(externalId => canCreatUrlExternalId(externalId))
                                    .map(externalId => createUrlExternalId(externalId)))

  return articleJsonLd;
}

