import Button from 'react-bootstrap/Button';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './FullTextButtonList.css';
import AccessibilityRequestLink from '../../common/AccessibilityRequestLink';

/**
 * Renders the full text download buttons, with PDF at the top, if present.
 * Expects list of FullTextFileVOs as `files` prop.
 */
export default function FullTextButtonList({ publication, publicationKind, previewMode, api, previewToken }) {
  const {t} = useTranslation();

  if (publication.fullTextFormats && publication.fullTextFormats.length > 0) {
    const hasPdf = publication.fullTextFormats.filter(format => format === 'PDF').length > 0;
    const otherFormats = publication.fullTextFormats.filter(format => format !== 'PDF');
    return (
      <span>
        <span className="publication-details-download-label">{t('search.result.list.item.download.label')}</span>

        {hasPdf &&
            <Button href={buildFullTextUrl(publicationKind, publication.id, 'PDF', previewToken)} target="_blank" rel="noopener" download
                    className='full-text-button' size='sm' variant='primary'>
              {downloadButtonContent(t, 'PDF', publication)}
            </Button>
        }

        {otherFormats.length > 0 && otherFormats.map(format => (
              <Button href={buildFullTextUrl(publicationKind, publication.id, format, previewToken)} target="_blank" rel="noopener" download className='full-text-button' size='sm' variant='primary' key={format}>
                {downloadButtonContent(t, format, publication)}
              </Button>
            ))
        }

        <AccessibilityRequestLink api={api}/>
      </span>
    );
  }
}

function downloadButtonContent(t, fileFormat, publication) {
  return (
    <>
      <FaDownload aria-label={t('icon.download.label')} /> {fileFormat}
       <span className="sr-only"> - {publication.mainTitle.text}</span>
    </>
  );
}

function resolveUrlPrefix(publicationKind) {
  switch (publicationKind) {
    case "article.details.kind":
      return '/articles/';
    case "book.details.kind.collectivework":
    case "book.details.kind.simplebook":
      return '/books/';
    case "chapter.details.kind":
      return '/chapters/';
    default:
      break;
  }
}

function buildFullTextUrl(publicationKind, publicationId, fullTextFormat, previewToken) {
  const tokenParam = previewToken ? '?previewToken=' + previewToken : '';
  return `${window.location.protocol}//${window.location.host}${resolveUrlPrefix(publicationKind)}${publicationId}.${fullTextFormat.toLowerCase()}${tokenParam}`;
}
