import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {useTranslation, Trans} from "react-i18next";
import IssueCover from '../issue/IssueCover';
import BookCover from '../publication/book/BookCover';
import { Link } from 'react-router-dom';
import { formatIssueLabel } from '../common/utils';
import ProcessMath from '../common/ProcessMath';
import { transformAsciiMathDelimiter } from '../common/utils';
import FormattedContent from '../publication/common/FormattedContent';
import validator from 'validator';
import sanitizeHtml from 'sanitize-html';

/**
 * Renders the publishing company details. PublishingCompanyVO is expected to
 * be passed as 'props.publishingCompany'.
 */
export default function PublishingCompanyBasicInfo({ publishingCompany }) {
  const {t} = useTranslation();
  if (publishingCompany) {
    return (
      <Container>
        <Row>
          <div className="tabbed-details-sidebar-present">
            {publishingCompany.additionalNames.length > 0 && additionalNames(publishingCompany.additionalNames, t)}
            {publishingCompany.addresses && addresses(publishingCompany.addresses, t)}
            {publishingCompany.phones && phones(publishingCompany.phones, t)}
            {publishingCompany.emails && emails(publishingCompany.emails, t)}
            {publishingCompany.urls && urls(publishingCompany.urls, t)}
            {publishingCompany.openAccessPolicy && openAccessPolicy(publishingCompany.openAccessPolicy, t)}

            {publishingCompany.additionalNames.length === 0 &&
              !publishingCompany.addresses && !publishingCompany.phones &&
              !publishingCompany.emails && !publishingCompany.urls &&
              !publishingCompany.openAccessPolicy && <em>{t('publishing.company.details.nodata')}</em>
            }
          </div>
          <div className="tabbed-details-sidebar">
            {publishingCompany.latestIssue && latestIssue(publishingCompany.latestIssue, t)}
            {publishingCompany.latestBook && latestBook(publishingCompany.latestBook, t)}
          </div>
        </Row>
      </Container>
    );
  }
  return null;
}

// -------------------- PRIVATE --------------------

function additionalNames(additionalNames, t) {
  return (
    <>
      <h3>{t('publishing.company.details.additionalnames')}</h3>
      <ul>
        {additionalNames.map((name, index) => <li key={index} data-publishing-company='additional-name'>{name}</li>)}
      </ul>
    </>
  );
}

function addresses(addresses, t) {
  return (
    <>
      <h3>{t('publishing.company.details.addresses')}</h3>
      <div data-publishing-company='addresses' className='preserve-whitespace'>{addresses}</div>
    </>
  );
}

function phones(phones, t) {
  return (
    <>
      <h3>{t('publishing.company.details.phones')}</h3>
      <div data-publishing-company='phones' className='preserve-whitespace'>{phones}</div>
    </>
  );
}

function emails(emails, t) {
  return (
    <>
      <h3>{t('publishing.company.details.emails')}</h3>
      <div data-publishing-company='emails' className='preserve-whitespace'>{emails}</div>
    </>
  );
}

function urls(urls, t) {
  const sanitizedUrlField = sanitizeHtml(urls, {
    allowedTags: [],
    disallowedTagsMode: 'recursiveEscape'
  });
  const parsedUrlField = sanitizedUrlField.split(/(\s)/).map(word => wrapUrl(word, t)).join("");

  return (
    <>
      <h3>{t('publishing.company.details.urls')}</h3>
      <div data-publishing-company='urls' className='preserve-whitespace' dangerouslySetInnerHTML={{__html: parsedUrlField}}>
      </div>
    </>
  );
}

function openAccessPolicy(openAccessPolicy, t) {
  return (
    <>
      <h3><Trans i18nKey="publishing.company.details.openaccesspolicy" components={{ inEnglish: <span lang="en" />}} /></h3>
      <div data-publishing-company='open-access-policy'>{openAccessPolicy}</div>
    </>
  );
}

function latestIssue(latestIssue, t) {
  return (
    <>
      <h3>{t('publishing.company.details.latest.issue.header')}</h3>
      <div className='latest-issue' data-publishing-company='latest-issue'>
        <Link to={`/issues/${latestIssue.id}`}>
          <div className='latest-issue-cover-container'>
            <IssueCover coverId={latestIssue.coverId} thumbnail={true} emptyAlt={true} />
          </div>
          <div className="latest-issue-journal"><strong>{latestIssue.journalTitle}</strong></div>
          <div>{t('publishing.company.details.latest.issue.prefix') + ' ' + formatIssueLabel(latestIssue)}</div>
        </Link>
      </div>
    </>
  );
}

function latestBook(latestBook, t) {
  return (
    <>
      <h3>{t('publishing.company.details.latest.book.header')}</h3>
      <div className='latest-book' data-publishing-company='latest-book'>
        <Link to={`/books/${latestBook.id}`}>
          <div className='latest-book-cover-container'>
            <BookCover coverId={latestBook.coverId} thumbnail={true} emptyAlt={true} />
          </div>
          <div className="latest-book-title">
            <strong>
              <ProcessMath>
                <FormattedContent as='span' content={transformAsciiMathDelimiter(latestBook.title)} />
              </ProcessMath>
            </strong>
          </div>
        </Link>
      </div>
    </>
  );
}

function wrapUrl(url, t) {
  const options = {
    protocols: ['http', 'https']
  }

  if (validator.isURL(url, options)) {
    let targetUrl = url;

    if (!targetUrl.startsWith('http')) {
      targetUrl = 'https://' + targetUrl;
    }

    return `<a target="_blank" rel="noopener noreferrer" href=${targetUrl}>${url}<span class="sr-only">${t('link.externalLink.label')}</span></a>`;
  }
  else {
    return url;
  }
}
