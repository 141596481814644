import React from "react";
import axios from "axios";
import {useTranslation, withTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import Container from "react-bootstrap/Container";
import {MAIN_CONTENT_ID} from "./SkipToContentLinks";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PubroSpinner from "../common/PubroSpinner";

/**
 * Displays logout button if user is logged in by single sign on.
 * On button click sends POST request to logout endpoint and immediately
 * reloads application homepage.
 */
class Logout extends React.Component {

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.state = {
      ssoLogin: false,
      isLoading: true
    };
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.props.api.get("/whoami/status")
    .then(response =>
      this.setState({
        ssoLogin: response.data.sso,
        isLoading: false
      }));
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    if (this.state.isLoading) {
      return <PubroSpinner />
    }

    const handleLogout = () => {
      const logoutUrl = '/logout';

      // skip '/api' prefix
      const uninterceptedaxios = axios.create();
      uninterceptedaxios.post(logoutUrl, {}, {});
      this.props.history.push('/');
    };

    return (
      <>
        <Container id={MAIN_CONTENT_ID} as="main">
          <Row>
            <Col>
              {this.state.ssoLogin &&
                <Button onClick={handleLogout}>{t('logout.button')}</Button>
              }
              {!this.state.ssoLogin &&
                <h1>{t('logout.header.loggedOut')}</h1>
              }
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default withTranslation()(Logout);
