import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './LicenseDetails.css';
import Tooltip from 'react-bootstrap/Tooltip';
import AccessibleOverlayTrigger from '../../common/AccessibleOverlayTrigger';
import { getCurrentLanguageShortCode } from '../../common/LanguageUtils';

/**
 * Renders the license details, with license icon linked to the license URL.
 * Expects the LicenseVO to be passed as 'license' prop.
 */
export default function LicenseDetails({ license }) {
  const { t, i18n } = useTranslation();

  if (license) {
    const licenseName = getCurrentLanguageShortCode(i18n.languages) === 'pl' ? license.namePl : license.nameEn;
    const licenseImage = (
      <Image src={`${process.env.REACT_APP_PUBRO_API_URL}/images/license-icons/${license.iconId}`}
        alt={licenseName}
        className='license-image'
        loading='lazy'/>
    )

    const licenseUrl = getCurrentLanguageShortCode(i18n.languages) === 'pl' ? license.urlPl : license.urlEn;
    return (
      licenseUrl && licenseUrl.length > 0 ?
        <AccessibleOverlayTrigger placement='top' overlay={<Tooltip id='license-icon-tooltip'>{licenseName}</Tooltip>}>
          <a className='license-link' href={licenseUrl} target="_blank" rel="noopener noreferrer">
            {licenseImage}
            <span className="sr-only">{t('link.externalLink.label')}</span>
          </a>
        </AccessibleOverlayTrigger>
        :
        <AccessibleOverlayTrigger placement='top' overlay={<Tooltip id='license-icon-tooltip'>{licenseName}</Tooltip>}>
          {licenseImage}
        </AccessibleOverlayTrigger>
    );
  }

  return (
    <Container>
      {t('publication.details.license.unknown')}
    </Container>
  );
}
