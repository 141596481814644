import { formatIssueLabel, buildContributorFullName } from './utils';
import { cleanFormattedContent } from '../publication/common/FormattedContentUtil';

// Maximum length for the generated `<title>` text.
const maxTitleLength = 70;

// Maximum length for the generated `<meta description>` attribute text.
const maxDescriptionLength = 120;

// The separator to use between `<title>` elements.
const titleSeparator = " — ";

// The separator to use when listing items.
const listSeparator = ", ";

// The character to end the abbreviated content with.
const abbreviatedText = "…";

// Site name (i18n label) to use in the <title> tag.
const siteName = "meta.site-name";

// Default <title> tag content (i18n label), when the generated content is empty.
const defaultTitle = "meta.default.title";

// Default `<meta description>` attribute content (i18n label), when the generated content is empty.
const defaultDescription = "meta.default.description";

// -------------------- PUBLIC --------------------

/**
 * Generate the `<title>` tag contents.
 * `data` parameter is a JSON with following optional attributes:
 * - `label` {`string`}: i18 label to put in the title, before site name.
 * - `text` {`string`}: Raw text to put in the title, before site name. 
 *   If both `label` and `text` are set, only `label` will be used.
 * - `subLabel` {`string`}: i18 label to put in the title, between `label`/`text` and site name.
 * - `subText` {`string`}: Raw text to put in the title, between `label`/`text` and site name. 
 *   If both `subLabel` and `subText` are set, only `subLabel` will be used.
 * - `skipSiteName` {`boolean`}: Denotes if the site name should be omitted from the title. Defaults to `false`.
 * 
 * If an empty/invalid `data` attribute is set, the default title is returned.
 * 
 * @param {Function} t i18n function
 * @param {JSON} data JSON data to generate the title from
 * @returns {string} Title string
 */

export function generateMetaTitle(t, data) {
  if (!t) {
    t = function(label) {
      return label;
    }
  }

  const firstPart = (data.label ? t(data.label) : data.text);
  const secondPart = (data.subLabel ? t(data.subLabel) : data.subText);

  if (firstPart && !secondPart) {
    if (data.skipSiteName) {
      return trimTitle(firstPart);
    }
    else {
      return trimTitle(firstPart, t(siteName));
    }
  }
  else if (firstPart && secondPart) {
    if (data.skipSiteName) {
      return trimTitle(firstPart, null, secondPart);
    }
    else {
      return trimTitle(firstPart, t(siteName), secondPart);
    }
  }
  else {
    return t(defaultTitle);
  }
}

/**
 * Generate the `<meta description>` attribute contents.
 * `data` parameter is a JSON with following optional attributes:
 * - `label` {`string`}: i18 label to put in the description.
 * - `text` {`string`}: Raw text to put in the description. 
 *   Only `label` will be used when both `label` and `text` are set.
 * - `noLimit` {`boolean`}: Denotes where the text should be truncated to fit within the limit. Defaults to `true`.
 * 
 * If an empty/invalid `data` attribute is set, the default description is returned.
 * 
 * @param {Function} t i18n function
 * @param {JSON} data JSON data to generate the description from
 * @returns {string} Description string
 */

export function generateMetaDescription(t, data) {
  if (!t) {
    t = function(label) {
      return label;
    }
  }

  if (data.label) {
    return trimDescription(t(data.label));
  }
  else if (data.text && data.noLimit) {
    return data.text;
  }
  else if (data.text) {
    return trimDescription(data.text);
  }
  else {
    return t(defaultDescription);
  }
}

/**
 * Generates a comma-separated list for use in the `<meta description>` or `<title>`.
 * `data` parameter is a JSON with following attributes:
 * - `type` {`string`}: data type; allowed types:
 * -- `articles`
 * -- `books`
 * -- `chapters`
 * -- `contributors`
 * -- `issues`
 * -- `journals`
 * -- `keywords`
 * -- `publishingCompanyAttributes`
 * -- `scientificFields`
 * - `data` {`Object`}: Object to generate results from.
 * - `language` {`string`}: Required for `keywords` type - only list the keywords where language matches. Not used in other cases.
 * 
 * @param {JSON} data JSON data to generate the list from
 * @returns {string} Comma-separated list, or an empty string
 */

export function generateSeparatedList(data) {
  const limit = maxDescriptionLength + listSeparator.length;
  let output = "";

  if (data.data && data.type) {
    switch (data.type) {
      case "articles":
      case "books":
      case "chapters":
        for (let i=0; i < data.data.length && output.length < limit; i++) {
          output += cleanFormattedContent(data.data[i].title) + listSeparator;
        }
        break;

      case "contributors":
        for (let i=0; i < data.data.length && output.length < limit; i++) {
          output += buildContributorFullName(data.data[i].firstName, data.data[i].lastName) + listSeparator;
        }
        break;
        
      case "issues":
        for (let i=0; i < data.data.length && output.length < limit; i++) {
          output += formatIssueLabel(data.data[i]) + listSeparator;
        }
        break;
        
      case "journals":
        for (let i=0; i < data.data.length && output.length < limit; i++) {
          output += data.data[i].mainTitle + listSeparator;
        }
        break;

      case "keywords":
        for (let i=0; i < data.data.length && output.length < limit; i++) {
          if (data.data[i].language == data.language) {
            output += cleanFormattedContent(data.data[i].text) + listSeparator;
          }
        }
        break;
      
      case "publishingCompanyAttributes":
        let attributes = ["addresses", "phones", "emails", "urls"];
        for (let i=0; i < attributes.length && output.length < limit; i++) {
          if (!data.data[attributes[i]]) {
            continue;
          }
          else {
            output += data.data[attributes[i]] + listSeparator;
          }
        }
        break;
      
      case "scientificFields":
        for (let i=0; i < data.data.length && output.length < limit; i++) {
          let disciplines = data.data[i].scientificDisciplines;

          if (disciplines && disciplines.length > 0) {
            for (let j=0; j < disciplines.length && output.length < limit; j++) {
              output += disciplines[j].namePl + listSeparator;
            }
          }
        }
        break;
    }

    // Remove trailing separator
    output = output.slice(0, -listSeparator.length);

    // Remove newline characters
    output = output.replace(/[\n\r]/gm, " ");
  }
  
  return output;
}

/**
 * Generates a dash-separated list for use in `<title>`.
 * 
 * @param {Array} array Array of text strings to merge
 * @returns {string} Dash-separated list, or an empty string
 */

export function mergeTitleElements(array) {
  if (array) {
    let output = "";

    array.forEach(function(element) {
      output += element + titleSeparator;
    });

    // Remove trailing separator
    output = output.slice(0, -titleSeparator.length);

    return output;
  }
}

// -------------------- PRIVATE --------------------

/**
 * Generates a `<title>` text content from given elements, truncated to fit within the limit.
 * Template: `title — subtitle — site`
 * 
 * @param {string} title Page title
 * @param {string} site Site name
 * @param {string} subtitle Page subtitle
 * @returns {string} Page title
 */
function trimTitle(title, site, subtitle) {
  let pageTitle = title;
  let suffix = "";

  if (subtitle && site) {
    suffix = titleSeparator + subtitle + titleSeparator + site;
  }
  else if (subtitle) {
    suffix = titleSeparator + subtitle;
  }
  else if (site) {
    suffix = titleSeparator + site;
  }

  if ((pageTitle + suffix).length > maxTitleLength) {
    pageTitle = pageTitle.slice(0, maxTitleLength - suffix.length - abbreviatedText.length) + abbreviatedText;
  }

  return pageTitle + suffix;
}

/**
 * Generates a `<meta description>` text content from given elements, truncated to fit within the limit.
 * 
 * @param {string} description Page description
 * @returns {string} Page title
 */
function trimDescription(description) {
  if (description.length > maxDescriptionLength) {
    return description.slice(0, maxDescriptionLength - abbreviatedText.length) + abbreviatedText;
  }
  else {
    return description;
  }
}