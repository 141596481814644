import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {withTranslation} from "react-i18next";
import { Helmet } from 'react-helmet-async'
import { MAIN_CONTENT_ID } from './SkipToContentLinks';
import SafeMail from '../publication/common/SafeMail';
import { MatomoContext } from '@datapunt/matomo-tracker-react';

/**
 * Renders the "Contact" subpage.
 */
class Contact extends React.Component {

  static contextType = MatomoContext;

  constructor(props) {
    super(props);
    this.state = {
      helpdeskEmail: null
    }
  }

  componentDidMount() {
    this.props.api.get('/info/contact')
      .then( response => this.setState({ helpdeskEmail: response.data.helpdeskEmail }) );
  }

  render() {
    const t = this.props.t;
    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: t('contact.title') });
    return (
      <>
        <Helmet title={t('contact.title')} meta={[{name: 'description', content: t('contact.description')}]}/>
        <Container id={MAIN_CONTENT_ID} as="main">
          <Row>
            <Col md={6}>
              <h1>{t('contact.title')}</h1>
              <div className="space-apart">
                <span>{t('contact.helpdesk.mail.label')}</span>
                <SafeMail renderAsLink encoded={this.state.helpdeskEmail}/>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation()(Contact)
