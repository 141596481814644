import axios from "axios";

const config = {
  baseURL: process.env.REACT_APP_PUBRO_API_URL,
  params: {}
};

/**
 * Axios instance pre-configured for Pubro REST API requests.
 */
export const createApiInstance = () => {
  const api = axios.create(config);
  api.unintercepted = axios.create(config);
  return api;
}
