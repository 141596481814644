import React from "react";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation, Trans } from "react-i18next";
import Card from "react-bootstrap/Card";
import DeltaRenderer from "../common/DeltaRenderer";
import Accordion from "react-bootstrap/Accordion";
import JournalLastIssueSidePanel from './JournalLastIssueSidePanel';
import ScientificDisciplineList from "../publication/common/ScientificDisciplineList";
import AccessibleOverlayTrigger from '../common/AccessibleOverlayTrigger';
import AccessibleAccordionToggle from '../common/AccessibleAccordionToggle';
import { convertToIetfLanguageTag } from '../common/LanguageUtils';
import JournalLinkedJournalsSidePanel from "./JournalLinkedJournalsSidePanel";


/**
 * Renders all basic journal information. JournalVO is expected to be passed
 * as 'journal' prop.
 */
export default function JournalBasicInfo({ journal, lastIssue }) {
  const { t } = useTranslation();
  const filteredAdditionalDescriptions = journal.additionalDescriptions.filter(description => description.type !== 'FOR_AUTHORS_AND_REVIEWERS');

  const hasMainDescription = journal.mainDescription && journal.mainDescription.text && journal.mainDescription.text.length > 0;

  return (
    <>

      <Container>

        <Row>
          <div className="tabbed-details-sidebar-present">

            {(hasMainDescription || journal.descriptionTranslations.length > 0) &&
              <Accordion defaultActiveKey="mainDesc">
                <h3 className="sr-only">{t('journal.details.section.header.descriptions')}</h3>
                <Card className='section'>
                  <AccessibleAccordionToggle eventKey="mainDesc">
                    {t('journal.details.section.header.descriptions')}
                  </AccessibleAccordionToggle>
                  <Accordion.Collapse eventKey="mainDesc">
                    <Card.Body>
                      <Container>
                        {mainDescription(journal.mainDescription, journal.descriptionTranslations, t)}
                      </Container>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>}

            {journal.scientificFields.length > 0 &&
              <Accordion defaultActiveKey="scientificFields">
                <h3 className="sr-only">{t('journal.details.section.header.scientific-fields')}</h3>
                <Card className='section'>
                  <AccessibleAccordionToggle eventKey="scientificFields">
                    {t('journal.details.section.header.scientific-fields')}
                  </AccessibleAccordionToggle>
                  <Accordion.Collapse eventKey="scientificFields">
                    <Card.Body>
                      <Container>
                        <ScientificDisciplineList scientificFields={journal.scientificFields} />
                      </Container>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>}

            {filteredAdditionalDescriptions.length > 0 && additionalDescriptions(filteredAdditionalDescriptions, t)}
          </div>

          <div className="tabbed-details-sidebar">
            {lastIssueSidebar(lastIssue, t)}
            {linkedJournalsSidebar(journal.linkedJournals, t)}
          </div>
        </Row>

      </Container>

    </>
  );
}

// -------------------- PRIVATE --------------------

function mainDescription(mainDescription, descriptionTranslations, t) {

  if (descriptionTranslations.length === 0) {
    return (
      <div id='journalMainDescription'>
        <Row>
          <Col className='journal-main-description-text' data-journal={"desc-text-"+mainDescription.language}>
            <div lang={convertToIetfLanguageTag(mainDescription.language)}>
              <DeltaRenderer delta={JSON.parse(mainDescription.text)} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div id='journalMainDescription'>
      <Row>
        <Col className='journal-main-description-text'>
          <Tab.Container defaultActiveKey={"desc-"+mainDescription.language}>
              <Nav variant="tabs">
                {[mainDescription, ...descriptionTranslations].map((desc, index) =>
                  <AccessibleOverlayTrigger key={"desc-tab-"+desc.language}
                                            overlay={<Tooltip id={"desc-tab-"+desc.language}>{t('dictionary.language.' + desc.language)}</Tooltip>}>
                    <Nav.Item>
                      <Nav.Link eventKey={"desc-"+desc.language} data-journal={"desc-tab-"+desc.language}>{desc.language}</Nav.Link>
                    </Nav.Item>
                  </AccessibleOverlayTrigger>
                )}
              </Nav>
            <Tab.Content>
              {[mainDescription, ...descriptionTranslations].map((desc, index) =>
                <Tab.Pane key={"desc-text-"+desc.language} eventKey={"desc-"+desc.language} data-journal={"desc-text-"+desc.language}>
                  <div lang={convertToIetfLanguageTag(desc.language)}>
                    <DeltaRenderer delta={JSON.parse(desc.text)} />
                  </div>
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </div>
  );
}

function additionalDescriptions(additionalDescriptions, t) {

  return (
    <div id='journalAdditionalDescriptions'>
      {additionalDescriptions.map((translation, index) => (
        <Accordion key={translation.type + index} defaultActiveKey="additionalDesc">
          <h3 className="sr-only">
            <Trans i18nKey={'dictionary.journal_additional_description_type.' + translation.type.toLowerCase()}
                   components={{ inEnglish: <span lang="en" />}} />
          </h3>
          <Card className="journal-additional-description-entry section">
            <AccessibleAccordionToggle eventKey="additionalDesc" data-journal="additional-desc-head">
              <Trans i18nKey={'dictionary.journal_additional_description_type.' + translation.type.toLowerCase()}
                     components={{ inEnglish: <span lang="en" />}} />
            </AccessibleAccordionToggle>
            <Accordion.Collapse eventKey="additionalDesc">
              <Card.Body>
                <Container data-journal="additional-desc-text">
                  <DeltaRenderer delta={JSON.parse(translation.text)}/>
                </Container>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}
    </div>
  );
}

function lastIssueSidebar(lastIssue) {
  return (
    <div>
      <JournalLastIssueSidePanel issue={lastIssue}/>
    </div>
  );
}

function linkedJournalsSidebar(linkedJournals) {
  return (
    <div className="linked-journals-tab">
      <JournalLinkedJournalsSidePanel linkedJournals={linkedJournals}/>
    </div>
  );
}
