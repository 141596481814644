import React from 'react';
import IssueCover from '../../../../issue/IssueCover';
import { formatIssueTitle } from '../../../../common/utils';
import SearchResultScientificDisciplines from '../common/SearchResultScientificDisciplines';
import { useTranslation } from "react-i18next";

/**
 * Renders sidebar part of article search result. Includes issue cover, journal
 * title, issue number and year, publishing company name and full text download
 * buttons.
 *
 * @author sniewcza
 */
export default function ArticleSearchResultSidebar({document, urlQueryValues}) {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <IssueCover coverId={document.coverId} issueLabel={formatIssueTitle(document.issueYear, document.issueVolume, document.issueNumber)}/>
      </div>
      <div>
        <div className='search-result-journal'>{document.journalTitle}</div>
        <div className='search-result-issue'>{formatIssueTitle(document.issueYear, document.issueVolume, document.issueNumber)}</div>
      </div>
      <div>
        <div className='search-result-publishing-company'>{document.publishingCompanyName}</div>
      </div>
      {document.scientificDisciplines.length > 0 && <SearchResultScientificDisciplines scientificDisciplines={document.scientificDisciplines}
                                                                                       urlQueryValues={urlQueryValues}/> }
    </>
  );
}
