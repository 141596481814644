import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import {LinkContainer} from 'react-router-bootstrap';
import PublishingCompanyLogo from './PublishingCompanyLogo';
import PubroSpinner from '../common/PubroSpinner';
import {withTranslation} from "react-i18next";
import { Helmet } from 'react-helmet-async';
import { MAIN_CONTENT_ID } from '../main-structure/SkipToContentLinks';
import queryString from 'query-string';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import Col from 'react-bootstrap/Col';
import ResultListPagination from '../common/ResultListPagination';
import LibrarySearch from '../main-structure/LibrarySearch';
import { extractNamePartFromUrlQuery, extractPageFromUrlQuery } from '../main-structure/library-utils';
import { generateMetaTitle, generateMetaDescription } from "../common/meta";
import WbnAccessIcon from '../common/WbnAccessIcon';

/**
 * Renders a list of all publishing companies. Contacts the backend to fetch
 * publishing company list items when mounted.
 */
class PublishingCompanyList extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      publishingCompanies: null,
      totalElements: 0,
      pageSize: 100,
      namePart: extractNamePartFromUrlQuery(this.props.location.search),
      page: extractPageFromUrlQuery(this.props.location.search),
      isLoading: true
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.updatePublishingCompanies();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        namePart: extractNamePartFromUrlQuery(this.props.location.search),
        page: extractPageFromUrlQuery(this.props.location.search),
        isLoading: true
      }, this.updatePublishingCompanies);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    if (this.state.isLoading) {
      return <PubroSpinner/>;
    }

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: t('publishing.company.list.title') });

    return (
      <>
        <Helmet title={ generateMetaTitle(t, {'label' : 'meta.publishers.title'}) }
                meta={[{ name: "description", content: generateMetaDescription(t, {'label' : 'meta.publishers.description'}) }]}/>
        <main id={MAIN_CONTENT_ID}>
          <div className="tabbed-details-name-container">
            <div className="tabbed-details-name-container tabbed-details-sidebar-present">
              <h1 className="library-list-header">{t('publishing.company.list.title')}</h1>
            </div>
            <div className="tabbed-details-sidebar">
              <LibrarySearch namePart={this.state.namePart} onSubmit={this.onFormSubmit}
                             placeholderKey='publishing.company.search.input.placeholder'
                             buttonTitleKey='publishing.company.search.button.title'/>
            </div>
          </div>
          <Container className="mt-0 container-without-cols">
            <Row>
              {this.state.publishingCompanies.length === 0 ? this.emptyPublishingCompanies() : this.publishingCompaniesList()}
            </Row>
          </Container>
          {this.state.totalElements > this.state.pageSize && this.buildPaginationContainer()}
        </main>
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  emptyPublishingCompanies() {
    const t = this.props.t;
    const emptyText = (this.state.namePart ? t('publishing.company.list.emptySearch') : t('publishing.company.list.empty'));
    return <em>{emptyText}</em>;
  }

  publishingCompaniesList() {
    const pubCoListElements = this.state.publishingCompanies.map(pubCompany => (
      <LinkContainer to={`/publishing-companies/${pubCompany.id}`} key={pubCompany.id}>
        <ListGroup.Item action className={pubCompany.restricted ? "wbn-access-restricted" : ""}>
          {pubCompany.logoId && <div className="publisher-name-logo-container">
            <PublishingCompanyLogo logoId={pubCompany.logoId} emptyAlt={true}/>
          </div>}
          <h2 className="publisher-name-header-container">
            {pubCompany.restricted && <WbnAccessIcon type={'PUBLISHER'}/>}
            {pubCompany.name}
          </h2>
        </ListGroup.Item>
      </LinkContainer>
    ));

    return (
      <ListGroup className="resources-list">
        {pubCoListElements}
      </ListGroup>
    );
  }

  buildPaginationContainer() {
    const namePart = this.state.namePart;
    const linkFn = page => queryString.stringify({namePart: namePart ? namePart : undefined, page: page});

    return (
      <Container>
        <Row>
          <Col>
            <ResultListPagination currentPage={this.state.page}
                                  linkFn={linkFn}
                                  totalResults={this.state.totalElements}
                                  pageSize={this.state.pageSize}/>
          </Col>
        </Row>
      </Container>
    );
  }

  updatePublishingCompanies() {
    this.props.api.get(`/publishing-companies`, {params: {namePart: this.state.namePart, page: this.state.page, pageSize: this.state.pageSize}})
      .then(response => this.setState({
        publishingCompanies: response.data.content,
        totalElements: response.data.totalElements,
        isLoading: false
      }));
  }

  onFormSubmit(formValues) {
    this.props.history.push('/publishing-companies?namePart=' + encodeURIComponent(formValues.namePart));
  }
}

export default withTranslation()(PublishingCompanyList);
