import React from 'react';
import { useTranslation } from 'react-i18next';
import IssueCover from '../issue/IssueCover';
import { Link } from 'react-router-dom';
import { formatIssueLabel } from '../common/utils';

/**
 * Renders journal last issue info,
 * displayed on the right side.
 * IssueVO is expected to be passed
 * as 'issue' prop.
 */
export default function JournalLastIssueSidePanel({ issue }) {

  const { t } = useTranslation();

  if (!issue) {
    return (
        <>
          <h3>{t('journal.details.last.issue')}</h3>
          <div data-latest-issue='latest-issue' className="latest-issue">
            <em>{t('journal.details.last.issue.empty')}</em>
          </div>
        </>
    )
  }

  return (
    <>
      <h3>{t('journal.details.last.issue')}</h3>
      <div data-latest-issue='latest-issue' className="latest-issue">
        <Link to={`/issues/${issue.id}`}>
          <div className="latest-issue-cover-container">
            <IssueCover coverId={issue.coverId} emptyAlt={true} thumbnail={true}/>
          </div>
          <div className="latest-issue-journal"><strong>{issue.journalTitle}</strong></div>
          <div>{formatIssueLabel(issue)}</div>
        </Link>
      </div>
    </>
  );
}
