import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

/**
 * Id of the main content on the page (typically should be used
 * for id of main html tag on the page)
 */
export const MAIN_CONTENT_ID = "main-content";

/**
 * Renders skip to page section content links.
 * For properly working skip to main content link - every
 * page need to have element with id specified in {@link MAIN_CONTENT_ID}
 * constant.
 * Note that skip links are only visible by tabbing with keyboard.
 */
export default function SkipToContentLinks(props) {
    const {t} = useTranslation();

    return (
      <div className="skip-links">
        <ul>
          <li><a href={'#' + MAIN_CONTENT_ID}>{t('header.skiptocontent')}</a></li>
        </ul>
        <ul>
          <li><Link to={`/sitemap`}>{t('header.gotositemap')}</Link></li>
        </ul>
      </div>
    );
}