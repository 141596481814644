import React from "react";
import PubroSpinner from '../../common/PubroSpinner';
import Select from 'react-select';
import { withTranslation } from "react-i18next";
import { getSelectAriaLiveMessages } from '../../common/utils';
import { getCurrentLanguageShortCode } from '../../common/LanguageUtils';

/**
 * Renders a select field in which multiple licenses can be selected.
 * Downloads all available licenses from the REST service on initialization.
 * Sets the values of selected options back to formik `licenses` field values.
 *
 * Relies on react-select component. See: https://react-select.com
 *
 * @author sniewcza
 */
class LicenseForm extends React.Component {

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.state = {
      licenses: [],
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    if (this.state.licenses.length === 0) {
      this.props.api.get('/licenses')
        .then(response => {
          this.setState({
            licenses: response.data,
            isLoading: false
          });
        });
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    if (this.state.isLoading) {
      return <PubroSpinner/>;
    }
    const licenseOptions = this.convertLicensesToSelectOptions(this.state.licenses);
    return <Select inputId={this.props.inputId}
                   isMulti options={licenseOptions}
                   value={this.getSelectedOptions(licenseOptions)}
                   onChange={selectedOptions => this.updateValues(selectedOptions)}
                   placeholder={''}
                   noOptionsMessage={() => this.props.t('search.form.select.nooptions.message')}
                   ariaLiveMessages={getSelectAriaLiveMessages(this.props.t)}
                   menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>;
  }

  getAllCcIds(licenses) {
    return licenses.filter(license => license.creativeCommons).map(license => license.id);
  }

  convertLicensesToSelectOptions(licenses) {
    let firstCcIndex = null;

    const licensesArray = licenses.map(license => ({
      label: getCurrentLanguageShortCode(this.props.i18n.languages) === 'pl' ? license.namePl : license.nameEn,
      value: license.id,
      isCc: license.creativeCommons
    }));

    for (let i = 0; i < licensesArray.length; i++) {
      if (firstCcIndex === null && licensesArray[i].isCc) {
        firstCcIndex = i;
        break;
      }
    }

    const allCcLicensesEntry ={
      label: this.props.t('search.form.advanced.licenses.allcc'),
      value: this.getAllCcIds(licenses).join(","),
      isAllCc: true
    };

    licensesArray.splice(firstCcIndex, 0, allCcLicensesEntry)

    return licensesArray;
  }

  getSelectedOptions(licenseOptions) {
    const ccLicenses = this.state.licenses.filter(license => license.creativeCommons).map(license => license.id);
    const allCcSelected = ccLicenses.every(license => this.props.formik.values.licenses.includes(license));

    if (allCcSelected) {
      const nonCcLicenses = licenseOptions.filter(license => !license.isCc && !license.isAllCc);
      const allCcLicenses = licenseOptions.filter(license => license.isAllCc);
      return allCcLicenses.concat(nonCcLicenses.filter(license => this.props.formik.values.licenses.includes(license.value)));
    } else {
      return licenseOptions.filter(license => this.props.formik.values.licenses.includes(license.value));
    }
  }

  updateValues(selectedOptions) {
    this.props.formik.setFieldValue('licenses', selectedOptions ? selectedOptions.map(option => option.value) : [])
  }

}

export default withTranslation()(LicenseForm);
