import React, { useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import HighlightedContent from './HighlightedContent';
import { convertUrlQueryValuesToUrlQuery } from "../../../form/AdvancedSearchUtils";
import { Link } from 'react-router-dom';
import AccessibleOverlayTrigger from '../../../../common/AccessibleOverlayTrigger';
import { v4 as uuidv4 } from 'uuid';

/**
 * Renders horizontal list of contributor names joined with a comma, eg:
 * "John Doe, Jane Doe, John Smith". Will render highlighting snippets instead,
 * if present.
 *
 * Expects the following props:
 *  - `contributorNames` - plain, not highlighted contributor names
 *  - `contributorSnippets` - contributor names with highlighting tags
 *
 *  `contributorSnippets` can be empty when there has been no highlighting done
 *  for the current query. If it's not empty, then it includes all contributors,
 *  even those that do not require highlighting, eg. when searching for "Smith":
 *  `['John Doe', 'Jane Doe', 'John <em>Smith</em>']`
 *
 * @author sniewcza
 */
export default function SearchResultContributorList({contributorNames, contributorSnippets, urlQueryValues}) {
  const [idPostfix] = useState(uuidv4());
  const { t } = useTranslation();

  if (contributorSnippets && contributorSnippets.length > 0) {
    return (
      <p className="search-result-contributor-list">
        {contributorSnippets.map((snippet, index) => (
          <React.Fragment key={index}>
            {contributorHihlightedLink(snippet, index + '-' + idPostfix, urlQueryValues, t)}
          </React.Fragment>
        ))}
      </p>);
  }

  if (contributorNames && contributorNames.length > 0) {
    return (<p className="search-result-contributor-list">{contributorNames.map((contributor, index) => (
      <React.Fragment key={index}>
        {contributorLink(contributor, index + '-' + idPostfix, urlQueryValues, t)}
      </React.Fragment>
    ))}</p>);
  }

  return null;
}

function contributorLink(contributor, idPostfix, urlQueryValues, t) {
  const tooltip = (<Tooltip id={'contributor-tooltip-' + idPostfix}>
    {t('search.result.list.item.contributors.link.tooltip')}
  </Tooltip>);

  return (
    <AccessibleOverlayTrigger placement="bottom" overlay={tooltip}>
      <Link to={'/search?' + buildContributorSearchUrl(contributor, urlQueryValues)}>{contributor}</Link>
    </AccessibleOverlayTrigger>
  );
}

function contributorHihlightedLink(snippet, idPostfix, urlQueryValues, t) {
  const tooltip = (<Tooltip id={'contributor-tooltip-' + idPostfix}>
    {t('search.result.list.item.contributors.link.tooltip')}
  </Tooltip>);

  return (
    <AccessibleOverlayTrigger placement="bottom" overlay={tooltip}>
      <Link to={'/search?' + buildContributorSearchUrl(stripTags(snippet), urlQueryValues)}>
        <HighlightedContent as='span' content={snippet}/>
      </Link>
    </AccessibleOverlayTrigger>
  );
}

function buildContributorSearchUrl(contributor, currentUrlQueryValues) {
  const newUrlQueryValues = {
    pageSize: currentUrlQueryValues.pageSize,
    contributors: encodeURIComponent('"' + contributor + '"')
  };
  return convertUrlQueryValuesToUrlQuery(newUrlQueryValues)
}

function stripTags(snippet) {
  return snippet.replace(/<\/?em>/g, '');
}
