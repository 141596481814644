import React from 'react';
import {useTranslation} from 'react-i18next';
import {formatIssn} from '../../common/utils';

/**
 * Custom select option content for rendering journal titles with ISSN/EISSN
 * values on publishing company & journal select field.
 *
 * @author sniewcza
 */
export default function JournalOption({journal}) {
  const {t} = useTranslation();

  const extIds = [];
  if (journal.issn) {
    extIds.push(t('search.form.advanced.pcj.issn.prefix') + ' ' + formatIssn(journal.issn))
  }
  if (journal.eissn) {
    extIds.push(t('search.form.advanced.pcj.eissn.prefix') + ' ' + formatIssn(journal.eissn))
  }

  return (
    <span className='journal-option'>
      <span className='journal-title'>{journal.label}</span>
      {extIds.length > 0 && <span className='issn'>{extIds.join(', ')}</span>}
    </span>
  );
}
