import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PubroSpinner from '../../common/PubroSpinner';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../common/Breadcrumbs';
import { Helmet } from 'react-helmet-async'
import { parse } from 'query-string';
import PublicationDetails from '../common/PublicationDetails';
import BookDetailsSidePanel from './BookDetailsSidePanel';
import { getCommonPublicationMetaTagValues } from '../common/MetaUtils';
import { buildBookJsonLd } from './BookJsonLd';
import { MAIN_CONTENT_ID } from '../../main-structure/SkipToContentLinks';
import { sortChapters } from '../chapter/ChapterSortUtil';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import { formatDate, isInPreviewMode } from '../../common/utils';
import { generateMetaDescription, generateMetaTitle, generateSeparatedList, mergeTitleElements } from '../../common/meta';
import { cleanFormattedContent } from '../common/FormattedContentUtil';

/**
 * Renders book details and a list of chapters if the book is a collective work.
 * Id of the book is determined from the 'bookId' path variable. The component
 * contacts backend to fetch BookVO and list of ChapterListItemVO
 * (only if it is a collective work) when mounted.
 */
class BookDetails extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      book: null,
      chapters: null,
      isLoading: true,
      pbnExportUrl: null
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    const { bookId } = this.props.match.params;
    const { previewToken } = parse(this.props.location.search);
    const config = previewToken ? { params: { previewToken } } : undefined;

    Promise.all([
      this.props.api.get(`/books/${bookId}`, config),
      this.props.api.head(`/pbn/books/${bookId}`)
    ])
    .then(([bookResponse, pbnResponse]) => {
      this.setState({
        book: bookResponse.data,
        pbnExportUrl: pbnResponse.headers.location
      });
      return bookResponse.data.chaptered ? this.props.api.get(`/books/${bookId}/chapters`, config) : Promise.resolve({ data: [] });
    })
    .then((chaptersResponse => this.setState({ chapters: sortChapters(chaptersResponse.data) })));
  }

  static getDerivedStateFromProps(props, state) {
    return {isLoading: state.book === null || state.chapters === null };
  }

  // -------------------- LOGIC --------------------

  render() {
    const book = this.state.book;
    const t = this.props.t;

    if (this.state.isLoading) {
      return <PubroSpinner />
    }

    const meta = [{ name: "description", content: this.getMetaDescription(t) }];
    meta.push(...getCommonPublicationMetaTagValues(book));
    meta.push(...getBookSpecificMetaTagValues(book));

    const jsonLd = buildBookJsonLd(this.state.book, this.state.chapters);

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: book.mainTitle.text });

    return (
      <>
        <Helmet title={this.getMetaTitle(t)} meta={meta}
                script={[{type: "application/ld+json", innerHTML: JSON.stringify(jsonLd)}]}/>
        <Breadcrumbs items={this.getBreadcrumbs(book)} maxItemLength={75} />
        <Container id={MAIN_CONTENT_ID} as="main" className="mt-0">
          <Row>
            <Col md={8} className="publication-details-container">{this.basicDetails()}</Col>
            <Col md={4} className="publication-details-sidebar">{this.sidePanel()}</Col>
          </Row>
        </Container>
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  basicDetails() {
    const bookKind = this.state.book.collectiveWork ? 'book.details.kind.collectivework' : 'book.details.kind.simplebook';
    const bookTypePrefix = this.state.book.collectiveWork ? 'dictionary.collective_work_type.' : 'dictionary.simple_book_type.';
    if (this.state.book) {
      const { previewToken } = parse(this.props.location.search);
      return (
        <Row>
          <PublicationDetails publication={this.state.book}
                              publicationKind={bookKind}
                              publicationType={this.state.book.collectiveWork ? 'COLLECTIVE_WORK' : 'SIMPLE_BOOK'}
                              abstractLabel={'publication.details.book.abstracts.header'}
                              publicationTypePrefix={bookTypePrefix}
                              chapters={this.state.chapters}
                              showFulltextAndLicensePanel={!this.state.book.chaptered}
                              previewMode={isInPreviewMode(this)}
                              api={this.props.api}
                              previewToken={previewToken}/>
        </Row>
      )
    }
    return <PubroSpinner />
  }

  sidePanel() {

    if (this.state.book) {
      return (
        <Row>
          <BookDetailsSidePanel book={this.state.book}
                                pbnExportUrl={this.state.pbnExportUrl}
                                previewMode={isInPreviewMode(this)}
                                api={this.props.api}/>
        </Row>
      )
    }
    return <PubroSpinner />
  }

  getBreadcrumbs(book) {
    return [
      { label: book.publishingCompanyInfo.name, link: `/publishing-companies/${book.publishingCompanyInfo.id}`},
      { label: book.mainTitle.text, link: null, math: true, html: true},
    ];
  }

  getMetaTitle(t) {
    const book = this.state.book;

    let metaTitle = cleanFormattedContent(book.mainTitle.text);

    if (book.contributors && book.contributors.length > 0) {
      let contributors = generateSeparatedList({"type": "contributors", "data": book.contributors});
      metaTitle = mergeTitleElements([metaTitle, contributors]);
    }

    return generateMetaTitle(t, {"text": metaTitle});
  }

  getMetaDescription(t) {
    const defaultDescription = generateMetaDescription(t, {});
    const book = this.state.book;
    const chapters = this.state.chapters;

    if (book) {
      let description = "";

      if (book.collectiveWork && chapters && chapters.length > 0) {
        description = generateSeparatedList({"type": "chapters", "data": chapters})
      }
      else if (!book.collectiveWork) {
        if (book.mainAbstract && book.mainAbstract.text.length > 0) {
          description = cleanFormattedContent(book.mainAbstract.text);
        }
        else if (book.publishingCompanyInfo) {
          description = book.publishingCompanyInfo.name;

          if (book.dates) {
            book.dates.forEach(function(date) {
              if (date.type === "PUBLISHED") {
                description = " — " + formatDate(date.date);
              }
            });
          }
        }
      }

      if (description.length === 0) {
        return defaultDescription;
      }
      else {
        return generateMetaDescription(t, {"text": description})
      }
    }
    else {
      return defaultDescription;
    }
  }
}

export default withTranslation()(BookDetails);

function getBookSpecificMetaTagValues(book) {
  const isbn = book.externalIdentifiers.find(e => e.type === 'ISBN');
  return isbn ? [{name: 'citation_isbn', content: isbn.text}] : [];
}
