import { firstBy } from 'thenby';

// -------------------- LOGIC --------------------

/**
 * Returns sorted issues array.
 * Sorting is performed according to rules below:
 *  - first we sort based on issue year (descending)
 *  - then we sort based on issue volume (descending)
 *  - then we sort based on issue number (descending)
 * 
 * Sort by year is aware of range of years (for example 2001-2002).
 * In such cases it is assumed that: 2002 > 2001-2003 > 2001-2002 > 2001
 * If no number can be extracted then lexicographical order is applied
 *
 * Sort by volume and number follow the rule:
 * If no number can be extracted then sort lexicagraphically.
 * If number can be extracted then sort by number.
 * The following order is applied: 2 > 1 > 'text1' > 'text' 
 */
export function sortIssues(issues) {
  return issues.sort(
    firstBy((issue1, issue2) => compareYears(issue1.year, issue2.year), 'desc')
      .thenBy((issue1, issue2) => compareLexicographicalOrByNumber(issue1.volume, issue2.volume), 'desc')
      .thenBy((issue1, issue2) => compareLexicographicalOrByNumber(issue1.number, issue2.number), 'desc')
  );
}

// -------------------- PRIVATE --------------------

function compareYears(year1, year2) {
  if(year1 === year2) {
    return 0;
  }
  
  const numberRange1 = extractNumbersSeparatedByHyphen(year1);
  const numberRange2 = extractNumbersSeparatedByHyphen(year2);
    
  const firstCompare = compareNaNNumbers(numberRange1.first, numberRange2.first);

  if (Number.isNaN(firstCompare)) {
    return year1.localeCompare(year2);
  }
    
  if (firstCompare) {
    return firstCompare;
  }
    
  const secondCompare = compareNaNNumbers(numberRange1.second, numberRange2.second);

  if (Number.isNaN(secondCompare)) {
    return year1.localeCompare(year2);
  }

  return secondCompare;
}


function compareLexicographicalOrByNumber(value1, value2) {
  const number1 = parseInt(value1);
  const number2 = parseInt(value2);
  
  const numberCompare = compareNaNNumbers(number1, number2);
  
  if (Number.isNaN(numberCompare) || numberCompare === 0) {
    return (value1 || '').localeCompare((value2 || ''));
  }
  return numberCompare;
}

function compareNaNNumbers(number1, number2) {
  if (Number.isNaN(number1) && Number.isNaN(number2)) {
   return NaN;
  }

  if (Number.isNaN(number1)) {
    return -1;
  }
  if (Number.isNaN(number2)) {
    return 1;
  }

  return number1 - number2;
}


function extractNumbersSeparatedByHyphen(numbersString) {
  const parts = numbersString.split('-');
  if (parts.length === 1) {
    return {first: parseInt(parts[0]), second: NaN}
  }
  if (parts.length === 2) {
    return {first: parseInt(parts[0]), second: parseInt(parts[1])}
  }
  return {first: NaN, second: NaN};
}

