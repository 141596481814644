import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {useTranslation} from 'react-i18next';

/**
 * Renders a list of publication attributes. Expects the following props:
 *  - reviewedPublication (boolean)
 *  - reprintPublication (boolean)
 *  - translationPublication (boolean)
 *  - postConferencePublication (boolean)
 *  - conferenceName
 */
export default function PublicationAttributeList({ reviewedPublication, reprintPublication, translationPublication,
                                                   postConferencePublication, conferenceName }) {
  const { t } = useTranslation();
  if (reviewedPublication || postConferencePublication || reprintPublication || translationPublication) {
    const attributes = [];
    if (reviewedPublication) {
      attributes.push(t('publication.details.attributes.reviewedpublication'));
    }
    if (reprintPublication) {
      attributes.push(t('publication.details.attributes.reprintpublication'));
    }
    if (translationPublication) {
      attributes.push(t('publication.details.attributes.translationpublication'));
    }
    if (postConferencePublication) {
      if (conferenceName) {
        attributes.push([t('publication.details.attributes.postconferencepublication') + ': ', conferenceName]);
      }
      else {
        attributes.push(t('publication.details.attributes.postconferencepublication'));
      }
    }

    return (
      <Row>
        <Col>
          <div>
            {attributes.map((attr, index) => 
              <p key={index}>
                {!Array.isArray(attr) && attr}
                {Array.isArray(attr) && 
                  <>
                    {attr[0]}
                    <span className="publication-details-conference-name">
                      {attr[1]}
                    </span>
                  </>}
                </p>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}
