import Language from '../../common/Language';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {useTranslation} from 'react-i18next';

/**
 * Renders the language of the publication. Expects the language to be passed
 * as 'language' prop.
 */
export default function PublicationLanguageDetails({ language }) {
  const { t } = useTranslation();
  if (language) {
    return (
      <Row>
        <Col>
          {t('publication.details.language.prefix')}
          <div>
            <strong><Language language={language} /></strong>
          </div>
        </Col>
      </Row>
    );
  }
}
