import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {Accordion} from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import AdvancedSearchForm from './AdvancedSearchForm';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

/**
 * Renders an expandable panel with advanced search criteria form. Requires
 * formik to be passed as a prop. Takes the boolean `expand` prop to determine
 * the initial state of the panel (expanded/collapsed).
 *
 * @author sniewcza
 */
export default function AdvancedSearchPanel({formik, expand, activeFilters, api}) {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(expand);

  const handleToggle = (e) => {
    setExpanded(!expanded);
  };

  return (
    <Accordion defaultActiveKey={expand ? '0' : null} className='advanced-search-panel'>
      <Card>
        <Card.Header className="text-right">
          <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={handleToggle} aria-expanded={expanded} className="advanced-search-toggle">
            <span>{t('search.form.advanced.expandable-panel.toggle.label')}</span>
            {activeFilters > 0 && <span data-active-filter-count>{' (' + activeFilters + ')'}</span>}
            {expanded ? <FaAngleUp aria-hidden="true" /> : <FaAngleDown aria-hidden="true" />}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <AdvancedSearchForm formik={formik} api={api}/>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
