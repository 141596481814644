import React from "react";
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import {LinkContainer} from "react-router-bootstrap";
import IssueCover from "./IssueCover";
import {withTranslation} from "react-i18next";
import { formatIssueLabel, getSelectAriaLiveMessages } from '../common/utils';
import Select from 'react-select';
import WbnAccessIcon from "../common/WbnAccessIcon";

/**
 * Renders a list of issues passed in 'issues' prop.
 */
class IssueList extends React.Component {

  selectedIssueYear;

  constructor(props) {
    super(props);
    this.state = {
      selectedYears: null,
      filteredIssues: this.props.issues
    }
  }


  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;

    if (this.props.issues.length === 0) {
      return <Container><em>{t('issue.list.empty')}</em></Container>;
    }

    const issueYears = this.prepareSelectOptions(this.props.issues, t);
    this.selectedIssueYear = this.prepareSelectedIssueYear(this.selectedIssueYear, issueYears);

    const issueListElements = this.state.filteredIssues.map(issueListItem => (
      <LinkContainer to={`/issues/${issueListItem.id}`} key={issueListItem.id}>
        <ListGroup.Item action className={issueListItem.restricted ? "wbn-access-restricted" : ""}>
          <div className="issue-list-thumbnail">
            <IssueCover coverId={issueListItem.coverId} emptyAlt={true} />
          </div>
          <div className="issue-list-info">
            {issueListItem.restricted && <WbnAccessIcon type={'ISSUE'}/>}
            <h3 className="list-issue-title">{formatIssueLabel(issueListItem)}</h3>
          </div>
        </ListGroup.Item>
      </LinkContainer>
    ));

    return (
      <Container>
        <div className="issue-list-year-container">
        <Form.Label htmlFor="issue-list-year-select">{t('issue.list.filter.year')}</Form.Label>
        <Select inputId="issue-list-year-select"
                className="issue-list-year-select"
                options={issueYears}
                value={this.selectedIssueYear}
                isSearchable={false} isClearable={false}
                ariaLiveMessages={getSelectAriaLiveMessages(t)}
                onChange={selectedOption => {this.filterIssues(selectedOption.value); this.selectedIssueYear=selectedOption;}}/>
        </div>
        <ListGroup className="issue-list">
          {issueListElements}
        </ListGroup>
      </Container>
    );
  }

  prepareSelectedIssueYear(selectedIssueYear, issueYears) {
    if (this.selectedIssueYear) {
      if (this.selectedIssueYear.value==null) {
         return issueYears[0];
      }
      return selectedIssueYear;
    }
    return issueYears[0];
  }

  filterIssues(year) {
    this.setState({
      filteredIssues: this.props.issues.filter(issue => year ? issue.year === year : true)
    });
  }

  prepareSelectOptions(issues, t) {
    const yearOptions = this.extractYears(issues).map(s => ({label: s, value: s}));
    yearOptions.unshift({label: t('issue.list.filter.year.all'), value: null});
    return yearOptions;
  }

  extractYears(issues) {
    return issues.map(v => v.year).filter((v, i, a) => a.indexOf(v) === i);
  }
}

export default withTranslation()(IssueList);
