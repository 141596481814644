import React from 'react';
import SearchResultFrame from '../common/SearchResultFrame';
import ArticleSearchResultSidebar from './ArticleSearchResultSidebar';
import SearchResultCommonBody from '../common/SearchResultCommonBody';

/**
 * Search result list item for articles. Renders a common search result frame
 * with article-specific body and sidebar.
 *
 * @author sniewcza
 */
export default function ArticleSearchResultListItem({document, urlQueryValues, api}) {
  const mainBody = <SearchResultCommonBody headerKey={'search.result.list.item.frame.article.header'} 
                                           document={document} urlQueryValues={urlQueryValues} api={api} 
                                           accessRestricted={document.accessRestricted}/>;
  const sidebar = <ArticleSearchResultSidebar document={document} urlQueryValues={urlQueryValues}/>;
  return (
    <SearchResultFrame mainBody={mainBody}
                       sidebar={sidebar}/>
  );
}
