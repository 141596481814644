import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Renders the main app logo.
 *  - classes - extra classes for logo container
 *  - emptyAlt - if true then logo image will have empty alt
 *      (set this prop to true when rendering inside a link that doesn't have its own aria-label)
 */
 export default function MainLogo({ classes, emptyAlt }) {

  const { t } = useTranslation();
  const logoImage = '/logoMainIcon.png'
  const alt = emptyAlt ? '' : t('logo.main.alt');

  classes = classes || '';

  return (
    <span className={'main-logo ' + classes}>
      <img src={logoImage} className='main-logo-image' alt={alt}/> 
      <span className='main-logo-text'>
        {t('logo.main')}
      </span>
    </span>
  );
}
