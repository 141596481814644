import React from 'react';
import {useTranslation} from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaExternalLinkAlt } from 'react-icons/fa';

/**
 * Renders a link to the research data. Expects the URL to be passed as
 * 'researchDataLink' prop.
 */
export default function ResearchDataLinkDetails({ researchDataLink, publicationTitle }) {
  const {t} = useTranslation();
  if (researchDataLink) {
    return (
      <Row>
        <Col>
          <a href={researchDataLink} target="_blank" rel="noopener noreferrer">
            {t('publication.details.researchdata.link')}
            <FaExternalLinkAlt className="external-link-icon" aria-label={t('icon.externalLink.label')} />
            <span className="sr-only"> - {publicationTitle}</span>
          </a>
        </Col>
      </Row>
    );
  }
}
