import React from 'react';
import PubroSpinner from '../../common/PubroSpinner';
import Card from 'react-bootstrap/Card';
import TitleList from '../common/TitleList';
import KeywordTabList from '../common/KeywordTabList';
import AbstractTabList from '../common/AbstractTabList';
import BibEntryList from '../common/BibEntryList';
import RemarksDetails from '../common/RemarksDetails';
import InstitutionList from '../common/InstitutionList';
import ContributorList from '../common/ContributorList';
import IndexDetails from '../common/IndexDetails';
import FullTextButtonList from './FullTextButtonList';
import PublicationType from './PublicationType';
import LicenseDetails from './LicenseDetails';
import ChapterList from '../chapter/ChapterList';
import BookPartList from '../book/BookPartList';
import { useTranslation } from 'react-i18next';
import DraftPreviewModeWarning from './DraftPreviewModeWarning';
import WbnAccessIcon from '../../common/WbnAccessIcon';

PublicationDetails.defaultProps = {
  showFulltextAndLicensePanel: true
}

/**
 * Renders part of publication details. Expects the following props:
 * - publication
 * - publicationKind
 * - abstractLabel
 * - chapters (list of chapters in case publication is a chaptered book)
 * - showFulltextAndLicensePanel (when false fulltext and license panel will not render)
 * - previewMode (true when preview token has been provided)
 */
export default function PublicationDetails({ publication, publicationKind, publicationType, publicationTypePrefix,
        abstractLabel, chapters, showFulltextAndLicensePanel, previewMode, api, previewToken }) {

  const { t } = useTranslation();

  if (!publication) {
    return <PubroSpinner />
  }

  const hasFullTextOrLicense = publication.fullTextFormats.length > 0 || publication.license;
  const showFullTextAndLicensePanelFinal = showFulltextAndLicensePanel && hasFullTextOrLicense;

  return (
    <>
      {previewMode && <DraftPreviewModeWarning/>}
      {showFullTextAndLicensePanelFinal &&
      <Card className='section'>
        <Card.Body>
          <p className="publication-download-licence-container">
            {publication.fullTextFormats.length > 0 && <FullTextButtonList publication={publication}
                                                                           publicationKind={publicationKind}
                                                                           previewMode={previewMode}
                                                                           api={api}
                                                                           previewToken={previewToken}/>}
            {publication.restricted && <WbnAccessIcon type={publicationType}/>}
            {publication.license && <LicenseDetails license={publication.license} />}
          </p>
        </Card.Body>
      </Card>}
      <Card className='section'>
        <Card.Body>
          <span className="publication-kind-and-type">
            {!showFullTextAndLicensePanelFinal && publication.restricted &&
              <WbnAccessIcon type={publicationType}/>
            }
            <span className="publication-kind">{t(publicationKind)}</span>
            <span className="publication-type"><PublicationType publicationType={publication.type} publicationTypePrefix={publicationTypePrefix} /></span>
          </span>
          <TitleList mainTitle={publication.mainTitle} mainTitleTranslations={publication.mainTitleTranslations} />
        </Card.Body>
      </Card>
      <Card className='section'>
        <Card.Body>
          {publication.contributors.length > 0 && <ContributorList contributors={publication.contributors} />}
          {publication.publicationInstitutions.length > 0 && <InstitutionList publicationInstitutions={publication.publicationInstitutions} />}
        </Card.Body>
      </Card>
      {(publication.mainAbstract.text || publication.abstractTranslations.length > 0) &&
        <Card className='section'>
          <Card.Body>
            <AbstractTabList mainAbstract={publication.mainAbstract}
              abstractTranslations={publication.abstractTranslations}
              headerKey={abstractLabel}
              preserveWhitespace={!publication.restricted} />
          </Card.Body>
        </Card>}
      {publication.keywords.length > 0 &&
        <Card className='section'>
          <Card.Body>
            <KeywordTabList keywords={publication.keywords} publicationLanguage={publication.language} />
          </Card.Body>
        </Card>
      }
      {publication.bookParts && publication.bookParts.length > 0 &&
        <Card className='section'>
          <Card.Body>
            <BookPartList book={publication} chapters={chapters} api={api}/>
          </Card.Body>
        </Card>
      }
      {(!publication.bookParts || publication.bookParts.length === 0) && chapters && chapters.length > 0 &&
        <Card className='section'>
          <Card.Body>
            <ChapterList book={publication} chapters={chapters} api={api}/>
          </Card.Body>
        </Card>
      }
      {publication.bibEntries.length > 0 &&
        <Card className='section'>
          <Card.Body>
            <BibEntryList bibEntries={publication.bibEntries} />
          </Card.Body>
        </Card>}
      {publication.index &&
        <Card className='section'>
          <Card.Body>
            <IndexDetails index={publication.index} />
          </Card.Body>
        </Card>}
      {publication.remarks &&
        <Card className='section'>
          <Card.Body>
            <RemarksDetails remarks={publication.remarks} />
          </Card.Body>
        </Card>}
    </>
  );
}
