import queryString from 'query-string';

export function extractNamePartFromUrlQuery(urlQuery) {
  const values = queryString.parse(urlQuery);
  return values.namePart ? values.namePart : undefined;
}

export function extractPageFromUrlQuery(urlQuery) {
  const values = queryString.parse(urlQuery);
  return values.page ? +values.page : 1;
}
