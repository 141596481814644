import React from "react";
import { useTranslation } from "react-i18next";
import { formatExternalIdentifier } from "../common/utils";
import { convertToIetfLanguageTag } from '../common/LanguageUtils';



/**
 * Renders all basic journal information. JournalVO is expected to be passed
 * as 'journal' prop.
 */
export default function JournalMainInfo({ journal }) {
  const { t } = useTranslation();
  return (
    <div>
      {journal.mainTitleTranslations.length > 0 && titleTranslations(journal.mainTitleTranslations, t)}
      {journal.additionalTitles.length > 0 && additionalTitles(journal.additionalTitles, t)}
      {journal.seriesTitle && seriesTitle(journal.seriesTitle, t)}
      {getJournalExternalIdentifiers(journal.externalIdentifiers).length > 0
        && externalIdentifiers(getJournalExternalIdentifiers(journal.externalIdentifiers), 'journal', t)}
      {getJournalSeriesExternalIdentifiers(journal.externalIdentifiers).length > 0
        && externalIdentifiers(getJournalSeriesExternalIdentifiers(journal.externalIdentifiers), 'series', t)}
    </div>
  );
}

// -------------------- PRIVATE --------------------

function titleTranslations(translations, t) {
  return (
    translations.map((title, index) =>
      <div key={"title-tab-" + title.language + index} lang={convertToIetfLanguageTag(title.language)} data-title-translation>
        {title.text} ({title.language})
      </div>
  ));
}

function seriesTitle(seriesTitle, t) {
  return (
    <div id='journalSeriesTitle'>
      {t('dictionary.journal_title_type.series')}: <strong>{seriesTitle}</strong>
    </div>
  );
}

function additionalTitles(additionalTitles, t) {
  return (
    <>
    <div id='journalAdditionalTitles'>
      {additionalTitles.map((title, index) => (
        <span key={title.type + index} className='journal-additional-title-text'>{title.text}</span>
      ))}
    </div>
    </>
  );
}

function externalIdentifiers(externalIdentifiers, prefix, t) {
  return (
    <div id={prefix + 'ExternalIdentifiers'}>
      {externalIdentifiers.map((extId, index) => (
        <span key={extId.type + index}>
          <span className={prefix + '-external-id-type'}>{t('dictionary.journal_external_id_type.' + extId.type.toLowerCase())}: </span>
          <span className={prefix + '-external-id-text'}>{formatExternalIdentifier(extId)}</span>
        </span>
      ))}
    </div>
  );
}

function getJournalExternalIdentifiers(externalIdentifiers) {
  return externalIdentifiers.filter(extId => ['ISSN', 'EISSN', 'DOI'].includes(extId.type));
}

function getJournalSeriesExternalIdentifiers(externalIdentifiers) {
  return externalIdentifiers.filter(extId => ['SERIES_ISSN', 'SERIES_EISSN'].includes(extId.type));
}
