import React from "react";
import Image from 'react-bootstrap/Image';
import {useTranslation} from 'react-i18next';

IssueCover.defaultProps = {
  thumbnail: true,
  emptyAlt: false
}

/**
 * Renders issue cover if `coverId` prop is present, otherwise renders
 * a placeholder image.
 *
 * @author sniewcza
 */
export default function IssueCover({coverId, thumbnail, emptyAlt, issueLabel}) {
  const { t } = useTranslation();

  let imageUrl = '/issueCoverThumbnailPlaceholder.png';
  if (coverId) {
    imageUrl = thumbnail ? `${process.env.REACT_APP_PUBRO_API_URL}/images/issue-cover-thumbnails/${coverId}`
                         : `${process.env.REACT_APP_PUBRO_API_URL}/images/issue-covers/${coverId}`;
  }

  let alt = '';
  if (!emptyAlt) {
    if (!issueLabel) {
      throw new Error('issueLabel must be specified if emptyAlt is set to true');
    }
    alt = coverId ? (t('issue.cover.alt') + ' ' + issueLabel) : t('issue.cover.default.alt');
  }

  return <Image className={`search-result-cover-thumbnail ${coverId ? "custom-thumbnail" : ""}`} src={imageUrl} alt={alt} loading='lazy'/>
}
