import React from "react";
import PubroSpinner from "../common/PubroSpinner";
import JournalMainInfo from './JournalMainInfo';
import SearchInJournalForm from '../search/form/SearchInJournalForm';
import LicenseDetails from "../publication/common/LicenseDetails";
import Language from "../common/Language";
import {withTranslation} from 'react-i18next';
import {isInPreviewMode, isAllRightsReserved} from '../common/utils';
import Breadcrumbs from '../common/Breadcrumbs';
import {Helmet} from 'react-helmet-async'
import {Tooltip} from 'react-bootstrap';
import {FaGem} from 'react-icons/fa';
import {parse} from 'query-string';
import {buildJournalJsonLd} from './JournalJsonLd';
import {MAIN_CONTENT_ID} from '../main-structure/SkipToContentLinks';
import JournalTabs from './JournalTabs';
import {sortIssues} from '../issue/IssueSortUtil';
import {convertToIetfLanguageTag} from '../common/LanguageUtils';
import {MatomoContext} from '@datapunt/matomo-tracker-react';
import DraftPreviewModeWarning from '../publication/common/DraftPreviewModeWarning';
import AccessibleOverlayTrigger from '../common/AccessibleOverlayTrigger';
import WbnAccessIcon from "../common/WbnAccessIcon";

/**
 * Renders journal details and a list of issues assigned to this journal.
 * Id of the journal to be displayed is determined from 'journalId' URL
 * parameter. The component contacts the backend to fetch the journal when
 * mounted.
 */
class JournalDetails extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      journal: null,
      issues: null,
      latestIssue: null,
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.loadJournal();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        isLoading: true
      }, this.loadJournal);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const { journalId } = this.props.match.params;
    const t = this.props.t;
    if (this.state.isLoading) {
      return <PubroSpinner/>
    }

    if (this.context && this.context.trackPageView && this.state.journal) this.context.trackPageView({ documentTitle: this.state.journal.mainTitle.text});

    return (
      <>
        {this.state.journal &&
          <>
            <Helmet script={[{type: "application/ld+json", innerHTML: JSON.stringify(buildJournalJsonLd(this.state.journal, this.state.issues, this.props.i18n))}]}/>
            <Breadcrumbs items={this.getBreadcrumbs(this.state.journal)} maxItemLength={80}/>
          </>
        }
        <main id={MAIN_CONTENT_ID}>
          <div className="tabbed-details-name-container mb-1">
            <div className="tabbed-details-name-header-container tabbed-details-sidebar-present">
              {isInPreviewMode(this) && <DraftPreviewModeWarning/>}
              <div className="tabbed-details-name-header">{t('journal.details.header.journal')}</div>
              {this.state.journal && <>
                {this.state.journal.restricted && <WbnAccessIcon type={'JOURNAL'}/>}
                <h1 className="tabbed-details-name" data-journal="main-title" lang={convertToIetfLanguageTag(this.state.journal.mainTitle.language)}>
                  {this.state.journal.mainTitle.text}
                </h1>
                <JournalMainInfo journal={this.state.journal}/>
              </>
              }
            </div>
            <div className="tabbed-details-sidebar">
              <SearchInJournalForm journalId={journalId}/>
              {this.state.journal && this.state.journal.language && this.language(this.state.journal.language, t)}
              {this.state.journal && this.state.journal.issueFrequency && this.issueFrequency(this.state.journal.issueFrequency, t)}
              {this.state.journal && (this.state.journal.license || this.isDiamondAccess()) &&
                <div>
                  {this.state.journal && this.state.journal.license && <LicenseDetails license={this.state.journal.license} />}
                  {this.state.journal && this.isDiamondAccess() && this.diamondAccessIcon(t)}
                </div>
              }


            </div>
          </div>

          <JournalTabs journal={this.state.journal} issues={this.state.issues} latestIssue={this.state.latestIssue} />
        </main>
      </>
    );
  }

  language(language, t) {
    return (
      <div>
          {t('journal.details.language.prefix') + ': '}
          <strong><Language language={language} /></strong>
      </div>
    );
  }

  issueFrequency(issueFrequency, t) {
    return (
      <div>
          {t('journal.details.issuefrequency.prefix') + ': '}
          <strong>{t('dictionary.journal_issue_frequency_type.' + issueFrequency.toLowerCase())}</strong>
      </div>
    );
  }

  diamondAccessIcon(t) {
    const tooltip = (<Tooltip id={'diamond-access'}>
      {t('publication.details.diamondaccess.tooltip')}
    </Tooltip>);

    return (
      <AccessibleOverlayTrigger placement="bottom" overlay={tooltip}>
        <FaGem className="diamond-access-icon" aria-label={t('publication.details.diamondaccess.tooltip')} />
      </AccessibleOverlayTrigger>
    );
  }

  isDiamondAccess() {
    return this.state.journal.publishingCharge && this.state.journal.publishingCharge === 'NO'
            && this.state.journal.embargo && this.state.journal.embargo === 'NO'
            && !isAllRightsReserved(this.state.journal.license);
  }

  getBreadcrumbs(journal) {
    return [
      { label: journal.publishingCompanyInfo.name, link: `/publishing-companies/${journal.publishingCompanyInfo.id}`},
      { label: journal.mainTitle.text, link: null}
    ];
  }

  loadJournal() {
    const { journalId } = this.props.match.params;
    const { previewToken } = parse(this.props.location.search);
    const config = previewToken ? { params: { previewToken } } : undefined;

    const journalPromise = journalId > 0
      ? this.props.api.get(`/journals/${journalId}`, config)
      : Promise.resolve({data: []});
    const lastIssuePromise = journalId > 0
      ? this.props.api.get(`/journals/${journalId}/issues/latest`, config)
      : Promise.resolve({data: []});
    const issuesPromise = journalId > 0
      ? this.props.api.get(`/journals/${journalId}/issues`, config)
      : Promise.resolve({data: []});

    Promise.all([journalPromise, lastIssuePromise, issuesPromise]).then(response => {
      this.setState({
        journal: response[0].data,
        latestIssue: response[1].data,
        issues: sortIssues(response[2].data),
        isLoading: false
      })
    });
  }

}

export default withTranslation()(JournalDetails);
