import React from "react";
import GeneralError from './GeneralError';

/**
 * Error boundary wrapping all content in App component and rendering the
 * GeneralError component when an error is thrown somewhere in the component
 * tree.
 */
export default class ErrorBoundary extends React.Component {

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  // -------------------- LOGIC --------------------

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return <GeneralError/>;
    }
    return this.props.children;
  }
};
