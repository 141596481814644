import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PubroSpinner from '../common/PubroSpinner';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from "react-i18next";
import JournalListItems from './JournalListItems';
import { MAIN_CONTENT_ID } from '../main-structure/SkipToContentLinks';
import queryString from 'query-string';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import Col from 'react-bootstrap/Col';
import ResultListPagination from '../common/ResultListPagination';
import { extractPageFromUrlQuery } from '../main-structure/library-utils';

class DatabaseJournals extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      journals: null,
      database: null,
      pageSize: 100,
      page: extractPageFromUrlQuery(this.props.location.search),
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    this.updateJournals();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        page: extractPageFromUrlQuery(this.props.location.search),
        isLoading: true
      }, this.updateJournals);
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    if (this.state.isLoading) {
      return <PubroSpinner/>;
    }

    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: this.databaseName() });

    return (
      <>
        <Helmet title={this.databaseName()} meta={[{name: 'description', content: ''}]} />

        <Container id={MAIN_CONTENT_ID} className="container-without-cols" as="main">
          <Row><h1>{this.databaseName()}</h1></Row>
          <Row><p>{this.databaseDescription()}</p></Row>
          <Row><h2>{t('journal.database.journals.header')}</h2></Row>
          <Row className="database-journal-list-container">
            <JournalListItems journalListItems={this.state.journals}/>
          </Row>
        </Container>

        {this.state.totalElements > this.state.pageSize && this.buildPaginationContainer()}
      </>
    );
  }

  // -------------------- PRIVATE --------------------

  buildPaginationContainer() {
    const linkFn = page => queryString.stringify({page: page});

    return (
      <Container>
        <Row>
          <Col>
            <ResultListPagination currentPage={this.state.page}
                                  linkFn={linkFn}
                                  totalResults={this.state.totalElements}
                                  pageSize={this.state.pageSize}/>
          </Col>
        </Row>
      </Container>
    );
  }

  updateJournals() {
    const { database } = this.props.match.params;
    this.props.api.get(`/journal-databases/${database}/journals`, {params: {page: this.state.page, pageSize: this.state.pageSize}})
      .then(response => this.setState({
        journals: response.data.content,
        database: database,
        totalElements: response.data.totalElements,
        isLoading: false
      }));
  }

  databaseName() {
    const t = this.props.t;
    return t('journal.database.name.' + this.state.database);
  }

  databaseDescription() {
    const t = this.props.t;
    return t('journal.database.description.' + this.state.database);
  }
  
}

export default withTranslation()(DatabaseJournals);
