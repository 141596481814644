import React from "react";
import { withTranslation, Trans } from 'react-i18next';
import Button from 'react-bootstrap/Button'
import i18n from "../i18n/i18n";
import {getCurrentLanguageShortCode} from "../common/LanguageUtils";

/**
 * Renders a cookies notice banner. Can be dismissed.
 */

class CookiesBanner extends React.Component {

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      cookiesBannerDismissed: (localStorage.getItem('cookiesBannerDismissed') === 'true')
    };
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    let currentLanguage = getCurrentLanguageShortCode(i18n.languages);

    const dismissCookiesBanner = () =>  {
      localStorage.setItem('cookiesBannerDismissed', 'true');
      this.setState({
        cookiesBannerDismissed: true
      });
    }
    
    return (
      <>
        {!this.state.cookiesBannerDismissed && <div className="cookies-banner">
              <span className="cookies-banner-text">
                <Trans i18nKey="cookies-banner.text" components={{ inEnglish: <span lang="en" />}} />
              </span>

              <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_DOC_URL}/${currentLanguage}/privacy-policy-and-cookies/`} className="cookies-banner-learn-more">
                  {t('cookies-banner.learnmore')}
                  <span className="sr-only">{t('link.externalLink.label')}</span>
              </a>

              <Button id="cookiesBannerDismissButton" onClick={dismissCookiesBanner} variant="primary">{t('cookies-banner.button.close')}</Button>
          </div>
        }
      </>
    );
  }
}

export default withTranslation()(CookiesBanner);