import React, { useState } from 'react';

export const WbnOrganizationNameContext = React.createContext();

const WbnOrganizationNameProvider = props => {
    const [namePl, setNamePl] = useState('');
    const [nameEn, setNameEn] = useState('');

    return (
        <WbnOrganizationNameContext.Provider
            value={{ namePl, setNamePl, nameEn, setNameEn }}
        >
            {props.children}
        </WbnOrganizationNameContext.Provider>
    );
};

export default WbnOrganizationNameProvider;
