import React from "react";
import PubroSpinner from '../../common/PubroSpinner';
import Select from 'react-select';
import { withTranslation } from "react-i18next";
import { getCurrentLanguageShortCode } from '../../common/LanguageUtils';
import { getSelectAriaLiveMessages } from '../../common/utils';

/**
 * Renders a select field in which multiple scientific disciplines can be
 * selected. Downloads all available scientific disciplines from the REST
 * service on initialization. Sets the values of selected options back to
 * formik `scientificDisciplines` field values.
 *
 * Relies on react-select component. See: https://react-select.com
 *
 * @author sniewcza
 */
class ScientificDisciplineForm extends React.Component {

  // -------------------- CONSTRUCTORS --------------------

  constructor(props) {
    super(props);
    this.state = {
      scientificFields: [],
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    if (this.state.scientificFields.length === 0) {
      this.props.api.get('/scientific-fields')
        .then(response => {
          this.setState({
            scientificFields: response.data,
            isLoading: false
          });
        });
    }
  }

  // -------------------- LOGIC --------------------

  render() {
    const options = this.convertScientificFieldsToSelectOptions(this.state.scientificFields)
    return this.state.isLoading
      ? <PubroSpinner/>
      : <Select inputId={this.props.inputId}
                isMulti options={options}
                value={this.getSelectedOptions(options)}
                onChange={selectedOptions => this.updateValues(selectedOptions)}
                placeholder={''}
                noOptionsMessage={() => this.props.t('search.form.select.nooptions.message')}
                ariaLiveMessages={getSelectAriaLiveMessages(this.props.t)}
                menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>;
  }

  convertScientificFieldsToSelectOptions(scientificFields) {
    return scientificFields.map(field => ({
      label: getCurrentLanguageShortCode(this.props.i18n.languages) === 'pl' ? field.namePl : field.nameEn,
      options: field.scientificDisciplines.map(discipline => ({
        label: getCurrentLanguageShortCode(this.props.i18n.languages) === 'pl' ? discipline.namePl : discipline.nameEn,
        value: discipline.id
      }))
    }));
  }


  getSelectedOptions(allOptions) {
    const arrayOfOptionArrays = allOptions.map(field => field.options);
    const flatOptionArray = [].concat(...arrayOfOptionArrays); // [].flat() does not work in tests
    return flatOptionArray.filter(discipline => this.props.formik.values.scientificDisciplines.includes(discipline.value))
  }

  updateValues(selectedOptions) {
    this.props.formik.setFieldValue('scientificDisciplines', selectedOptions ? selectedOptions.map(option => option.value) : [])
  }
}

export default withTranslation()(ScientificDisciplineForm);
