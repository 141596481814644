import React from "react";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation, Trans } from "react-i18next";
import Badge from 'react-bootstrap/Badge';

/**
 * Renders journal indexation and scores information. JournalVO is expected to be passed
 * as 'journal' prop.
 */
export default function JournalIndexationAndScores({ journal }) {
  const { t } = useTranslation();
  const isInexationAndScoresEmpty = journal.mainDatabases.length === 0
        && journal.additionalDatabases.length === 0
        && journal.mainScores.length === 0
        && journal.additionalScores.length === 0
  
  return (
    <>

      <Container>
      {isInexationAndScoresEmpty ?
          <Row>
            <Col>
              <em>{t('journal.details.indexation-and-scores.empty')}</em>
            </Col>
          </Row>
          :
          <>
            {(journal.mainDatabases.length > 0 || journal.additionalDatabases.length > 0) &&
            <>
              <Row>
                <Col>
                  <h3>{t('journal.details.header.databases')}</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  {journal.mainDatabases.length > 0 && mainDatabases(journal.mainDatabases, t)}
                </Col>
              </Row>
              <Row>
                <Col>
                  {journal.additionalDatabases.length > 0 && additionalDatabases(journal.additionalDatabases)}
                </Col>
              </Row>
            </>}
            {(journal.mainScores.length > 0 || journal.additionalScores.length > 0) && 
              <>
              <Row>
                <Col>
                  <h3>{t('journal.details.header.scores')}</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  {journal.mainScores.length > 0 && mainScores(journal.mainScores, t)}
                </Col>
              </Row>
              <Row>
                <Col>
                {journal.additionalScores.length > 0 && additionalScores(journal.additionalScores)}
                </Col>
              </Row>
            </>}
          </>}
      </Container>

    </>
  );
}

// -------------------- PRIVATE --------------------

function mainDatabases(mainDatabases, t) {
  return (
    <div id="journalMainDatabases">
      {mainDatabases.map((mainDatabase) => (
        <div className="w-100" key={mainDatabase}>
          {t('dictionary.journal_database_type.' + mainDatabase.toLowerCase())}
        </div>
      ))}
    </div>
  );
}

function additionalDatabases(additionalDatabases) {
  return (
    <Container id="journalAdditionalDatabases">
      <Row>
        {additionalDatabases.map((additionalDatabase, index) => (
          <Badge key={additionalDatabase + index} variant="light" pill>{additionalDatabase}</Badge>
        ))}
      </Row>
    </Container>
  );
}

function mainScores(mainScores, t) {
  return (
    <Container id="journalMainScores">
      {mainScores.map((translation, index) => (
        <Row key={translation.type + index}>
          <Col className='journal-main-score-type'>
            <Trans i18nKey={'dictionary.journal_score_type.' + translation.type.toLowerCase()}
                   components={{ inEnglish: <span lang="en" />}} />
            :
          </Col>
          <Col className='journal-main-score-text'>{translation.text}</Col>
        </Row>
      ))}
    </Container>
  );
}

function additionalScores(additionalScores) {
  return (
    <Container id="journalAdditionalScores">
      {additionalScores.map((keyValue, index) => (
        <Row key={keyValue.key + index}>
          <Col className='journal-additional-score-type'>{keyValue.key}:</Col>
          <Col className='journal-additional-score-text'>{keyValue.value}</Col>
        </Row>
      ))}
    </Container>
  );
}
